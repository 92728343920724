import React, { useEffect, useState } from "react";
import NotificationIcon from "./NotificationIcon";
import NotificationSidePane from "./NotificationSidePane";
import NotificationDetailView from "./NotificationDetailView";
import axios from "axios";
import { PORT } from "../../Api/api";
import decodeToken from "../../affiliate/affiliatePanel/affiliateLib/decodeToken/DecodeToken";

// Demo data
// const demoNotifications = [
//   {
//     id: 1,
//     title: 'New connection request',
//     message: 'John Doe wants to connect with you.',
//     timestamp: new Date(Date.now() - 3600000).toISOString(),
//     isRead: false,
//     type: 'connection',
//   },
//   {
//     id: 2,
//     title: 'Your post is trending',
//     message: 'Your recent post "10 Tips for React Developers" is gaining traction!',
//     timestamp: new Date(Date.now() - 7200000).toISOString(),
//     isRead: false,
//     type: 'engagement',
//   },
//   {
//     id: 3,
//     title: 'New job opportunity',
//     message: 'A new job matching your profile has been posted: Senior React Developer at TechCorp.',
//     timestamp: new Date(Date.now() - 86400000).toISOString(),
//     isRead: true,
//     type: 'job',
//   },
// ];

function NotificationSystem() {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const getRoleId = JSON.parse(localStorage.getItem("roleId")) || null;
  const Token = localStorage.getItem("token");
  const DecodedToken = Token ? decodeToken(Token) : null;
  const affilateUser = DecodedToken?.id || null;
  const user = affilateUser || getRoleId;

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${PORT}getNotification/${user}`);
      const data = await response.data;
      setNotifications(data);
    } catch (error) {
      console.error("Failed to fetch notifications:", error.message);
    }
  };

  useEffect(() => {
    if (user) {
      fetchNotifications();
    }
  }, [user]);

  const toggleSidePane = () => {
    setIsOpen(!isOpen);
  };

  const handleNotificationClick = async(notification) => {
    setSelectedNotification(notification);
    if (!notification.isRead) {

      // console.log('Marking notification as read:', notification);
        try{
          await axios.put(`${PORT}notificationMarkedRead/${notification._id}`);
          fetchNotifications();
        }
        catch (error) {
          console.error('Failed to mark notification as read:', error.message);
        }

    }
  };

  const handleCloseDetailView = () => {
    setSelectedNotification(null);
  };

  const unreadCount =
    notifications && notifications?.filter((n) => !n.isRead).length;

  return (
    <>
      <NotificationIcon onClick={toggleSidePane} count={unreadCount || 0} />
      <NotificationSidePane
        isOpen={isOpen}
        onClose={toggleSidePane}
        notifications={notifications}
        onNotificationClick={handleNotificationClick}
      />
      {selectedNotification && (
        <NotificationDetailView
          notification={selectedNotification}
          onClose={handleCloseDetailView}
        />
      )}
    </>
  );
}

export default NotificationSystem;
