import React, { useEffect, useState, useContext, useMemo } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { PORT } from "../../../Api/api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "../../../Navbar/Navbar";
import GuardLayout from "../../../lib/GuardLayout";
import { LanguageContext } from "../../../lib/LanguageContext";
import { IoIosEyeOff, IoIosEye } from "react-icons/io";
import { Hourglass } from "react-loader-spinner";
import "./style.css";
import { RiUserAddLine } from "react-icons/ri";
import { getFcmToken } from "../../../firebase/getFCMToken/getToken";
import ButtonLoader from "../../../Loading/ButtonLoader";
function AffiliateLogin() {
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [errorForLogin, setErrorForLogin] = useState();
  const { language } = useContext(LanguageContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    email:
      localStorage.getItem("rememberMe") === "true"
        ? localStorage.getItem("email")
        : "",
    password:
      localStorage.getItem("rememberMe") === "true"
        ? localStorage.getItem("password")
        : "",
  };


  useEffect(()=>{
    if( localStorage.getItem("rememberMe") === "true"){
      setRememberMe(true)
    } 
  },[])

  const handleDismissToast = () => {
    setShowToast(false);
  };

  const handleToast = () => {
    if (showToast) {
      toast.error(
        `${
          language === "hindi"
            ? "Unauthorized: Invalid Credentials"
            : "अनधिकृत: अमान्य उपयोगकर्ता नाम या पासवर्ड"
        }`,
        { onClose: handleDismissToast }
      );
    }
  };

  useEffect(() => {
    handleToast();
  }, [showToast, language]);

  const validationSchema = (language) =>
    Yup.object().shape({
      email: Yup.string()
        .required(
          language === "hindi"
            ? "Email is required"
            : "ईमेल या फोन नंबर आवश्यक है"
        )
        .test(
          "is-valid-contact",
          language === "hindi"
            ? "Please enter a valid email"
            : "कृपया एक मान्य ईमेल या मोबाइल नंबर दर्ज करें।",
          (value) => {
            const trimmedValue = value ? value.trim() : "";
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const phonePattern = /^\d{10}$/;
            if (!trimmedValue) return false;

            return (
              emailPattern.test(trimmedValue) || phonePattern.test(trimmedValue)
            );
          }
        ),
      password: Yup.string()
        .min(
          6,
          language === "hindi"
            ? "Password must be at least 6 characters"
            : "पासवर्ड कम से कम 6 अक्षरों का होना चाहिए"
        )
        .max(
          25,
          language === "hindi"
            ? "Password must be of maximum 25 characters"
            : "पासवर्ड अधिकतम 25 अक्षरों का होना चाहिए"
        )
        .required(
          language === "hindi" ? "Password is required":"पासवर्ड आवश्यक है" 
        ),
    });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const response = await axios.post(`${PORT}/affiliatelogin`, {
        email: values.email.trim(),
        password: values.password,
      });
      console.log(response);
      const { token } = response.data;
      if (response.status === 200) {
            if (rememberMe) {          
              localStorage.setItem("email", values.email);
              localStorage.setItem("password", values.password);
              localStorage.setItem("rememberMe", "true");
              localStorage.setItem("token", token);
              navigate("/affiliate/affiliateDashboard");
            } else {
              localStorage.removeItem("email");
              localStorage.removeItem("password");
              localStorage.removeItem("rememberMe");
              localStorage.setItem("token", token);
              setIsAuthenticated(true);
              navigate("/affiliate/affiliateDashboard");
            }
        if (response?.data?.accountStatus === "Approved") {
          //18/12/24
          const FCM_Token = await getFcmToken();
          //save fcm
          const FCM_response = await axios.post(`${PORT}/setUserFCMinDB`, {
            affiliate_id: response.data.id,
            FCM_Token: FCM_Token,
          });
          // console.log(FCM_response);
         
        } 
        else if(response?.data?.accountStatus === "Rejected"){
        localStorage.removeItem('token');
        Swal.fire("Error",`Your account has been Rejected.\nPlease contact our support team for more information.`,'error');
        
          return
        }
        else {
          localStorage.setItem("token", token);
          // alert("InActive Account !");
          navigate("/unApprovedUser");
        }
      }
      setSubmitting(false);

    } catch (error) {
      setErrorForLogin(error.response.data.msg);
      setShowToast(true);
      setSubmitting(false);

      console.log(error);
    }
  };

  const useRevalidateOnLanguageChange = () => {
    const { validateForm } = useFormikContext();
    useEffect(() => {
      validateForm();
    }, [language, validateForm]);
  };

  const RevalidateOnLanguageChange = () => {
    useRevalidateOnLanguageChange();
    return null;
  };
  const handleRegister = () => {
    navigate("/affiliateRegister");
  };
  //Affiliate Token is already Exist
  useEffect(() => {
    localStorage.removeItem('forgotEmail');
    const isAuthenticated = localStorage.getItem("token");
    if (isAuthenticated || location.pathname === "/affiliate") {
      navigate("/affiliate/affiliateDashboard");
    }
  }, [navigate, location.pathname]);

  const handleForgetPass = (emailValue) => {
    // Check if email is empty
    if (!emailValue) {
      toast.error(
        `${
          language === "hindi"
            ? "Please enter your email address"
            : "कृपया ईमेल पता दर्ज करें"
        }`,
        { onClose: handleDismissToast }
      );
      return;
    }
  
    // Regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    // Validate the email format
    if (!emailRegex.test(emailValue)) {
      toast.error(
        `${
          language === "hindi"
            ? "Invalid email address"
            : "अमान्य ईमेल पता"
        }`,
        { onClose: handleDismissToast }
      );
      return;
    }
    localStorage.setItem('forgotEmail',emailValue)
  // Na vigate to forgot password page if validation passes
    navigate('/forgotPassword');
  };
  

  return (
    <div className="background_image">
      <GuardLayout>
        <Navbar />
        <div className="main-form-affiliate">
          <div className="form-container-affiliate-login" style={{ height: "auto", minHeight: "400px" }}>
            <h2 className="affiliate-login_title">
              {language === "english" ? "साझेदार लॉगिन" : "AFFILIATE LOGIN"}
            </h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema(language)}
              onSubmit={handleSubmit}
            >
              {({ values,isSubmitting }) => (
                <Form>
                  <div className="form-field">
                    <label className="label_title" htmlFor="email">
                      {language === "english" ? "ईमेल पता" : "Email address"}
                    </label>
                    <Field type="text" name="email" maxLength="50" />
                    <div className="error_login">
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="form-field">
                    <label className="label_title" htmlFor="password">
                      {language === "english" ? "पासवर्ड" : "Password"}
                    </label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      maxLength="25"
                    />
                    <div className="error_login">
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="d-flex justify-content-end" onClick={() => handleForgetPass(values.email)} ><span style={{ cursor: "pointer" }}> {language === "english" ? "पासवर्ड भूल गए" : " Forget Password"}</span></div>
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="submit-button"
                  >
                    {isSubmitting ? (
                      // <Hourglass
                      //   visible={true}
                      //   height="20"
                      //   width="20"
                      //   ariaLabel="hourglass-loading"
                      //   wrapperStyle={{}}
                      //   wrapperClass=""
                      //   colors={["#ffffff"]}
                      //   className="mt-1"
                      // />
                      <ButtonLoader color="green"/>
                    ) : language === "english" ? (
                      "सबमिट"
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <RevalidateOnLanguageChange />
                </Form>
              )}
            </Formik>
            <ToastContainer />
            <div className="togglePasswordVisibilityLoginPageMainDiv">
              <div
                className="togglePasswordVisibilityLoginPage"
                onClick={togglePasswordVisibility} style={{marginTop:"-25px"}}
              >
                {showPassword ? (
                  <IoIosEye className="eyebuttonLogin" />
                ) : (
                  <IoIosEyeOff className="eyebuttonLogin" />
                )}
              </div>
            </div>
            <div className="affiliate-bottom-div">
              <label
                className="affiliate-register-div"
                onClick={handleRegister}
              >
                <RiUserAddLine />
                {language === "english" ? "साइन अप करें" : " Sign Up"}
              </label>

              <div className="affiliate-rememberMe-div">
                <label>
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  {language === "english" ? "मुझे याद रखें" : " Remember Me"}
                </label>
              </div>
            </div>
          </div>
        </div>
      </GuardLayout>
    </div>
  );
}

export default AffiliateLogin;
