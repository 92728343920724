import AffiliateLayout from "../../../../affiliateLib/affiliateLayout/AffiliateLayout";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import { ThreeCircles } from "react-loader-spinner";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { PORT } from "../../../../../../Api/api";
import "./style.css";
import { formatDate } from "../../../../../../lib/FormattedDate";
import { FaArrowRight, FaEdit } from "react-icons/fa";
import { FaCheckCircle, FaDollarSign } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import { MdOutlineCreditScore } from "react-icons/md";
import { MdOutlineCreditCardOff } from "react-icons/md";
import Swal from "sweetalert2";
import { FaLevelDownAlt } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import "./style.css";
import Loading from "../../../../../../Loading/Loading";
import { useNavigate } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import decodeToken from "../../../../affiliateLib/decodeToken/DecodeToken";
import ViewAllAffiliateBills from "../../../../../../GetData/ViewAllAffiliateBills";
const ViewAffiliateSocietyList = () => {
  const { language } = useContext(LanguageContext);
  const [affiliateData, setAffiliateData] = useState([]);
  const [societyAgencyFilterData, setAgencyFilterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [societyLoading, setSocietyLoading] = useState(false);
  const [selectedAgencyId, setSelectedAgencyId] = useState("");
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [societyData, setSocietyData] = useState([]);
  const [editCommission, setEditCommission] = useState(false);
  const [searchingSocityData, setSearchingSocityData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [commissionValue, setCommissionValue] = useState("");
  // Get token and decode it
  const token = localStorage.getItem("token");
  const decodeTokenData = decodeToken(token);
  const id = decodeTokenData.id;

  const [viewMode, setViewMode] = useState("select");

  const toggleViewMode = () => {
    setViewMode(viewMode === "select" ? "all" : "select");
  };
  //fetch affiliate agency
  const fetchaffiliateAgency = async () => {
    try {
      const response = await axios.get(`${PORT}/getAffiliateUser`);
      const res = (await response.data) || [];
      const filterApprovedAgency = await res.filter(
        (item) => item.status === "Approved" && item._id === id
      );
      setAffiliateData(filterApprovedAgency.reverse());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching affiliate users:", error);
    }
  };
  //fetch society
  const fetchSociety = async (id) => {
    try {
      setSocietyLoading(true);
      const response = await axios.get(`${PORT}/getSocietyData`);
      const res = await response.data.societyData;
      const filterAffiliateSociety = await res.filter(
        (item) => item.affiliateId === (id || selectedAgencyId)
      );
      setSocietyData(filterAffiliateSociety);
      setSearchingSocityData(filterAffiliateSociety);
      setSocietyLoading(false);
    } catch (error) {
      console.log("Error fetching society data:", error);
    }
  };

  const handleSearchChange = (e) => {
    const searchText = e.target.value.toLowerCase();
    setSearchQuery(e.target.value.toLowerCase());
    if (searchText === "") {
      // If the search text is empty, display all societies
      setSocietyData(searchingSocityData);
    } else {
      // Filter society data based on the search text
      const filteredSocieties = searchingSocityData.filter((society) =>
        society.name.toLowerCase().includes(searchText)
      );

      // Update the state with filtered data
      setSocietyData(filteredSocieties);
    }
  };

  //custom Function to convert the Date in month
  function formatDateToCustom(dateString) {
    const date = new Date(dateString);
    // Map of month numbers to short names
    const monthShortNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    // Extract date parts
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = monthShortNames[date.getMonth()]; // Converts month to short name
    const year = date.getFullYear(); // Gets the full year

    // Combine parts in the desired format
    return `${day}-${month}-${year}`;
  }
  // Handle selection changes
  const handleSocietyChange = (event, value) => {
    if (value) {
      setEditCommission(false);
      setSelectedAgency(value); // Update selected value
      setSelectedAgencyId(value._id); // Update selected ID
      fetchSociety(value._id);
      setAgencyFilterData(value);
      // const data = affiliateData.filter((item) => item?._id === value?._id);
      // setAgencyFilterData(data[0]); // Update filtered data
    }
  };
  // Helper function to generate a random color
  const getRandomColor = () =>
    `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  //handleViewSocietyTransaction
  const handleViewTransaction = (id) => {
    navigate(`/affiliate/viewAffiliateTransactions/${id}`);
  };
  //handleCommissionEdit
  const handleCommissionEdit = (index, item) => {
    setEditCommission(true);
  };
  //handleCancel
  const handleCancel = () => {
    setEditCommission(false);
  };
  // Handle input change
  const handleInputChange = (value) => {
    if (/^\d*\.?\d*%?$/.test(value)) {
      // Allow only numbers, decimals, and an optional `%` at the end
      setCommissionValue(value);
    }
  };
  const handleSaved = (id) => {
    if (!commissionValue.endsWith("%")) {
      alert("Please add a % symbol to the value.");
      return;
    }
    // Remove the % symbol and pass the number to the save function
    // const numericValue = parseFloat(commissionValue.replace("%", "%"));
    onSave(commissionValue, id); // Call the provided onSave function with the numeric value
  };
  const onSave = async (numericValue, id) => {
    console.log(numericValue, id);
    try {
      const data = {
        commissionRate: numericValue,
        affiliateId: id,
      };
      const response = await axios.post(
        `${PORT}/modifyAffiliateCommission`,
        data
      );
      console.log(response);
      fetchaffiliateAgency();
      setEditCommission(false);
    } catch (error) {
      console.log(error);
    }
  };
  //function lock
  useEffect(() => {
    fetchaffiliateAgency();
    setSelectedAgencyId(id);
  }, []);
  // Automatically pick the first entry
  useEffect(() => {
    if (affiliateData.length > 0) {
      const firstSociety = affiliateData[0];
      setSelectedAgency(firstSociety);
      fetchSociety(firstSociety._id);
      setAgencyFilterData(firstSociety);
    }
  }, [affiliateData]);
  return (
    <div>
      <AffiliateLayout>
        <div class="container-fluid py-4 ">
          <div class="row">
            <div class="col-12 ">
              <div class="card mb-4">
                <div class="card-header pb-0">
                  <div className="card-body px-0 pt-0 pb-2 w-100 ">
                    {loading ? (
                      <Loading />
                    ) : (
                      <div className="table-responsive p-0 bg-black">
                        <>
                          <div className="billing-container">
                            {/* Top Section */}
                            <div className="section-wrapper">
                              {/* Current Plan Summary */}
                              <div className="card-billing-wrapper">
                                <div className="card-billing-heading1">
                                  <div className="card-billing-heading-title">
                                    {language === "hindi"
                                      ? "Agency Details"
                                      : "एक एजेंसी चुनें"}
                                  </div>
                                  <div className="card-billing-heading-button">
                                    {/* <button>Upgrade</button> */}
                                  </div>
                                </div>
                                {affiliateData ? (
                                  <div className="card-billing-body">
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {language === "hindi"
                                            ? "Agency Name"
                                            : "एजेंसी का नाम"}
                                        </h6>
                                        <strong
                                          style={{
                                            // backgroundColor: bgColorPlanType,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000", // Black text for contrast
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : (
                                            societyAgencyFilterData?.agencyName ||
                                            ""
                                          )}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {language === "hindi"
                                            ? "Contact Person"
                                            : "संपर्क व्यक्ति"}
                                        </h6>

                                        <strong
                                          style={{
                                            // backgroundColor: bgColorHouseCount,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000", // Black text for contrast
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : (
                                            societyAgencyFilterData?.contactPerson ||
                                            ""
                                          )}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {language === "hindi"
                                            ? "Email Address"
                                            : "ईमेल पता"}
                                        </h6>

                                        <strong
                                          style={{
                                            // backgroundColor: bgColorHouseCount,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000", // Black text for contrast
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : (
                                            societyAgencyFilterData?.email || ""
                                          )}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {" "}
                                          {language === "hindi"
                                            ? "Phone no."
                                            : "फ़ोन नंबर"}{" "}
                                        </h6>

                                        <strong
                                          style={{
                                            // backgroundColor: bgColorHouseCount,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000", // Black text for contrast
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : (
                                            societyAgencyFilterData?.phone || ""
                                          )}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {language === "hindi"
                                            ? "Status"
                                            : "स्थिति"}
                                        </h6>

                                        <strong
                                          style={{
                                            // backgroundColor: bgColorHouseCount,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000", // Black text for contrast
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : (
                                            <span
                                              className={`status-badge ${societyAgencyFilterData?.status?.toLowerCase()}`}
                                            >
                                              {societyAgencyFilterData?.status ||
                                                ""}
                                            </span>
                                          )}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {language === "hindi"
                                            ? "Address"
                                            : "पता"}
                                        </h6>

                                        <strong
                                          style={{
                                            maxWidth: "280px", // अपनी आवश्यकता के अनुसार चौड़ाई सेट करें
                                            maxHeight: "100px", // अपनी आवश्यकता के अनुसार ऊँचाई सेट करें
                                            overflow: "auto", // स्क्रॉल जोड़ने के लिए
                                            whiteSpace: "normal", // टेक्स्ट को लाइन में रखने के लिए
                                            wordWrap: "break-word", // टेक्स्ट को ब्रेक करने के लिए
                                            padding: "5px", // थोड़ा पेडिंग जोड़ें
                                            border: "1px solid #ccc", // बॉर्डर स्टाइल के लिए
                                            borderRadius: "5px", // थोड़ा गोल बॉर्डर
                                          }}
                                          className="affiliate-address-div"
                                        >
                                          {societyAgencyFilterData?.address ||
                                            "No Data"}
                                        </strong>
                                      </p>
                                    </div>
                                    {/* commsion rate */}
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {language === "hindi"
                                            ? "Commission Rate"
                                            : "कमीशन दर"}
                                        </h6>

                                        <strong
                                          style={{
                                            // backgroundColor: bgColorHouseCount,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000", // Black text for contrast
                                          }}
                                          className=""
                                        >
                                          {societyAgencyFilterData?.commissionRate ||
                                            "20%"}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="billing-no-data"></div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="billing-container">
                            <div className="section-wrapper">
                              {/* Current Plan Summary */}
                              <div className="card-billing-wrapper">
                                <div className="card-billing-heading2">
                                  <div className="card-billing-heading-title">
                                    {" "}
                                    {language === "hindi"
                                      ? " Added Society"
                                      : "जोड़ी गई सोसाइटी"}
                                  </div>
                                  <div className="card-billing-heading-title">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "15px",
                                        flex: 1,
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {viewMode === "select" && (
                                        <div
                                          style={{
                                            position: "relative",
                                            maxWidth: "250px",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            id="search-society"
                                            name="searchSociety"
                                            placeholder="Search society..."
                                            onChange={handleSearchChange}
                                            style={{
                                              padding: "6px 28px 6px 12px",
                                              border: "1px solid #dee2e6",
                                              borderRadius: "4px",
                                              fontSize: "14px",
                                              width: "100%",
                                              outline: "none",
                                            }}
                                          />
                                          <span
                                            style={{
                                              position: "absolute",
                                              right: "8px",
                                              top: "50%",
                                              transform: "translateY(-50%)",
                                              color: "#6c757d",
                                              fontSize: "14px",
                                              pointerEvents: "none",
                                            }}
                                          >
                                            🔍
                                          </span>
                                        </div>
                                      )}

                                      <button
                                        onClick={toggleViewMode}
                                        style={{
                                          padding: "6px 12px",
                                          backgroundColor: "#fff",
                                          color: "#333",
                                          border: "1px solid #dee2e6",
                                          borderRadius: "4px",
                                          cursor: "pointer",
                                          fontSize: "14px",
                                          whiteSpace: "nowrap",
                                          transition: "all 0.2s",
                                          position: "relative",
                                          zIndex: 2,
                                        }}
                                      >
                                        {viewMode === "select"
                                          ? "View All Bills "
                                          : "Select Society"}
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                {viewMode === "select" ? (
                                  <div className="card-billing-body">
                                    {/* <div className="searchbox mb-5 pe-3 py-3" style={{marginBottom:'50px'}}>
                                       <div className="search-filter-box">
                                    <Box
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: " #5e72e4",
                                        borderWidth: "1px",
                                        borderRadius: "5px",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: " #5e72e4",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: " #5e72e4",
                                      },

                                      "& .MuiInputLabel-root.Mui-error": {
                                        color: "red",
                                      },
                                    },
                                  }}
                                >
                                  <TextField
                                    id="search-input"
                                    label={
                                      language === "hindi" ? "Search..." : "खोज..."
                                    }
                                    variant="outlined"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="search-input_society"
                                  />
                                    </Box>
                                      </div>
                                  </div> */}
                                    <div className="affiliate-container">
                                      {/* society card */}
                                      {societyLoading ? (
                                        <Loading />
                                      ) : societyData?.length > 0 ? (
                                        societyData.map((item) => {
                                          // Generate a unique color for each society's timeline line
                                          const borderColor = getRandomColor();
                                          return (
                                            <div
                                              key={item.id}
                                              className="society-card"
                                              style={{
                                                borderBottom: `4px solid ${borderColor}`,
                                              }}
                                            >
                                              <div className="card-content">
                                                <p className="card-title">
                                                  {item.name}
                                                </p>
                                                <div className="timeline">
                                                  <span className="timeline-date">
                                                    {formatDate(
                                                      item.submitedDate
                                                    )}
                                                  </span>

                                                  <span className="timeline-date">
                                                    {item.submitedTime}
                                                  </span>
                                                </div>
                                                <div className="status">
                                                  <span>Status:</span>
                                                  <span
                                                    className="status-text"
                                                    style={{
                                                      color:
                                                        item.status ===
                                                        "approved"
                                                          ? "green"
                                                          : item.status ===
                                                            "rejected"
                                                          ? "red"
                                                          : "orange",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item.status.toUpperCase()}
                                                  </span>
                                                </div>
                                                <div className="card-footer">
                                                  {item.status ===
                                                  "approved" ? (
                                                    <button
                                                      className="action-button"
                                                      onClick={() =>
                                                        handleViewTransaction(
                                                          item._id
                                                        )
                                                      }
                                                    >
                                                      <FaArrowRight />
                                                    </button>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <div  className="no-data-row"
                                        style={{ textAlign: "center", verticalAlign: "middle" }}>
                                          No Data
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <ViewAllAffiliateBills
                                    selectedAgencyId={selectedAgencyId}
                                    isAdmin={false}
                                  />
                                )}
                                <div className="card-billing-progress-body">
                                  <div className="card-billing-progress-details"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </AffiliateLayout>
    </div>
  );
};

export default ViewAffiliateSocietyList;
