import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import GuardLayout from '../lib/GuardLayout';
import { LanguageContext } from '../lib/LanguageContext';
import Swal from "sweetalert2";
import { PORT } from '../Api/api';
import axios from 'axios';
import Navbar from '../Navbar/Navbar';
import './ForgotStyle.css';
import { IoIosEyeOff, IoIosEye } from "react-icons/io";
import ButtonLoader from '../Loading/ButtonLoader';

function ChangePassword() {
const { language } = useContext(LanguageContext);
    const [issubmitting,setSubmitting]=useState(false);
    const navigate=useNavigate();
    const location=useLocation();
    const Data=location.state;
    const [showPassword, setShowPassword] = useState(false);
    
    const [userData, setUserData] = useState({

        newPassword: '',
        confirmPassword: '',
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const copyLogiInfo = { ...userData };
        copyLogiInfo[name] = value;
        setUserData(copyLogiInfo);
    };
       
        
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true)
        if(userData.newPassword.length<6){
            let msg = language === "english" 
            ? "The new password and confirm password do not match." 
            : "Password must be at least 6 characters long.";            
            return Swal.fire('Error', msg, 'error');
        }
        if (userData.newPassword !== userData.confirmPassword) {
            let msg=language === "english" ?"नया पासवर्ड और कन्फर्म पासवर्ड मेल नहीं खाते":"New password and confirm password do not match"
            setSubmitting(false)
            return Swal.fire('Error', msg, 'error');
          }
        try {  setSubmitting(true)
            const url = `${PORT}ChangePassword`;
            const payload={
                password:userData?.newPassword,
                otp:Data?.otp
            }
            if(Data.affiliateUser){
                payload.affiliateUser=Data.affiliateUser;
              }
              if(Data.societyUser){
                payload.societyUser=Data.societyUser;
              }
              // console.log('paspps',payload);
              
            const response = await axios.patch(url,payload );

            if (response.status === 200) {
                // handleSuccess('Password changed successfully');
                // Reset the form
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: language === "english" ?"पासवर्ड सफलतापूर्वक बदला गया..!":"Password changed successfully..!",
                    showConfirmButton: false,
                    timer: 900
                    });
                setUserData({newPassword: '', confirmPassword: '' });
                localStorage.removeItem('forgotEmail');
              
                setTimeout(()=>{
                    if(Data.affiliateUser){
                        navigate('/affiliateLogin')
                    }
                    if(Data.societyUser){
                        navigate('/login')
                    }
                },1000)
                
              
            }
        } catch (error) {
            // handleError(error.response?.data?.message || 'Error changing password');
            setSubmitting(false)
            Swal.fire({
                position: "center",
                icon: "error",
                title: language === "english" ?"पासवर्ड अपडेट करते समय आंतरिक सर्वर त्रुटि":"Internal Server Error While Updating Password",
                showConfirmButton: false,
                timer: 1500
                });
                navigate(-1)
        }finally{  setSubmitting(false)}
    };
    if(!Data){
        Swal.fire('Error', 'UnAuthorized Access !', 'error');
        setTimeout(()=>{
            navigate(-1)
        },500);
    }
    
  return (
    <div className="background_image">
    <GuardLayout>
    <Navbar/>
      <div className="main-form-affiliate">
        <div className="form-container-affiliate-login" style={{height:'300px'}}>
          <h2 className="affiliate-login_title">
            {language === "english" ? "पासवर्ड रीसेट" : "Reset Password"}
          </h2>

          <form onSubmit={handleSubmit}>
                
                      <div className="mb-3 mt-3 position-relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder={language === "english" ? "नया पासवर्ड" : "New Password"}
                            name="newPassword"
                            value={userData.newPassword}
                            onChange={handleChange}
                            required
                            minLength={6}
                            maxLength={20}
                        />
                        <span className="eye-icon position-absolute" onClick={togglePasswordVisibility}>
                            {showPassword ? <IoIosEye /> : <IoIosEyeOff />}
                        </span>
                    </div>

                    <div className="mb-4 mt-3 position-relative">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={language === "english" ? "नए पासवर्ड की पुष्टि करें" : "Confirm New Password"}
                            name="confirmPassword"
                            value={userData.confirmPassword}
                            onChange={handleChange}
                            required
                            minLength={6}
                            maxLength={20}
                        />
                    </div>
                    <div className="text-center">
                        <button className="forgot-submit-button" type="submit">
                        {issubmitting?<ButtonLoader/>:language === "english" ? "पासवर्ड बदलें" : "Change Password"} 
                        </button>
                    </div>
                </form>


        </div>
        </div>
        </GuardLayout>
     </div>
  )
}

export default ChangePassword
