import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../../lib/Layout";
import { toast, ToastContainer } from "react-toastify";
import { Box, Pagination, Stack, TextField, Tooltip } from "@mui/material";
import { LanguageContext } from "../../../../lib/LanguageContext";
import axios from "axios";
import { PORT } from "../../../../Api/api";
import { MdDelete, MdOutlineModeEdit } from "react-icons/md";
import Loading from "../../../../Loading/Loading";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaHouseUser, FaTimesCircle } from "react-icons/fa";
import { PermissionContext } from "../../../../lib/PermissionContext";
const ViewAffiliateUser = () => {
  const { language } = useContext(LanguageContext);
  const { permissions } = useContext(PermissionContext);
  const [affiliateUser, setAffiliateUser] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [rejectionMessage, setRejectionMessage] = useState();
  const navigate = useNavigate();
  const fetchAffiliateUser = async () => {
    try {
      const response = await axios.get(`${PORT}/getAffiliateUser`);
      setAffiliateUser(response.data);
      setFilteredData(response.data.reverse());
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching affiliate users:", error);
    }
  };

  useEffect(() => {
    fetchAffiliateUser();
  }, []);
  // Handle search input change
  const handleSearchInput = (e) => {
    const query = e.target.value.trim();
    setSearchQuery(query);

    // Filter data based on the search query
    const filtered = affiliateUser.filter(
      (user) =>
        user.agencyName.toLowerCase().includes(query.toLowerCase()) ||
        user.contactPerson.toLowerCase().includes(query.toLowerCase()) ||
        user.status.toLowerCase().includes(query.toLowerCase()) ||
        user.email.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page when search changes
  };

  // Pagination logic
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Get the data for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  // delete Affiliate User Functionlaity
  const handleDelete = async (id) => {
    // console.log(id);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        container: "my-swal",
      },
    });

    if (!result.isConfirmed) return;
    try {
      const response = await axios.delete(`${PORT}/deleteAffiliateUser/${id}`);
      // console.log(response.data.msg);
      fetchAffiliateUser();

      Swal.fire({
        title: "Deleted!",
        text: "User has been deleted.",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
  //edit Affiliate User Functionality
  const editAffiliateUser = async (id) => {
    navigate(`/admin/editAffiliateUser/${id}`);
  };
  const handleStatusChange = async (id, status) => {
    //18/12/24
    try {
      const statusTitle = status === "approved" ? "Approved" : "Rejected";
      status = statusTitle;
      const result = await Swal.fire({
        title: `Are you sure you want to ${statusTitle} this society?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, ${statusTitle} it!`,
      });
      if (result.isConfirmed) {
        let result2;
        if (statusTitle === "Rejected") {
          const options = [
            "Incomplete or incorrect information (e.g., missing business name, contact details)",
            "Lack of required licenses or certifications",
            "Expired or invalid business registration documents",
            "Negative background check or poor reputation",
            "Financial issues such as payment defaults or insolvency",
            "Non-compliance with local laws or regulations",
            "Fake or unverifiable references",
            "Duplicate registration attempts",
            "Invalid or unreachable contact information",
            "Inadequate infrastructure or operational setup",
            "Other", // "Other" option for custom input
          ];

          result2 = await Swal.fire({
            title: "Cause of Rejection",
            html: `
              <div class="form-group">
                <label for="rejectionReason" class="form-label">Select a reason:</label>
                <select id="rejectionReason" class="form-control">
                  ${options
                    .map(
                      (option, index) =>
                        `<option value="${index}">${option}</option>`
                    )
                    .join("")}
                </select>
              </div>
              <div class="form-group mt-3" id="customReasonGroup" style="display: none;">
                <label for="customReason" class="form-label">Custom Reason:</label>
                <input id="customReason" class="form-control" type="text" placeholder="Enter your reason" />
              </div>
            `,
            showCancelButton: true,
            confirmButtonText: "Save",
            focusConfirm: false,
            preConfirm: () => {
              const reasonSelect = document.getElementById("rejectionReason");
              const customReasonInput = document.getElementById("customReason");
              const selectedValue = reasonSelect.value;

              if (selectedValue === String(options.length - 1)) {
                // "Other" selected
                const customReason = customReasonInput.value.trim();
                if (!customReason) {
                  Swal.showValidationMessage(
                    "You need to provide a cause of rejection!"
                  );
                  return null;
                }
                return customReason; // Return custom input value
              } else {
                return options[selectedValue]; // Return selected dropdown value
              }
            },
            didOpen: () => {
              const reasonSelect = document.getElementById("rejectionReason");
              const customReasonGroup =
                document.getElementById("customReasonGroup");

              // Show/hide custom input based on dropdown selection
              reasonSelect.addEventListener("change", () => {
                if (reasonSelect.value === String(options.length - 1)) {
                  // "Other" selected
                  customReasonGroup.style.display = "block";
                } else {
                  customReasonGroup.style.display = "none";
                  document.getElementById("customReason").value = ""; // Clear custom input
                }
              });
            },
          });

          if (result2.isConfirmed) {
            setRejectionMessage(result2.value); // Set the selected or custom rejection message
          }
        }

        // console.log(result2);

        if (result2 && !result2.isConfirmed) {
          return;
        }
        console.log(result2);

        let approvedBy = await localStorage.getItem("roleId");
        approvedBy = approvedBy.replace(/^"|"$/g, "");
        const payload = {
          approvedBy,
          affiliate_id: id,
          status,
          rejectionReason: result2?.value || rejectionMessage || "Na",
        };
        // console.log(payload);
        // setLoading(true);
        const response = await axios.put(`${PORT}editAffiliateUser/${id}`, {
          status,
        });

        if (response.status == 200) {
          // setLoading(false);
          Swal.fire({
            title: "Success!",
            text: `The Agency has been ${statusTitle}.`,
            icon: "success",
            timer: 1500,
            timerProgressBar: true,
          });
          // setLoading(false);
          fetchAffiliateUser();
          try {
            const notify = await axios.post(
              `${PORT}notifyAgencyStatus`,
              payload
            );
          } catch (error) {
            console.log(
              "error while sending notification mail to Agency",
              error
            );
          }
        }

        // setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error updating society status:", error);
      // Swal.fire(
      //   "Error",
      //   "An Error Occurred while Updating Approval Status",
      //   "error"
      // );
    } finally {
      setLoading(false);
    }
  };
  // console.log(rejectioMessage);

  return (
    <div>
      <Layout>
        <>
          <div className="container-fluid py-4">
            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="card-header pb-0">
                    <div className="filtered-div">
                      {permissions[13]?.actions.read ? (
                        <div className="search-filter-box">
                          <Box
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#5e72e4",
                                  borderWidth: "1px",
                                  borderRadius: "5px",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#5e72e4",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#5e72e4",
                                },
                                "& .MuiInputLabel-root.Mui-error": {
                                  color: "#5e72e4",
                                },
                              },
                            }}
                          >
                            <TextField
                              id="search-input"
                              label={
                                language === "hindi" ? "Search..." : "खोज..."
                              }
                              variant="outlined"
                              value={searchQuery}
                              onChange={handleSearchInput}
                              className="search-input"
                            />
                          </Box>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="card-body px-0 pt-0 pb-2 w-100">
                    <div className="table-responsive p-0">
                      {loading ? (
                        <Loading />
                      ) : permissions[13]?.actions.read ? (
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                                {language === "hindi"
                                  ? "Agency Name"
                                  : "एजेंसी का नाम"}
                              </th>
                              <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                                {language === "hindi"
                                  ? "Contact Person"
                                  : "संपर्क व्यक्ति"}
                              </th>
                              <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                                {language === "hindi" ? "Email" : "ईमेल"}
                              </th>
                              <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                                {language === "hindi" ? "Password" : "पासवर्ड"}
                              </th>
                              <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                                {
                                  language === "hindi" ? "Status" : "स्थिति" //18/12/24}
                                }
                              </th>
                              <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                                {language === "hindi" ? "Actions" : "कार्रवाई"}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentData.length > 0 ? (
                              currentData.map((item, index) => (
                                <tr key={index}>
                                  <td className="align-middle text-center">
                                    {item.agencyName}
                                  </td>
                                  <td className="align-middle text-center">
                                    {item.contactPerson}
                                  </td>
                                  <td className="align-middle text-center">
                                    {item.email}
                                  </td>
                                  <td className="align-middle text-center">
                                    {item.password}
                                  </td>
                                  <td className="align-middle text-center text-bold">
                                    <span
                                      className={`status-badge ${item.status.toLowerCase()}`}
                                    >
                                      {item.status}
                                    </span>
                                  </td>
                                  <td className="align-middle d-flex justify-content-end me-5">
                                    {item.status == "Pending"&& permissions[13]?.actions.edit? (
                                      <>
                                        <Tooltip
                                          title={
                                            //18/12/24
                                            language === "hindi"
                                              ? "Reject"
                                              : "अस्वीकृत"
                                          }
                                          placement="top"
                                          arrow
                                        >
                                          <button
                                            className="edit-btn"
                                            onClick={() => {
                                              handleStatusChange(
                                                item._id,
                                                "rejected"
                                              );
                                            }}
                                          >
                                            <FaTimesCircle
                                              className="eyes_view "
                                              disabled={
                                                item.status !== "approved"
                                              }
                                            />
                                          </button>
                                        </Tooltip>
                                        <Tooltip
                                          title={
                                            language === "hindi"
                                              ? "Approve"
                                              : "स्वीकृत"
                                          }
                                          placement="top"
                                          arrow
                                        >
                                          <button
                                            className="edit-btn"
                                            onClick={() =>
                                              handleStatusChange(
                                                item._id,
                                                "approved"
                                              )
                                            }
                                          >
                                            <FaCheckCircle className="eyes_view" />
                                          </button>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {permissions[13]?.actions.edit && (
                                      <button
                                        className="edit-btn"
                                        onClick={() =>
                                          editAffiliateUser(item._id)
                                        }
                                      >
                                        <MdOutlineModeEdit
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={
                                            language === "hindi"
                                              ? "Click to Edit"
                                              : "संपादित करें"
                                          }
                                        />
                                      </button>
                                    )}
                                    {permissions[13]?.actions.delete && (
                                      <button
                                        className="dlt-btn"
                                        onClick={() => handleDelete(item._id)}
                                      >
                                        <MdDelete
                                          data-placement="top"
                                          title={
                                            language === "hindi"
                                              ? "Click to Delete"
                                              : "हटाएं"
                                          }
                                        />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5}>
                                  <div className="no_data_entry">
                                    {language === "hindi"
                                      ? "कोई डेटा नहीं"
                                      : "No data"}
                                  </div>
                                </td>
                              </tr>
                            )}

                            {/* Pagination row inside table body */}
                            {filteredData.length > itemsPerPage && (
                              <tr>
                                <td colSpan={5}>
                                  <div className="table-pagination text-center">
                                    <Stack spacing={2}>
                                      <Pagination
                                        count={Math.ceil(
                                          filteredData.length / itemsPerPage
                                        )}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        color="primary"
                                      />
                                    </Stack>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <div className="permission">
                          <h2>You do not have permission to read this data</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </>
      </Layout>
    </div>
  );
};

export default ViewAffiliateUser;
