import React from 'react';
import { Badge, Fab } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

function NotificationIcon({ onClick, count }) {
  return (
    <Fab
      color="primary"
      onClick={onClick}
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
      }}
    >
      <Badge badgeContent={count} color="error">
        <NotificationsIcon />
      </Badge>
    </Fab>
  );
}

export default NotificationIcon;

