import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import axios from "axios";
// Import necessary Chart.js modules
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { PORT } from "../Api/api";
import { ThreeCircles } from "react-loader-spinner";
// Register modules with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);
const SuperAdminUserCountPie = () => {
  const [apiData, setApiData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const fetchUserCounts = async () => {
    try {
      const response = await axios.get(`${PORT}/fetchAllUsersCount`);
      const data = response.data;
      setApiData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching society data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserCounts();
  }, []);
  // Prepare data for the chart
  const data = {
    labels: [
      `Active Users (${apiData?.activeUsers || 0})`,
      `Unactive Users  (${apiData?.unActiveUsers || 0})`,
      `Active House Owners (${apiData?.activeHouseOwners || 0})`,
      `Unactive House Owners (${apiData?.unActiveHouseOwners || 0})`,
      `Approved Affiliates (${apiData?.approvedAffiliates || 0})`,
      `Unapproved Affiliates (${apiData?.unApprovedAffiliates || 0})`,
      `Rejected Affiliates (${apiData?.rejectedAffiliates || 0})`,
    ],
    datasets: [
      {
        label: "User Data",
        data: [
          apiData?.activeUsers || 0,
          apiData?.unActiveUsers || 0,
          apiData?.activeHouseOwners || 0,
          apiData?.unActiveHouseOwners || 0,
          apiData?.approvedAffiliates || 0,
          apiData?.unApprovedAffiliates || 0,
          apiData?.rejectedAffiliates || 0,
        ],
        backgroundColor: [
          "#4caf50", // Green
          "#f44336", // Red
          "#2196f3", // Blue
          "#9c27b0", // Purple
          "#ffeb3b", // Yellow
          "#ff9800", // Orange
          "#795548", // Brown
        ],
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div className="row mt-4 ">
      <div className="col-lg-12 mb-lg-0 mb-4 mt-4">
        <div className="card z-index-2 h-100">
          <div className="mx-3 mt-3"></div>
          <div className="card-header pb-0 pt-3 bg-transparent">
            <h6 className="text-capitalize">
              {/* {language === "hindi" ? "Society" : " सोसाइटी"} */}
            </h6>
            <p className="text-sm mb-0">
              <i className="fa fa-arrow-up text-success"></i>
              {/* <span className="font-weight-bold"></span> in{" "}
                        {societyYear} */}
            </p>
            {isLoading ? (
              <div className="chartdata_loader">
                <ThreeCircles
                  visible={true}
                  height={50}
                  width={50}
                  color="#5e72e4"
                  ariaLabel="three-circles-loading"
                />
              </div>
            ) : Object.keys(apiData).length > 0 ? (
              <div style={{ width: "70%", margin: "auto" }}>
                <h6 style={{ textAlign: "center" }}>User Count by Category</h6>
                <Pie data={data} options={options} />
              </div>
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminUserCountPie;

// const PieChart = ({ apiData }) => {
//   // Prepare data for the chart
//   const data = {
//     labels: [
//       "Active Users",
//       "Unactive Users",
//       "Active House Owners",
//       "Unactive House Owners",
//       "Approved Affiliates",
//       "Unapproved Affiliates",
//       "Rejected Affiliates",
//     ],
//     datasets: [
//       {
//         label: "User Data",
//         data: [
//           apiData.activeUsers,
//           apiData.unActiveUsers,
//           apiData.activeHouseOwners,
//           apiData.unActiveHouseOwners,
//           apiData.approvedAffiliates,
//           apiData.unApprovedAffiliates,
//           apiData.rejectedAffiliates,
//         ],
//         backgroundColor: [
//           "#4caf50", // Green
//           "#f44336", // Red
//           "#2196f3", // Blue
//           "#9c27b0", // Purple
//           "#ffeb3b", // Yellow
//           "#ff9800", // Orange
//           "#795548", // Brown
//         ],
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Chart options
//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "right",
//       },
//       tooltip: {
//         enabled: true,
//       },
//     },
//   };

//   return (
//     <div style={{ width: "50%", margin: "auto" }}>
//       <h3 style={{ textAlign: "center" }}>Pie Chart View</h3>
//       <Pie data={data} options={options} />
//     </div>
//   );
// };
