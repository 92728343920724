
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAsTiNTbbJSWMM-_kH4Zl2B9-9i98tpv_8",
  authDomain: "guardxxx-57923.firebaseapp.com",
  projectId: "guardxxx-57923",
  storageBucket: "guardxxx-57923.firebasestorage.app",
  messagingSenderId: "245973840425",
  appId: "1:245973840425:web:47d723c5d68029e74ba238",
  measurementId: "G-Z9XNMNXZB8"
};
const firebaseApp = initializeApp(firebaseConfig);
// Optionally, export messaging if needed
const messaging = getMessaging(firebaseApp);
export { messaging };
export default firebaseApp;