import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import ButtonLoader from "../../../../../../Loading/ButtonLoader";
const DisputeModal = ({
  show,
  btnLoading,
  handleClose,
  handleSaveChanges,
  language,
}) => {
  const initialFormData = {
    selectedReason: "",
    disputeDescription: "",
    manualComment: "",
    screenshot: null,
    errors: {
      selectedReason: "",
      disputeDescription: "",
      manualComment: "",
      screenshot: "",
    },
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
      errors: { ...prevState.errors, [name]: "" }, // Clear error when user modifies fields
    }));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 1 * 1024 * 1024) {
      setFormData((prevState) => ({
        ...prevState,
        screenshot: null, // Clear invalid screenshot
        errors: {
          ...prevState.errors,
          screenshot:
            language === "hindi"
              ? "फ़ाइल का आकार 1MB से कम होना चाहिए।"
              : "File size should be less than 1MB.",
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        screenshot: file,
        errors: { ...prevState.errors, screenshot: "" }, // Clear screenshot error
      }));
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.selectedReason) {
      errors.selectedReason =
        language === "hindi"
          ? "कृपया विवाद का कारण चुनें।"
          : "Please select a reason for the dispute.";
    }

    if (formData.selectedReason === "other" && !formData.manualComment.trim()) {
      errors.manualComment =
        language === "hindi"
          ? "कृपया अन्य विवरण दर्ज करें।"
          : "Please provide additional details.";
    }

    if (!formData.disputeDescription.trim()) {
      errors.disputeDescription =
        language === "hindi"
          ? "कृपया विवाद विवरण दर्ज करें।"
          : "Please enter the dispute description.";
    }

    setFormData((prevState) => ({
      ...prevState,
      errors,
    }));

    return errors;
  };

  const handleSubmit = () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      return; // Don't proceed if there are validation errors
    }

    // Call parent save changes handler with formData
    handleSaveChanges(formData);

    // Reset form after submission
    setFormData(initialFormData);
  };

  const handleModalClose = () => {
    // Reset form data when modal is closed
    setFormData(initialFormData);
    handleClose(); // Call the parent handler to close the modal
  };

  return (

    <Modal show={show} onHide={handleModalClose} >
      <Modal.Header closeButton>
        <Modal.Title>
          {language === "hindi" ? "विवाद जोड़ें" : "Add Disputes"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Dropdown for Existing Reasons */}
          <Form.Group controlId="reason">
            <Form.Label>
              {language === "hindi"
                ? "विवाद का कारण चुनें"
                : "Select Reason for Dispute"}
            </Form.Label>
            <Form.Control
              as="select"
              name="selectedReason"
              value={formData.selectedReason}
              onChange={handleChange}
              className="dispute-select"
            >
              <option value="">
                {language === "hindi" ? "चुनें..." : "Select..."}
              </option>
              <option value="Wrong Calculation of Amount (Errors in the total calculation)">
                {language === "hindi"
                  ? "गलत गणना की गई राशि (कुल राशि में गणना त्रुटि)"
                  : "Wrong Calculation of Amount (Errors in the total calculation)"}
              </option>
              <option value="Delayed Payment (Payment was not received on time)">
                {language === "hindi"
                  ? "देरी से भुगतान (भुगतान समय पर प्राप्त नहीं हुआ)"
                  : "Delayed Payment (Payment was not received on time)"}
              </option>
              <option value="Missing Payment (A portion of the total due amount is missing)">
                {language === "hindi"
                  ? "भुगतान गायब है (कुल बकाया राशि का कुछ हिस्सा गायब है)"
                  : "Missing Payment (A portion of the total due amount is missing)"}
              </option>
              <option value="Duplicate Transaction (The same payment was processed multiple times)">
                {language === "hindi"
                  ? "डुप्लिकेट लेनदेन (एक ही भुगतान दो बार किया गया है)"
                  : "Duplicate Transaction (The same payment was processed multiple times)"}
              </option>
              <option value="Unauthorized Deduction (Deductions made without prior notice)">
                {language === "hindi"
                  ? "अनधिकृत कटौती (बिना पूर्व सूचना के कटौती की गई है)"
                  : "Unauthorized Deduction (Deductions made without prior notice)"}
              </option>
              <option value="Incorrect Commission Rate (The commission rate shown on the invoice is incorrect)">
                {language === "hindi"
                  ? "गलत कमीशन दर (चालान पर दिखाए गए कमीशन की दर गलत है)"
                  : "Incorrect Commission Rate (The commission rate shown on the invoice is incorrect)"}
              </option>
              <option value="Service Charge Error (Incorrect or unexpected service charges applied)">
                {language === "hindi"
                  ? "सेवा शुल्क त्रुटि (गलत सेवा शुल्क लगाया गया है)"
                  : "Service Charge Error (Incorrect or unexpected service charges applied)"}
              </option>
              <option value="Tax Miscalculation (Taxes were added incorrectly on the invoice)">
                {language === "hindi"
                  ? "कर की गलत गणना (चालान में कर गलत तरीके से जोड़ा गया है)"
                  : "Tax Miscalculation (Taxes were added incorrectly on the invoice)"}
              </option>
              <option value="Payment Not Received (No confirmation of payment receipt)">
                {language === "hindi"
                  ? "भुगतान प्राप्त नहीं हुआ (भुगतान की पुष्टि नहीं हुई है)"
                  : "Payment Not Received (No confirmation of payment receipt)"}
              </option>
              <option value="Discrepancy in Total Amount (Mismatch between the billed and received amount)">
                {language === "hindi"
                  ? "कुल राशि में विसंगति (दिखाई गई और प्राप्त राशि मेल नहीं खाती)"
                  : "Discrepancy in Total Amount (Mismatch between the billed and received amount)"}
              </option>
              <option value="Invoice Not Provided (No invoice was issued for the transaction)">
                {language === "hindi"
                  ? "चालान प्रदान नहीं किया गया (लेनदेन के लिए चालान जारी नहीं किया गया है)"
                  : "Invoice Not Provided (No invoice was issued for the transaction)"}
              </option>
              <option value="Late Fee Issue (Late fees were applied unnecessarily)">
                {language === "hindi"
                  ? "देर शुल्क समस्या (देर शुल्क अनावश्यक रूप से लगाया गया है)"
                  : "Late Fee Issue (Late fees were applied unnecessarily)"}
              </option>
              <option value="Partial Payment (Only a part of the total amount was paid)">
                {language === "hindi"
                  ? "आंशिक भुगतान (पूरी राशि का भुगतान नहीं किया गया)"
                  : "Partial Payment (Only a part of the total amount was paid)"}
              </option>
              <option value="Contract Violation (Breach of agreed contractual terms)">
                {language === "hindi"
                  ? "अनुबंध उल्लंघन (अनुबंध के शर्तों का पालन नहीं किया गया)"
                  : "Contract Violation (Breach of agreed contractual terms)"}
              </option>
              <option value="System Error (Billing error caused by a technical issue)">
                {language === "hindi"
                  ? "सिस्टम त्रुटि (तकनीकी समस्या के कारण बिलिंग में त्रुटि हुई)"
                  : "System Error (Billing error caused by a technical issue)"}
              </option>
              <option value="Other (Provide details for any other reason)">
                {language === "hindi"
                  ? "अन्य (किसी अन्य कारण के लिए विवरण दें)"
                  : "Other (Provide details for any other reason)"}
              </option>
            </Form.Control>
            {/* Error Message */}
            {formData.errors.selectedReason && (
              <Form.Text className="text-danger">
                {formData.errors.selectedReason}
              </Form.Text>
            )}
          </Form.Group>

          {/* Manual Comment Field */}
          {formData.selectedReason === "other" && (
            <Form.Group controlId="manualComment">
              <Form.Label>
                {language === "hindi"
                  ? "अन्य विवरण जोड़ें"
                  : "Add Other Details"}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="manualComment"
                placeholder={
                  language === "hindi"
                    ? "कृपया अन्य विवरण दर्ज करें"
                    : "Please enter additional details"
                }
                value={formData.manualComment}
                onChange={handleChange}
              />
              {/* Error Message */}
              {formData.errors.manualComment && (
                <Form.Text className="text-danger">
                  {formData.errors.manualComment}
                </Form.Text>
              )}
            </Form.Group>
          )}

          {/* Textarea for Dispute Description */}
          <Form.Group controlId="disputeDescription">
            <Form.Label>
              {language === "hindi" ? "विवाद" : "Dispute"}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="disputeDescription"
              placeholder={
                language === "hindi"
                  ? "कृपया विवाद विवरण दर्ज करें।"
                  : "Please enter a disputed Commission / Amount description."
              }
              value={formData.disputeDescription}
              onChange={handleChange}
            />
            {/* Error Message */}
            {formData.errors.disputeDescription && (
              <Form.Text className="text-danger">
                {formData.errors.disputeDescription}
              </Form.Text>
            )}
          </Form.Group>

          {/* File Upload for Screenshot */}
          <Form.Group controlId="screenshot">
            <Form.Label>
              {language === "hindi"
                ? "स्क्रीनशॉट अपलोड करें (वैकल्पिक)"
                : "Upload Screenshot (Optional)"}
            </Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
            />
            {/* Error Message */}
            {formData.errors.screenshot && (
              <Form.Text className="text-danger">
                {formData.errors.screenshot}
              </Form.Text>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleModalClose}
          disabled={btnLoading}
        >
          {language === "hindi" ? "बंद करें" : "Close"}
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={btnLoading}>
          {btnLoading ? (
            <ButtonLoader />
          ) : language === "hindi" ? (
            "सहेजें"
          ) : (
            "Save Changes"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DisputeModal;
