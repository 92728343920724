import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { PORT } from "../Api/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./nav.css";
import Layout from "../lib/Layout";
import AddBackbtn from "../lib/AddBackbtn";
import { LanguageContext } from "../lib/LanguageContext";
import { useFormikContext } from "formik";
import getCurrentDate from "../lib/CurrentData";
import getCurrentTime from "../lib/CurrentTime";
import ButtonLoader from "../Loading/ButtonLoader";

const AddSociety = () => {
  const { language } = useContext(LanguageContext);
  const [selectedHouses, setSelectedHouses] = useState();
  const [planeType, setPlaneType] = useState("");
  const [interval, setInterval] = useState();
  const [societyButtonDisable, setSocietyButtonDisable] = useState(false);
  const [subscription, setSubscription] = useState();
  const getRoleId = JSON.parse(localStorage.getItem("roleId"));
  const navigate = useNavigate();
  const [plane, setPlan] = useState("");
  const [b, setB] = useState([]);
  const initialValues = {
    name: "",
    address: "",
    defaultPermissionLevel: 4,
    societyContactNumber: "",
    role: "Society Admin",
    createdBy: getRoleId,
    state: "",
    city: "",
    status: "approved",
    planType: "",
    intervalType: "",
    discountType: "",
    discountAmount: "",
    pricePerHouse: "",
    currencyType: "",
    submitedDate: getCurrentDate(),
    submitedTime: getCurrentTime(),
    societyEmail: "",
  };
  //fetch price according to plan
  const fetchPriceAccToSelectedPlan = (plane) => {
    if (!subscription) return;
    const selectedPlan = subscription.find((plan) => plan.name === plane);
    setB(selectedPlan);
  };
  //addsociety
  const handleSubmit = async (values, actions) => {
    try {
      setSocietyButtonDisable(true);
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (
          key === "secretaryPhoto" ||
          key === "secretaryDetails" ||
          key === "societyImages" ||
          key === "superAdminDocument" ||
          key === "superAdminPhoto"
        ) {
          // Handle file arrays
          Array.from(values[key]).forEach((file) => {
            formData.append(key, file);
          });
        } else if (
          key === "pricePerHouse" ||
          (key === "currencyType" && planeType !== "Custom")
        ) {
          // Append pricePerHouse and currencyType
          if (key === "pricePerHouse") {
            formData.append(key, b?.price || values.pricePerHouse); // Ensure string type
          }
          if (key === "currencyType") {
            formData.append(key, b?.commissionCurrency || values.currencyType); // Ensure string type
          }
        } else {
          // Append other form data
          formData.append(key, values[key]);
        }
      });
      const response = await axios.post(`${PORT}/addSociety`, formData);
      toast.success("Society Added Successfully!");
      addBilling(response?.data?.addSociety);
      setTimeout(() => {
        navigate("/admin/society-details");
      }, 1000);
      setSocietyButtonDisable(false);
    } catch (error) {
      setSocietyButtonDisable(false);
      toast.dismiss();
      toast.error(error?.response?.data?.error);
      console.log(error);
    } finally {
      actions.setSubmitting(false);
    }
  };
  //addbill
  const addBilling = async (data) => {
    const id = data?._id;
    try {
      if (!data.societyHouseList || typeof data.societyHouseList !== "string") {
        throw new Error(
          "Invalid societyHouseList. Expected a string in the format 'min-max'"
        );
      }
      const dataArray = {
        society_id: id,
        intervalType: data.intervalType,
        discountType: data.discountType,
        societyHouseList: data.societyHouseList,
        planType: data.planType,
        currencyType: data.currencyType,
        totalHouse: "",
        discountAmount: data.discountAmount,
        amount: "",
        pricePerHouse: data.pricePerHouse,
        submitedDate: getCurrentDate(),
        submitedTime: getCurrentTime(),
      };
      const response = await axios.post(`${PORT}createBill`, dataArray);
    } catch (error) {
      console.error("Error in addBilling:", error.message);
    }
  };

  const useRevalidateOnLanguageChange = () => {
    const { validateForm } = useFormikContext();

    React.useEffect(() => {
      validateForm();
    }, [language, validateForm]);
  };
  const RevalidateOnLanguageChange = () => {
    useRevalidateOnLanguageChange();
    return null;
  };
  //handle price and selct houses according to plane
  const getsubscription = async () => {
    try {
      const response = await axios.get(`${PORT}/getSubscription`);
      setSubscription(response.data);
    } catch (error) {}
  };
  // Handle dynamic changes to societyHouseList
  const handleHouseChange = (e, setFieldValue) => {
    const selectedRange = e.target.value;
    setFieldValue("societyHouseList", selectedRange);
    setSelectedHouses(selectedRange);
    // Call the API or logic to get the subscription based on house range
    fetchSubscription(selectedRange, setFieldValue);
  };
  // Handle Discount Type Change
  const handleDiscountTypeChange = (e, setFieldValue) => {
    const selectedDiscountType = e.target.value;
    setFieldValue("discountType", selectedDiscountType);
    // If changing to "Flat", you might want to reset or change the discount amount calculation
    if (selectedDiscountType === "flat") {
      setFieldValue("discountAmount");
    } else {
      setFieldValue("discountAmount");
    }
  };
  // Simulate fetching the subscription plan based on house range
  const fetchSubscription = (houseRange, setFieldValue) => {
    if (!subscription) return;
    const selectedPlan = subscription.find(
      (plan) => plan.societyHouseList === houseRange
    );
    if (selectedPlan) {
      setPlaneType(selectedPlan.name);
      setInterval(selectedPlan.interval);
      setFieldValue("planType", selectedPlan.name);
      setFieldValue("intervalType", selectedPlan.interval || "monthly");
      setFieldValue("billingCycle", selectedPlan.interval);
      setFieldValue("pricePerHouse", selectedPlan.price);
      setFieldValue("currencyType", selectedPlan.commissionCurrency);
    } else {
      setFieldValue("planType", "");
      setFieldValue("intervalType", "");
      setPlaneType("");
      setInterval("");
    }
  };
  //handle plan Change
  const handlePlanTypeChange = (e, setFieldValue, values) => {
    const selectedPlan = e.target.value;
    // Set the selected plan type
    setFieldValue("planType", selectedPlan);
    if (selectedPlan === "Custom") {
      // Clear previous fetched values to allow manual input
      setFieldValue("pricePerHouse", ""); // Clear price
      setFieldValue("currencyType", ""); // Clear currency
      setFieldValue("intervalType", "monthly"); // Optional, clear interval if needed
      console.log("Custom plan selected, input values manually.");
    } else {
      // Fetch subscription details based on house range
      fetchSubscription(values.societyHouseList, setFieldValue);
    }
  };
  //function lock
  useEffect(() => {
    fetchPriceAccToSelectedPlan(plane);
  }, [plane]);
  useEffect(() => {
    getsubscription();
  }, []);
  return (
    <>
      <Layout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi" ? " Add Society" : "सोसायटी जोड़ें"}
          </h5>

          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <br />
        <div className="society_form_div">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting, setFieldValue, values }) => (
              <Form enctype="multipart/form-data">
                <div className="society_main">
                  <div className="society_main_div">
                    <div className="society_detail_heading">
                      {language === "hindi"
                        ? " Society details"
                        : "सोसायटी विवरण"}
                    </div>
                    <div>
                      <label className="edit_society_lable" htmlFor="name">
                        {language === "hindi"
                          ? "  Society Name "
                          : "सोसायटी का नाम "}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        id="name"
                        className="edit-input"
                        name="name"
                        required
                        maxLength="35"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error_msg_society"
                      />
                    </div>
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="societyLogo"
                      >
                        {language === "hindi"
                          ? "    Society Logo "
                          : "सोसायटी लोगो  "}
                        {/* <span className="Star_color">*</span> */}
                      </label>
                      <br />
                      <input
                        type="file"
                        className="edit-input"
                        // id="societyLogo"
                        name="societyLogo"
                        accept="image/*"
                        onChange={(event) => {
                          setFieldValue(
                            "societyLogo",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <ErrorMessage
                        name="societyLogo"
                        component="div"
                        className="error_msg_society"
                      />
                    </div>
                    {/* Address */}
                    <div>
                      <label className="edit_society_lable" htmlFor="address">
                        {language === "hindi" ? " Address" : "पता"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        className="edit-input"
                        id="address"
                        name="address"
                        maxLength="45"
                        required
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="error_msg_society"
                      />
                    </div>
                    {/* State */}
                    <div>
                      <label className="edit_society_lable" htmlFor="state">
                        {language === "hindi" ? " State" : "राज्य"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        className="edit-input"
                        id="state"
                        name="state"
                        maxLength="45"
                        required
                        autoComplete="off"
                      />
                    </div>
                    {/* City */}
                    <div>
                      <label className="edit_society_lable" htmlFor="city">
                        {language === "hindi" ? " City" : "शहर"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        className="edit-input"
                        id="city"
                        name="city"
                        maxLength="45"
                        required
                        autoComplete="off"
                      />
                    </div>
                    {/* societyContactNumber */}
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="societyContactNumber"
                      >
                        {language === "hindi"
                          ? "  Contact No."
                          : "संपर्क संख्या"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        className="edit-input"
                        id="societyContactNumber"
                        name="societyContactNumber"
                        required
                        pattern="\d{10}"
                        title="Please enter exactly 10 digits."
                        autoComplete="off"
                        maxLength={10}
                        // minLength={10}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          ); // Remove non-numeric characters
                          setFieldValue("societyContactNumber", numericValue); // Update Formik value
                        }}
                      />
                      <ErrorMessage
                        name="societyContactNumber"
                        component="div"
                        className="error_msg_society"
                      />
                    </div>
                    {/* societyHouseList */}
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="societyHouseList"
                      >
                        {language === "hindi"
                          ? "No. of Houses in Society"
                          : "सोसायटी में घरों की संख्या"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        as="select"
                        className="edit-input"
                        id="societyHouseList"
                        name="societyHouseList"
                        required
                        onChange={(e) => handleHouseChange(e, setFieldValue)}
                      >
                        <option value="">
                          {" "}
                          {language === "hindi"
                            ? "Select House Range"
                            : "हाउस रेंज चुनें"}{" "}
                        </option>
                        <option value="0-250">0 - 250</option>
                        <option value="250-500">250 - 500</option>
                        <option value="500-1000">500 - 1000</option>
                      </Field>
                    </div>
                    {/* Society Registration No. */}
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="societyRegistration"
                      >
                        {language === "hindi"
                          ? "Society Registration No."
                          : "सोसायटी पंजीकरण संख्या "}{" "}
                      </label>
                      <br />
                      <Field
                        type="text"
                        className="edit-input"
                        id="societyRegistration"
                        name="societyRegistration"
                        maxLength="45"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="societyRegistration"
                        component="div"
                        className="error_msg_society"
                      />
                    </div>
                    {/* society email */}
                    <div className="society-email">
                      <label
                        className="edit_society_lable"
                        htmlFor="societyRegistration"
                      >
                        {language === "hindi"
                          ? "Society Email"
                          : "सोसायटी ईमेल"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="email"
                        className="edit-input"
                        id="societyEmail"
                        name="societyEmail"
                        maxLength="70"
                        autoComplete="off"
                        required
                      />
                    </div>{" "}
                    <div
                      style={{ visibility: "hidden" }}
                      className="society-email"
                    >
                      <label
                        className="edit_society_lable"
                        htmlFor="societyRegistration"
                      >
                        {language === "hindi"
                          ? "Society Email"
                          : "सोसायटी ईमेल"}{" "}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        className="edit-input"
                        id="societyEmail"
                        name="societyEmail"
                        maxLength="45"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  {selectedHouses ? (
                    <div className="society_main_div">
                      <div className="society_detail_heading">
                        {language === "hindi" ? "Plan details" : "योजना विवरण"}
                      </div>
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="planType"
                        >
                          {language === "hindi" ? "Plan name " : "योजना नाम"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />
                        <Field
                          as="select"
                          id="planType"
                          name="planType"
                          required
                          value={values.planType}
                          className="edit-input "
                          onChange={(e) => {
                            setPlan(e.target.value);
                            // First handle your custom logic
                            handlePlanTypeChange(e, setFieldValue, values);
                            // Then update the planType field in Formik
                            setFieldValue("planType", e.target.value);
                          }}
                        >
                          <option value="">
                            {" "}
                            {language === "hindi"
                              ? "Change Plane"
                              : "योजना बदलें"}
                          </option>

                          <option value="Standard">Standard Plan</option>
                          <option value="Elite">Elite Plan</option>
                          <option value="Supreme">Supreme Plan</option>
                          <option value="Custom">Custom Plan</option>
                        </Field>
                      </div>
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="intervalType"
                        >
                          {language === "hindi" ? "Interval" : " अंतराल "}
                          <span className="Star_color">*</span>
                        </label>
                        <br />
                        <input
                          type="text"
                          id="intervalType"
                          className="edit-input "
                          name="intervalType"
                          required
                          maxLength="35"
                          value={values.intervalType.toUpperCase()}
                          readOnly
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* customplan */}
                  {values.planType === "Custom" ? (
                    <div className="society_main_div">
                      <div className="society_detail_heading">
                        {language === "hindi" ? "Price details" : "मूल्य विवरण"}
                      </div>
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="pricePerHouse"
                        >
                          {language === "hindi"
                            ? " Price Per household"
                            : " प्रत्येक घर का मूल्य(₹)"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />
                        <Field
                          type="number"
                          id="pricePerHouse"
                          name="pricePerHouse"
                          value={values.pricePerHouse}
                          onChange={(e) => {
                            setFieldValue("pricePerHouse", e.target.value); // Ensure it updates Formik state
                            console.log(
                              "pricePerHouse value updated:",
                              e.target.value
                            );
                          }}
                          // onBlur={handleBlur}
                          placeholder={
                            language === "hindi" ? "Enter price" : " मूल्य"
                          }
                          className="edit-input"
                          min="0" // Ensure no negative values
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="currencyType" className="editLabel">
                          {language === "english"
                            ? "भुगतान मुद्रा"
                            : " Payment Currency"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />
                        <select
                          id="currencyType"
                          name="currencyType"
                          value={values.currencyType}
                          onChange={(e) => {
                            setFieldValue("currencyType", e.target.value); // Ensure it updates Formik state
                            console.log(
                              "Commission Currency updated:",
                              e.target.value
                            );
                          }}
                          // onBlur={handleBlur}
                          className="edit-input"
                          required
                        >
                          <option value="" selected>
                            {language === "hindi"
                              ? "Select  Payment Currency "
                              : "भुगतान मुद्रा चुनें"}{" "}
                            {/* Placeholder option */}
                          </option>
                          <option value="INR">
                            {language === "hindi" ? " ₹ (INR)" : "₹ (रुपया)"}
                          </option>
                          <option value="USD" disabled={true}>
                            {language === "hindi" ? " $ (USD)" : "$ (डॉलर)"}
                          </option>
                          {/* You can add more currencies if needed */}
                        </select>
                      </div>
                    </div>
                  ) : null}
                  {selectedHouses ? (
                    <div className="society_main_div">
                      <div className="society_detail_heading">
                        {language === "hindi"
                          ? "Discount details"
                          : "छूट विवरण"}
                      </div>
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="discountType"
                        >
                          {language === "hindi"
                            ? "Discount Type"
                            : "छूट का प्रकार"}
                        </label>
                        <br />
                        <Field
                          as="select"
                          className="edit-input"
                          id="discountType"
                          name="discountType"
                          required
                          onChange={(e) =>
                            handleDiscountTypeChange(e, setFieldValue)
                          }
                        >
                          <option value="">
                            {" "}
                            {language === "hindi"
                              ? "Select Discount Type  "
                              : "डिस्काउंट प्रकार चुनें"}
                          </option>
                          <option value="fixed">
                            {language === "hindi"
                              ? "Fixed Discount"
                              : "निर्धारित छूट"}
                          </option>
                          <option value="flat">
                            {language === "hindi"
                              ? "Percentage Discount"
                              : "प्रतिशत छूट"}
                          </option>
                        </Field>
                      </div>
                      {/* { ? ( */}
                      {/* // Fixed Discount Input */}
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="discountAmount"
                        >
                          {values.discountType === "fixed"
                            ? language === "hindi"
                              ? "Discount Amount (₹)"
                              : "छूट राशि (₹)"
                            : language === "hindi"
                            ? "Discount Percentage (%)"
                            : "छूट प्रतिशत (%)"}
                        </label>
                        <br />
                        <Field
                          type="number"
                          id="discountAmount"
                          name="discountAmount"
                          className="edit-input"
                          value={values.discountAmount}
                          onChange={(e) =>
                            setFieldValue("discountAmount", e.target.value)
                          }
                          required
                        />
                      </div>
                      {/* ) : values.discountType === "flat" ? ( */}
                      {/* // Flat Discount Input */}
                      {/* <div>
                          <label
                            className="edit_society_lable"
                            htmlFor="discountAmount"
                          >
                            {language === "hindi"
                              ? "Discount Percentage (%)"
                              : "छूट प्रतिशत (%)"}
                          </label>
                          <br />
                          <Field
                            type="number"
                            id="discountAmount"
                            name="discountAmount"
                            className="edit-input"
                            value={values.discountAmount}
                            onChange={(e) =>
                              setFieldValue("discountAmount", e.target.value)
                            }
                            required
                          />
                        </div>
                      ) : null} */}
                    </div>
                  ) : null}

                  <div className="society_bottom_btn_div">
                    <button
                      className="society_btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <ButtonLoader />
                      ) : language === "hindi" ? (
                        "    Submit"
                      ) : (
                        "  जमा करना"
                      )}
                    </button>
                    <RevalidateOnLanguageChange />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default AddSociety;
