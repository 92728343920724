import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./Navbar/Navbar";
import Entry from "./Entry/Entry";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Purpose from "./Purpose/Purpose";
import House from "./Purpose/House";
import Verification from "./PhotoVerify/Verification";
import VerfiedUser from "./Purpose/VerfiedUser";
import "react-toastify/dist/ReactToastify.css";
import SignUp from "./GetData/SignUp";
import Login from "./GetData/Login";
import Private from "./Private/Private";
import AdminHome from "./AdminPannel/AdminHome";
import EntryData from "./AdminPannel/EntryData";
import PurposeData from "./AdminPannel/PurposeData";
import HouseData from "./AdminPannel/HouseData";
import MaidData from "./AdminPannel/MaidData";
import AddMaidEntry from "./AdminPannel/AddMaidEntry";
import VerifyEntries from "./AdminPannel/VerifyEntries";
import NotFoundPage from "./Purpose/NotFoundPage";
import Attendance from "./AdminPannel/Attendance";
import SocietyDetails from "./AdminPannel/SocietyDetails";
import AddSociety from "./AdminPannel/AddSociety";
import AddRegularEntries from "./AdminPannel/AddRegularEntries";
import AddHouseDetails from "./AdminPannel/AddHouseDetails";
import EditHouseData from "./AdminPannel/EditHouseData";
import EditHouseMaid from "./AdminPannel/EditHouseMaid";
import PrivateLogout from "./Private/PrivateLogout";
import Dashboard from "./Dashboard/Dashboard";
import { useNavigate } from "react-router-dom";
import Roles from "./Roles/Roles";
import axios from "axios";
import { PORT } from "./Api/api";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import ShowRoles from "./Roles/ShowRoles";
import EditRole from "./Roles/EditRole";
import ShowAdmin from "./AdminCurd/ShowAdmin";
import AddAdmin from "./AdminCurd/AddAdmin";
import EditAdmin from "./AdminCurd/EditAdmin";
import Setting from "./Entry/Setting";
import Profilesetting from "./profileSetting/Profilesetting";
import GuardAttendance from "./profileSetting/GuardAttendance";
import AdminAttendance from "./AdminSetting/AdminAttendance";
import AdminProfileSetting from "./AdminSetting/AdminProfileSetting";
import AdminSetting from "./AdminSetting/AdminSetting";
import SuperAdminSetting from "./AdminSetting/SuperAdminSetting";
import EditEntries from "./AdminPannel/EditEntries";
import EditPurposeData from "./AdminPannel/EditPurposeData";
import AddPurpose from "./AdminPannel/AddPurpose";
import AddEntry from "./AdminPannel/AddEntry";
import EntriesImportCsv from "./AdminPannel/importCsv/EntriesImportCsv";
import { createContext } from "react";
import PurposeImportCsv from "./AdminPannel/importCsv/PurposeImportCsv";
import FirstPage from "./Entry/FirstPage";
import UsersImportCsv from "./AdminPannel/importCsv/UsersImportCsv";
import EditSociety from "./AdminPannel/EditSociety";
import HouseListImportCsv from "./AdminPannel/importCsv/HouseListImportCsv";
import SocietyImportCsv from "./AdminPannel/importCsv/SocietyImportCsv";
import RegularListImportCsv from "./AdminPannel/importCsv/RegularListImportCsv";
import DailyAttendance from "./AdminPannel/attendance/DailyAttendance";
import WeeklyAttendance from "./AdminPannel/attendance/WeeklyAttendance";
import MonthlyAttendance from "./AdminPannel/attendance/MonthlyAttendance";
import ViewAnnouncement from "./AdminPannel/announcement/ViewAnnouncement/ViewAnnouncement";
import AddAnnouncement from "./AdminPannel/announcement/Add Announcement/AddAnnouncement";
import ViewComplaint from "./AdminPannel/complaint/ViewComplaint/ViewComplaint";
import AffiliateLogin from "./affiliate/affiliateAuth/affiliateLogin/AffiliateLogin";
import AffiliateRegister from "./affiliate/affiliateAuth/affiliateRegister/AffiliateRegister";
import AffiliateProtectedRoute from "./affiliate/affiliatePanel/affiliateLib/affiliateProtectedRoute/AffiliateProtectedRoute";
import AffiliateDashboard from "./affiliate/affiliatePanel/affiliatePages/affiliateDashboard/AffiliateDashboard";
import ViewClient from "./affiliate/affiliatePanel/affiliatePages/affiliateClient/clientTab/viewClient/ViewClient";
import AddClient from "./affiliate/affiliatePanel/affiliatePages/affiliateClient/clientTab/addClient/AddClient";
import ViewContracts from "./affiliate/affiliatePanel/affiliatePages/affiliateContracts/contractsTab/viewContracts/ViewContracts";
import ViewSocietyContracts from "./affiliate/affiliatePanel/affiliatePages/affiliateContracts/contractsTab/viewSocietyContract/ViewSocietyContracts";
import AddContracts from "./affiliate/affiliatePanel/affiliatePages/affiliateContracts/contractsTab/addContracts/AddContracts";
import EditContracts from "./affiliate/affiliatePanel/affiliatePages/affiliateContracts/contractsTab/editContracts/EditContracts";
import ViewSubscription from "./AdminPannel/subscription/subscriptionTab/viewSubscription/ViewSubscription";
import ViewAffiliateUser from "./AdminPannel/affiliateUser/affiliateUserTab/viewAffiliateUser/ViewAffiliateUser";
import AddSubscription from "./AdminPannel/subscription/subscriptionTab/addSubscription/AddSubscription";
import EditAffiliateUser from "./AdminPannel/affiliateUser/affiliateUserTab/editAffiliateUser/EditAffiliateUser";
import EditSubscription from "./AdminPannel/subscription/subscriptionTab/editSubscription/EditSubscription";
import ViewBillingDetails from "./AdminPannel/billing/billingTab/viewBillingDetails/ViewBillingDetails";

import ForgotPassword from "./ForgotPassword/SendOtpMail";
import SendOtpMail from "./ForgotPassword/SendOtpMail";
import ChangePassword from "./ForgotPassword/ChangePassword";

import EditClient from "./affiliate/affiliatePanel/affiliatePages/affiliateClient/clientTab/editClient/EditClient";
import ViewAffiliateBilling from "./AdminPannel/affliateBillingAndInvoice/affiliateBillingTab/viewAffiliateBilling/ViewAffiliateBilling";
import UnApprovedUser from "./affiliate/affiliatePanel/affiliatePages/unApprovedUser/UnApprovedUser";
import ViewAffiliateSocietyTransactions from "./AdminPannel/affliateBillingAndInvoice/affiliateBillingTab/viewAffiliateSocietyBilling/ViewAffiliateSocietyTransactions";
import ViewDisputes from "./affiliate/affiliatePanel/affiliatePages/affiliateDisputes/disputesTab/viewDisputes/ViewDisputes";
import ViewAffiliateSocietyList from "./affiliate/affiliatePanel/affiliatePages/affiliateBilling/billingTransactionsTab/viewAffilaiteSociety/ViewAffiliateSocietyList";
import ViewAffiliateTransactions from "./affiliate/affiliatePanel/affiliatePages/affiliateBilling/billingTransactionsTab/viewAffiliateTransactions/ViewAffiliateTransactions";

export const PermissionContext = createContext();
function App() {
  const [language, setLanguage] = useState("english");
  const handleLanguageChange = () => {
    const newLanguage = language === "english" ? "hindi" : "english";
    setLanguage(newLanguage);
  };
  // Get Affiliate Token
  const token = localStorage.getItem("token");
  //Get Admin Token
  const adminToken = localStorage.getItem("data");
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Guard Routes */}
          <Route path="/" element={<FirstPage language={language} />} />
          <Route path="/purpose" element={<Purpose language={language} />} />

          <Route path="/house-no" element={<House language={language} />} />
          <Route
            path="/photo-verification"
            element={<Verification language={language} />}
          />
          <Route
            path="/verified-user/:id"
            element={<VerfiedUser language={language} />}
          />
          <Route path="/login" element={<Login language={language} />} />
          <Route
            path="/profileSetting"
            element={<Profilesetting language={language} />}
          />
          <Route
            path="/showAttendance"
            element={<GuardAttendance language={language} />}
          />
          {/* Admin panel routes */}
          <Route
            path="/admin"
            element={
              adminToken ? <Navigate to="/admin/dashboard" /> : <Login />
            }
          />
          <Route path="/admin" element={<Private />}>
            <Route path="home" element={<AdminHome language={language} />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route
              path="entry-type"
              element={<EntryData language={language} />}
            />
            <Route
              path="entry-add"
              element={<AddEntry language={language} />}
            />

            <Route
              path="edit-entry/:id"
              element={<EditEntries language={language} />}
            />

            <Route
              path="purpose-type"
              element={<PurposeData language={language} />}
            />
            <Route
              path="purpose-add"
              element={<AddPurpose language={language} />}
            />

            <Route
              path="edit-purpose/:id"
              element={<EditPurposeData language={language} />}
            />

            <Route
              path="house-data"
              element={<HouseData language={language} />}
            />
            <Route
              path="maid-data"
              element={<MaidData language={language} />}
            />
            <Route
              path="add-maid-data"
              element={<AddMaidEntry language={language} />}
            />
            <Route
              path="regular/:id"
              element={<VerifyEntries language={language} />}
            />
            <Route
              path="attendance/:id"
              element={<Attendance language={language} />}
            />
            <Route
              path="society-details"
              element={<SocietyDetails language={language} />}
            />
            <Route
              path="add-society"
              element={<AddSociety language={language} />}
            />
            <Route
              path="edit-society/:id"
              element={<EditSociety language={language} />}
            />
            <Route
              path="add-regular/:id"
              element={<AddRegularEntries language={language} />}
            />
            <Route
              path="edit-regular/:id"
              element={<EditHouseMaid language={language} />}
            />

            <Route
              path="add-house-details"
              element={<AddHouseDetails language={language} />}
            />
            <Route
              path="Edit-house-details/:id"
              element={<EditHouseData language={language} />}
            />
            {/* //Roles Route */}
            <Route
              path="readRoles"
              element={<ShowRoles language={language} />}
            />
            <Route path="addRoles" element={<Roles language={language} />} />
            <Route
              path="editRoles/:id"
              element={<EditRole language={language} />}
            />
            {/* Admin */}
            <Route
              path="showUser"
              element={<ShowAdmin language={language} />}
            />
            <Route path="addUser" element={<AddAdmin language={language} />} />
            <Route
              path="editUser/:id"
              element={<EditAdmin language={language} />}
            />
            {/* //  Setting ROute */}
            <Route
              path="attendance"
              element={<AdminAttendance language={language} />}
            />
            <Route
              path="calender/daily"
              element={<DailyAttendance language={language} />}
            />
            <Route
              path="calender/weekly"
              element={<WeeklyAttendance language={language} />}
            />
            <Route
              path="calender/monthly"
              element={<MonthlyAttendance language={language} />}
            />
            <Route
              path="setting"
              element={<AdminSetting language={language} />}
            />
            <Route
              path="profilesetting"
              element={<AdminProfileSetting language={language} />}
            />
            <Route
              path="importEntries"
              element={<EntriesImportCsv language={language} />}
            />
            <Route
              path="importPurpose"
              element={<PurposeImportCsv language={language} />}
            />
            <Route
              path="importUsers"
              element={<UsersImportCsv language={language} />}
            />
            <Route
              path="importHouseList"
              element={<HouseListImportCsv language={language} />}
            />
            <Route
              path="importSocieties"
              element={<SocietyImportCsv language={language} />}
            />
            <Route
              path="importRegularList"
              element={<RegularListImportCsv language={language} />}
            />
            <Route
              path="viewAnnouncement"
              element={<ViewAnnouncement language={language} />}
            />
            <Route
              path="addAnnouncement"
              element={<AddAnnouncement language={language} />}
            />
            <Route
              path="viewComplaints"
              element={<ViewComplaint language={language} />}
            />
            {/* Subscription routes */}
            <Route
              path="viewSubscription"
              element={<ViewSubscription language={language} />}
            />
            <Route
              path="addSubscription"
              element={<AddSubscription language={language} />}
            />
            <Route
              path="editSubscription/:id"
              element={<EditSubscription language={language} />}
            />
            {/* Affiliate User Route */}
            <Route
              path="viewAffiliateUser"
              element={<ViewAffiliateUser language={language} />}
            />
            <Route
              path="editAffiliateUser/:id"
              element={<EditAffiliateUser />}
            />
            {/* //billing */}
            <Route path="viewBillingDetails" element={<ViewBillingDetails />} />
            <Route
              path="viewAffiliateBillingDetails"
              element={<ViewAffiliateBilling />}
            />
             <Route
              path="viewAffiliateSocietyTransactions/:id"
              element={<ViewAffiliateSocietyTransactions />}
            />
          </Route>
          {/* Affiliate Login */}
          <Route
            path="/affiliateLogin"
            element={<AffiliateLogin language={language} />}
          />
          <Route
            path="/affiliateRegister"
            element={<AffiliateRegister language={language} />}
          />

          <Route
            path="/affiliateLogin"
            element={
              token ? (
                <Navigate to="/affiliate/affiliateDashboard" />
              ) : (
                <Navigate to="/affiliateLogin" />
              )
            }
          />
          <Route path="/unApprovedUser" element={<UnApprovedUser />} />
          {/* Affiliate panel Protected Routes */}

          <Route
            path="/affiliate"
            element={
              token ? (
                <Navigate to="/affiliate/affiliateDashboard" />
              ) : (
                <AffiliateLogin />
              )
            }
          />
          <Route path="/affiliate" element={<AffiliateProtectedRoute />}>
            <Route path="affiliateDashboard" element={<AffiliateDashboard />} />
            <Route path="viewAffiliateClient" element={<ViewClient />} />
            <Route path="addAffiliateClient" element={<AddClient />} />
            <Route path="viewAffiliateContracts" element={<ViewContracts />} />
            <Route path="editAffiliateClient/:id" element={<EditClient />} />

          {/* disputes */}
          <Route path="viewAffiliateDisputes" element={<ViewDisputes/>} />
            {/* transactions */}
            <Route
              path="viewAffiliateSocietyList"
              element={<ViewAffiliateSocietyList />}
            />
             <Route
              path="viewAffiliateTransactions/:id"
              element={<ViewAffiliateTransactions/>}
            />
            {/* Contracts */}
            <Route
              path="viewAffiliateSocietyContracts/:id" //15/01/2025 minor changes 
              element={<ViewSocietyContracts />}
            />
               
            
            <Route
              path="addAffiliateContracts/:id"
              element={<AddContracts />}
            />
            <Route
              path="editAffiliateContracts/:id"
              element={<EditContracts />}
            />
          </Route>
          <Route path="/forgotPassword" element={<SendOtpMail />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
