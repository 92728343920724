import React, { useContext, useState, useEffect } from "react";
import Layout from "../../../lib/Layout";
import { LanguageContext } from "../../../lib/LanguageContext";
import { PORT } from "../../../Api/api";
import axios from "axios";
import { formatDate } from "../../../lib/FormattedDate";
import { FaEye, FaSpinner, FaCheck } from "react-icons/fa";
import Loading from "../../../Loading/Loading";
import { Tooltip } from "@mui/material";
import "./style.css";
import { IoEyeSharp } from "react-icons/io5";
import ViewComplaintModal from "../ViewFullComplaintInModal/ViewComplaintModal";
import { PermissionContext } from "../../../lib/PermissionContext";
import { FaHouseChimney } from "react-icons/fa6";
import { RiShieldUserFill } from "react-icons/ri";
import { FaListAlt } from "react-icons/fa";
import Swal from "sweetalert2";
const ViewComplaint = () => {
  const {language} = useContext(LanguageContext);
  const society_id = JSON.parse(localStorage.getItem("society_id")) || null;
  const [complaintData, setComplaintData] = useState([]);
  const { permissions } = useContext(PermissionContext);
  const [filterType, setFilterType] = useState("house"); // Default filter
  const [loading, setLoading] = useState(true);
  const fetchComplaints = async () => {
    try {
      const response = await axios.get(`${PORT}getComplaints`);
      const filterAnnouncementAccToSociety = response?.data?.complaints?.filter(
        (item) => item?.society_id?._id === society_id
      );
      // console.log(filterAnnouncementAccToSociety)
      let filteredComplaints = filterAnnouncementAccToSociety || [];
      // Apply filter based on filterType
      if (filterType === "house") {
        filteredComplaints = filteredComplaints.filter(
          (item) => item?.house_id?._id
        );
      } else if (filterType === "guard") {
        filteredComplaints = filteredComplaints.filter(
          (item) => item?.guard_id?._id
        );
      } else if (filterType === "all") {
        filteredComplaints = filteredComplaints.filter(
          (item) => item?.society_id?._id === society_id
        );
      }
      setComplaintData(filteredComplaints.reverse());
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchComplaints();
  }, [filterType]);
  const updateStatus = async (complaintId, newStatus) => {
    const status = newStatus;
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `You want to change the status to ${status.toUpperCase()}? You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        container: "my-swal",
      },
    });

    if (!result.isConfirmed) return;
    try {
      const response = await axios.post(
        `${PORT}/updateComplaintStatus/${complaintId}`,
        { status: newStatus }
      );
      fetchComplaints();
    } catch (error) {
      alert("Failed to update complaint status.");
    }
  };

  return (
    <div>
      <Layout>
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12">
              <div className="top-heading-compaints-div">
                <button
                  onClick={() => setFilterType("house")}
                  // disabled={filterType === "house"}
                  className={
                    filterType === "house"
                      ? "active-button"
                      : "active-button-disable"
                  }
                >
                  <FaHouseChimney className="top-heading-compaints-div-icons" />

                  {language === "english"
                    ? "घर की शिकायतें"
                    : "House Compaints"}
                </button>
                <button
                  onClick={() => setFilterType("guard")}
                  // disabled={filterType === "guard"}
                  className={
                    filterType === "guard"
                      ? "active-button"
                      : "active-button-disable"
                  }
                >
                  <RiShieldUserFill className="top-heading-compaints-div-icons" />

                  {language === "english"
                    ? "गार्ड की शिकायतें"
                    : "  Guard Compaints"}
                </button>
                <button
                  onClick={() => setFilterType("all")}
                  // disabled={filterType === "all"}
                  className={
                    filterType === "all"
                      ? "active-button"
                      : "active-button-disable"
                  }
                >
                  <FaListAlt className="top-heading-compaints-div-icons" />

                  {language === "english"
                    ? "सभी शिकायतें"
                    : "     All Complaints"}
                </button>
              </div>

              <div className="view-complaint-main-div">
                <div className="card-body px-0 pt-0 pb-2 w-100 ">
                  {loading ? (
                    <Loading />
                  ) : permissions[11]?.actions?.read ? (
                    <div className="table-responsive p-0 bg-black">
                      <table className="table align-items-center mb-0  ">
                        <thead>
                          <tr>
                            <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                              {language === "english" ? "श्रेणी" : "Category"}
                            </th>
                            <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                              {language === "english" ? "विवरण" : "Description"}
                            </th>
                            <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                              {language === "english"
                                ? "प्रकाशन तिथि"
                                : "Publish Date"}
                            </th>
                            <th className="text-dark text-center text-sm font-weight-bolder opacity-7 ps-2">
                              {language === "english" ? "प्रकार" : "status"}
                            </th>
                            <th className="text-center text-dark text-sm font-weight-bolder opacity-7">
                              {language === "english" ? "कार्रवाई" : "Action"}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {complaintData.length > 0 ? (
                            complaintData.map((item, idx) => (
                              <>
                                <tr
                                  key={idx}
                                  className="view-announcement-content-card"
                                >
                                  <td className="text-center align-middle">
                                    {item.category}
                                  </td>
                                  <td className="text-center align-middle">
                                    {item.description.length > 50
                                      ? item.description.substring(0, 30) +
                                        "..."
                                      : item.description}
                                  </td>
                                  <td className="text-center align-middle">
                                    {formatDate(item.submitedDate || "")}
                                  </td>
                                  <td className="text-center align-middle">
                                    <span
                                      style={{
                                        color:
                                          item.status === "resolved"
                                            ? "green"
                                            : item.status === "inProgress"
                                            ? "red"
                                            : "orange",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item.status.toUpperCase()}
                                    </span>
                                  </td>
                                  <td className="text-center align-middle gap-2 text-center  d-flex justify-content-center">
                                    <Tooltip
                                      title={"View"}
                                      placement="top"
                                      arrow
                                    >
                                      <div className="edit-btn-complaint">
                                        <ViewComplaintModal data={item} />
                                        {/* <IoEyeSharp /> */}
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      title={"In Progress"}
                                      placement="top"
                                      arrow
                                    >
                                      <div
                                        className="edit-btn-complaint"
                                        onClick={() =>
                                          updateStatus(item._id, "inProgress")
                                        }
                                      >
                                        <FaSpinner />
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      title={"Resolved"}
                                      placement="top"
                                      arrow
                                    >
                                      <div
                                        className="edit-btn-complaint"
                                        onClick={() =>
                                          updateStatus(item._id, "resolved")
                                        }
                                      >
                                        <FaCheck />
                                      </div>
                                    </Tooltip>
                                  </td>
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5}>
                                <div className="no_data_entry">No data</div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="permission">
                      <h2>You do not have permission to read this data</h2>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ViewComplaint;
