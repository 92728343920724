import { Outlet, Navigate } from "react-router-dom";
import decodeToken from "../decodeToken/DecodeToken";
function AffiliateProtectedRoute() {
  const token = localStorage.getItem("token");
  const CheckAccount=decodeToken(token)

  const isUserLoggedIn = token !== null && CheckAccount?.status==="Approved";
  return isUserLoggedIn ? <Outlet /> : <Navigate to="/affiliateLogin" />;
}
export default AffiliateProtectedRoute;
