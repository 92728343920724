import React from "react";
import { ThreeCircles } from "react-loader-spinner";

const SmallLoader = ({ visible = true, size = 20, color = "#5e72e4" }) => {
  return (
    // <div className="d-flex justify-content-center align-items-center">
      <ThreeCircles
        visible={visible}
        height={size}
        width={size}
        color={color}
        ariaLabel="small-loader"
      />
    // </div>
  );
};

export default SmallLoader;
