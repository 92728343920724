import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import AffiliateLayout from "../../../../affiliateLib/affiliateLayout/AffiliateLayout";
import { useNavigate, useParams } from "react-router-dom";
import { PORT } from "../../../../../../Api/api";
import axios from "axios";
import { ThreeCircles } from "react-loader-spinner";
import { Tooltip } from "@mui/material";
import { formatDate } from "../../../../../../lib/FormattedDate";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import Swal from "sweetalert2";
import { CiEdit } from "react-icons/ci";
import AddBackbtn from "../../../../../../lib/AddBackbtn";
import { IoMdArrowBack } from "react-icons/io";
import { MdReportProblem } from "react-icons/md";
import { Modal, Button, Form } from "react-bootstrap";
import ButtonLoader from "../../../../../../Loading/ButtonLoader";
import DisputeModal from "./DisputeModal ";
import ViewDisputeModal from "../../../../../../DisputeChat/ViewDisputeModal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TiExport } from "react-icons/ti";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Papa from "papaparse";

const ViewAffiliateTransactions = () => {
  const { language } = useContext(LanguageContext);
  const [billsData, setBillsData] = useState([]);
  const [billDetails, setBillDetails] = useState([]);
  const [getId, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [commissionEditView, setCommissionEditView] = useState(false);
  const [commissionEditCancelView, setCommissionEditCancelView] =
    useState(false);
  const [show, setShow] = useState(false);
  const [disputeChatShow, setDisputeChatShow] = useState(false);
  const [dispute, setDispute] = useState("");
  const [error, setError] = useState("");
  const [commissionValues, setCommissionValues] = useState({}); // Tracks values for each row
  const [filterData, setFilterData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;
  const perPage = 12;
  //fetchSocietyBills
  const fetchSocietyBills = async () => {
    if (id) {
      try {
        const response = await axios.get(`${PORT}/getCommission/${id}`);
        const res = (await response.data.commission) || [];
        // const filterBillsWithSocietyId = await res.filter(
        //   (item) => item?.society_id?._id === id
        // );
        setBillsData(res[0]);
        setId(res[0]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("Error fetching society data:", error);
      }
    }
  };
  //custom Function to convert the Date in month
  function formatDateToCustom(dateString) {
    const date = new Date(dateString);

    // Map of month numbers to short names
    const monthShortNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    // Extract date parts
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = monthShortNames[date.getMonth()]; // Converts month to short name
    const year = date.getFullYear(); // Gets the full year

    // Combine parts in the desired format
    return `${day}-${month}-${year}`;
  }
  //handlePaymentStatus
  const handlePaymentStatus = async (item, paymentStatus) => {
    const data = {
      mainBillId: getId?._id,
      billId: item?._id,
      affiliateId: getId?.affiliateId?._id,
      affiliateCommission: item?.affiliateCommission,
      paymentStatus: paymentStatus,
    };
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to pay this?",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (!result.isConfirmed) return;
    try {
      const response = await axios.post(
        `${PORT}/modifyAffiliateCommissionStatus`,
        data
      );
      Swal.fire({
        title: "Updated!",
        text: `${response.data.message}`,
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
      fetchSocietyBills();
    } catch (error) {
      console.log("Error fetching society data:", error);
    }
  };
  //custom Function to convert the Date in month
  function formatDateToCustom(dateString) {
    const date = new Date(dateString);

    // Map of month numbers to short names
    const monthShortNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    // Extract date parts
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = monthShortNames[date.getMonth()]; // Converts month to short name
    const year = date.getFullYear(); // Gets the full year

    // Combine parts in the desired format
    return `${day}-${month}-${year}`;
  }
  //handleCommissionEdit
  const handleCommissionEdit = (index, items) => {
    setCommissionEditView((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the edit view for the specific row
    }));
    setCommissionEditCancelView((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the edit view for the specific row
    }));
    // Initialize input value with the current commission if entering edit mode
    if (!commissionEditView[index]) {
      setCommissionValues((prev) => ({
        ...prev,
        [index]: items[index]?.affiliateCommission || 0,
      }));
    }
  };
  //handleInputChange
  const handleInputChange = (index, value) => {
    if (/^\d*\.?\d*$/.test(value)) {
      // Allow only numbers and decimals
      setCommissionValues((prev) => ({
        ...prev,
        [index]: value,
      }));
    }
  };

  //handleCancel
  const handleCancel = (index, items) => {
    setCommissionEditView((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the edit view for the specific row
    }));
    setCommissionEditCancelView((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the edit view for the specific row
    }));
  };

  //modal handler
  const handleClose = () => {
    setShow(false);
    setDispute("");
  };
  const handleCloseDisputeChat = () => {
    fetchSocietyBills();
    setDisputeChatShow(false);
    setDispute("");
  };

  const handleShowDisputeChat = (item) => {
    console.log(item);

    if (item?.disputeId?._id) {
      setDispute(item?.disputeId?._id);
      setDisputeChatShow(true);
    }
  };

  const handleShow = (item) => {
    if (item?.disputeId?.status == "Pending") {
      Swal.fire(
        "Warning !",
        "Dispute Already Raised & Under Process",
        "warning"
      );
    } else {
      setShow(true);
      setBillDetails(item);
    }
  };

  //handle disputes
  const handleDisputeChange = (e) => {
    setDispute(e.target.value);
  };

  const handleSaveChanges = async (formData) => {
    console.log("DSDSD", formData);

    // try {
    //   setBtnLoading(true);
    //   const data = {
    //     mainBillId: billsData?.billId,
    //     affiliateId: billsData?.affiliateId?._id,
    //     societyId: billsData?.society_id?._id,
    //     billId: billDetails?.invoiceNo,
    //     disputeReason: formData.selectedReason === "other"
    //     ? formData.manualComment
    //     : formData.selectedReason,
    //     disputeDescription: formData.disputeDescription,
    //   };
    //   if (formData.screenshot) {
    //     data.screenshot=formData.screenshot;
    //   }
    //   // console.log('sss',data);
    //   const response = await axios.post(`${PORT}/raisedDispute`, data);
    //   // console.log(response);
    try {
      setBtnLoading(true);

      // Create FormData object
      const formDataPayload = new FormData();
      formDataPayload.append("mainBillId", billsData?.billId);
      formDataPayload.append("affiliateId", billsData?.affiliateId?._id);
      formDataPayload.append("societyId", billsData?.society_id?._id);
      formDataPayload.append("billId", billDetails?.invoiceNo);
      formDataPayload.append(
        "disputeReason",
        formData.selectedReason === "other"
          ? formData.manualComment
          : formData.selectedReason
      );
      formDataPayload.append("disputeDescription", formData.disputeDescription);

      // Check if the screenshot file exists
      if (formData.screenshot) {
        console.log("inside", formData.screenshot);

        formDataPayload.append("screenshot", formData.screenshot);
      }

      console.log(formDataPayload);

      // Send the request
      const response = await axios.post(
        `${PORT}/raisedDispute`,
        formDataPayload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      handleClose();
      Swal.fire({
        position: "center", // Position at the top right
        icon: "success", // Icon type, can be success, error, etc.
        title: "Success!", // Title of the alert
        text: "Dispute Raised Successfully", // Your custom message
        showConfirmButton: false, // Hides the confirm button
        timer: 1500, // Auto-closes after 1.5 seconds
      });
      setBtnLoading(false);
      fetchSocietyBills();
    } catch (error) {
      console.log(error);
      Swal.fire("Error", `An Error Occured while Raising Disute!`, "error");
      setBtnLoading(false);
    } finally {
      setDispute("");
    }
  };
  //function lock
  useEffect(() => {
    fetchSocietyBills();
  }, []);

  const exportToExcel = (filterData, filename) => {
    if (!filterData || filterData.length === 0) {
      Swal.fire(`Error`, `No data to export!`, `error`);
      return;
    }
    const ws = XLSX.utils.json_to_sheet(
      filterData.map((item) => ({
        BillingCycle: item.billingCycle ? item.billingCycle : "Not Added",
        BillStartDate: item.billing_period_startDate
          ? formatDateToCustom(item.billing_period_startDate)
          : "Not Added",
        BillEndDate: item.billing_period_endDate
          ? formatDateToCustom(item.billing_period_endDate)
          : "Not Added",
        InvoiceNo: item.invoiceNo ? item.invoiceNo : "Not Added",
        TotalHouses: item.totalHouse ? item.totalHouse : "Not Added",

        Amount: item.total ? item.total : "Not Added",
        DiscountAmount: item.discountAmount ? item.discountAmount : "0",

        "Net Payable": item.total || "NA",

        DueDate: item.dueDate ? formatDate(item.dueDate) : "Not Added",
        AffiliateCommission: item.affiliateCommission
          ? item.affiliateCommission
          : "Not Added",
        PaymentStatus: item.paymentStatus ? item.paymentStatus : "Not Added",
        DisputeStatus: item.disputeId ? item.disputeId.status : "Not Added",
      }))
    );

    // Set column widths
    const wscols = new Array(17).fill({ wch: 30 }); // All columns set to 30 width
    ws["!cols"] = wscols;

    // Highlight rows with specific conditions
    const lightOrange = { fill: { fgColor: { rgb: "FFECB3" } } }; // Light orange color
    Object.keys(ws).forEach((cell) => {
      if (cell[0] !== "!") {
        const rowNumber = parseInt(cell.match(/\d+/)[0], 10) - 2; // Adjust for header row
        if (
          filterData[rowNumber] &&
          (filterData[rowNumber].paymentStatus === "unpaid" ||
            (filterData[rowNumber].disputeId &&
              filterData[rowNumber].disputeId.status === "pending"))
        ) {
          ws[cell].s = lightOrange;
        }
      }
    });

    // Create workbook and append sheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Write workbook and download
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const currentTimeAndDate = new Date()
      .toLocaleString()
      .replace(/[/, :]/g, "_");
    FileSaver.saveAs(blob, `${filename}_${currentTimeAndDate}.xlsx`);
  };

  const exportToCSV = (filterData, filename) => {
    if (!filterData || filterData.length === 0) {
      Swal.fire(`Error`, `No data to export!`, `error`);
      return;
    }

    // Map data into the desired CSV structure
    const csvData = filterData.map((item) => ({
      BillingCycle: item.billingCycle ? item.billingCycle : "Not Added",
      BillStartDate: item.billing_period_startDate
        ? formatDateToCustom(item.billing_period_startDate)
        : "Not Added",
      BillEndDate: item.billing_period_endDate
        ? formatDateToCustom(item.billing_period_endDate)
        : "Not Added",
      InvoiceNo: item.invoiceNo ? item.invoiceNo : "Not Added",
      TotalHouses: item.totalHouse ? item.totalHouse : "Not Added",

      Amount: item.total ? item.total : "Not Added",
      DiscountAmount: item.discountAmount ? item.discountAmount : "0",

      "Net Payable": item.total || "NA",

      DueDate: item.dueDate ? formatDate(item.dueDate) : "Not Added",
      AffiliateCommission: item.affiliateCommission
        ? item.affiliateCommission
        : "Not Added",
      PaymentStatus: item.paymentStatus ? item.paymentStatus : "Not Added",
      DisputeStatus: item.disputeId ? item.disputeId.status : "Not Added",
    }));

    // Convert JSON data to CSV
    const csv = Papa.unparse(csvData);

    // Create a Blob and save as a CSV file
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const currentTimeAndDate = new Date()
      .toLocaleString()
      .replace(/[/, :]/g, "_");
    FileSaver.saveAs(blob, `${filename}_${currentTimeAndDate}.csv`);
  };

  const handleSearchInput = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  useEffect(() => {
    let filteredData = billsData?.transactions || []; // Make sure you're starting with the original data

    // Apply start date filter billsData.transactions
    if (startDate || endDate) {
      const start = startDate
        ? new Date(startDate).toISOString().split("T")[0]
        : null;
      const end = endDate
        ? new Date(endDate).toISOString().split("T")[0]
        : null;

      filteredData = filteredData.filter((item) => {
        const formattedStartDate = new Date(item.billing_period_startDate)
          .toISOString()
          .split("T")[0];
        const formattedEndDate = new Date(item.billing_period_endDate)
          .toISOString()
          .split("T")[0];

        // Check conditions based on available dates
        if (start && end) {
          return formattedStartDate >= start && formattedEndDate <= end;
        } else if (start) {
          return formattedStartDate >= start;
        } else if (end) {
          return formattedEndDate <= end;
        }
        return true;
      });
    }

    // Apply search query filter
    if (searchQuery && searchQuery.trim() !== "") {
      const query = searchQuery.toLowerCase();
      filteredData = filteredData.filter((item) => {
        return (
          item.paymentStatus.toLowerCase() === query || // Exact match for payment status
          item?.disputeId?.status?.toLowerCase().includes(query) || // Partial match for dispute status
          item.invoiceNo.toLowerCase().includes(query) // Partial match for invoice number
        );
      });
    }
    setFilterData(filteredData); // Update state with filtered results
    setTotalPages(Math.ceil(filteredData?.length / perPage)); // Update total pages
    setCurrentPage(1); // Reset to page 1 after filtering
  }, [searchQuery, startDate, endDate, billsData, perPage]);

  return (
    <div>
      <AffiliateLayout>
        <div class="container-fluid py-4 ">
          <div class="row">
            <div class="col-12 ">
              <div className="affiliate-transactions-view">
                <h5 className="heading_top">
                  {language === "hindi" ? "Transactionis View" : "लेनदेन दृश्य"}
                </h5>

                <div></div>
              </div>
              <div className="hrline"></div>
              <div className="affiliate-transactions-view-heading">
                <button onClick={() => navigate(-1)}>
                  {" "}
                  <IoMdArrowBack className="regular_entries_back_btn" />{" "}
                </button>
              </div>

              <div class="card mb-4">
                <div class="card-header pb-0">
                  <div className="card-body px-0 pt-0 pb-2 w-100 ">
                    {/* Top Section */}
                    <div className="section-wrapper">
                      {/* Current Plan Summary */}
                      <div className="card-billing-wrapper">
                        <div className="card-billing-heading1">
                          <div className="card-billing-heading-title">
                            {language === "hindi"
                              ? "  Society Details"
                              : "सोसाइटी विवरण"}
                          </div>
                          <div className="card-billing-heading-button">
                            {/* <button>Upgrade</button> */}
                          </div>
                        </div>
                        {billsData ? (
                          <div className="card-billing-body">
                            <div className="card-billing-body-details">
                              <p>
                                <h6>
                                  {language === "hindi"
                                    ? "Society Name"
                                    : "सोसाइटी का नाम"}
                                </h6>
                                <strong
                                  style={{
                                    // backgroundColor: bgColorPlanType,
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    color: "#000", // Black text for contrast
                                  }}
                                >
                                  {loading ? (
                                    <div className="">
                                      <ThreeCircles
                                        visible={true}
                                        height={30}
                                        width={30}
                                        color="#5e72e4"
                                        ariaLabel="three-circles-loading"
                                      />
                                    </div>
                                  ) : (
                                    billsData?.society_id?.name || ""
                                  )}
                                </strong>
                              </p>
                            </div>
                            <div className="card-billing-body-details">
                              <p>
                                <h6>
                                  {language === "hindi"
                                    ? "Agency Name"
                                    : "एजेंसी का नाम"}
                                </h6>

                                <strong
                                  style={{
                                    // backgroundColor: bgColorHouseCount,
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    color: "#000", // Black text for contrast
                                  }}
                                >
                                  {loading ? (
                                    <div className="">
                                      <ThreeCircles
                                        visible={true}
                                        height={30}
                                        width={30}
                                        color="#5e72e4"
                                        ariaLabel="three-circles-loading"
                                      />
                                    </div>
                                  ) : (
                                    billsData.affiliateId?.agencyName || ""
                                  )}
                                </strong>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="billing-no-data"></div>
                        )}
                      </div>
                    </div>
                    {/* Top Section */}
                    <div className="section-wrapper">
                      {/* Current Plan Summary */}
                      <div className="card-billing-wrapper">
                        <div className="card-billing-heading1">
                          <div className="card-billing-heading-title">
                            {language === "hindi"
                              ? "Commission to be Pay"
                              : "भुगतान करने के लिए कमीशन"}
                          </div>
                          <div className="card-billing-heading-button">
                            {/* <button>Upgrade</button> */}
                          </div>
                        </div>
                        {billsData ? (
                          <div className="card-billing-body">
                            <div className="card-billing-body-details">
                              <p>
                                <h6>
                                  {language === "hindi"
                                    ? "Total commission"
                                    : " कुल कमीशन"}
                                </h6>
                                <strong
                                  style={{
                                    // backgroundColor: bgColorPlanType,
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    color: "#000", // Black text for contrast
                                  }}
                                >
                                  {loading ? (
                                    <div className="">
                                      <ThreeCircles
                                        visible={true}
                                        height={30}
                                        width={30}
                                        color="#5e72e4"
                                        ariaLabel="three-circles-loading"
                                      />
                                    </div>
                                  ) : (
                                    billsData?.affiliateTotalCommission.toFixed(
                                      2
                                    ) || ""
                                  )}
                                </strong>
                              </p>
                            </div>
                            <div className="card-billing-body-details">
                              <p>
                                <h6>
                                  {language === "hindi"
                                    ? "Paid commission"
                                    : " भुगतान की गई कमीशन"}
                                </h6>

                                <strong
                                  style={{
                                    // backgroundColor: bgColorHouseCount,
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    color: "#000", // Black text for contrast
                                  }}
                                >
                                  {loading ? (
                                    <div className="">
                                      <ThreeCircles
                                        visible={true}
                                        height={30}
                                        width={30}
                                        color="#5e72e4"
                                        ariaLabel="three-circles-loading"
                                      />
                                    </div>
                                  ) : (
                                    billsData.affiliatePaidEarnings.toFixed(
                                      2
                                    ) || ""
                                  )}
                                </strong>
                              </p>
                            </div>
                            <div className="card-billing-body-details">
                              <p>
                                <h6>
                                  {language === "hindi"
                                    ? "Pending commission"
                                    : "लंबित कमीशन"}
                                </h6>

                                <strong
                                  style={{
                                    // backgroundColor: bgColorHouseCount,
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    color: "#000", // Black text for contrast
                                  }}
                                >
                                  {loading ? (
                                    <div className="">
                                      <ThreeCircles
                                        visible={true}
                                        height={30}
                                        width={30}
                                        color="#5e72e4"
                                        ariaLabel="three-circles-loading"
                                      />
                                    </div>
                                  ) : (
                                    billsData.affiliatePendingEarnings.toFixed(
                                      2
                                    ) || ""
                                  )}
                                </strong>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="billing-no-data"></div>
                        )}
                      </div>
                    </div>

                    {/* Billing Section */}
                    <div className="section-wrapper">
                      <div className="card-billing-wrapper">
                        <div className="filtered-div">
                          <div className="p-export-btn-society export-dropdown">
                            <Tooltip placement="top" title={"Export"}>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  <TiExport className="export_icon" />
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="export-btns">
                                    <button
                                      className="view-transactions-clear-btn"
                                      onClick={() =>
                                        exportToExcel(
                                          filterData,
                                          `Affiliate Society Billing of ${billsData?.society_id?.name} `
                                        )
                                      }
                                    >
                                      Excel
                                    </button>
                                    <button
                                      className="view-transactions-clear-btn"
                                      onClick={() =>
                                        exportToCSV(
                                          filterData,
                                          `Affiliate Society Billing of ${billsData?.society_id?.name}`
                                        )
                                      }
                                    >
                                      CSV
                                    </button>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </Tooltip>
                          </div>
                          <div className="date-filter">
                            <TextField
                              id="start-date"
                              label={
                                language === "hindi"
                                  ? "Start Date"
                                  : "आरंभ करने की तिथि"
                              }
                              type="date"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                                style: { color: "#5e72e4" },
                              }}
                              InputProps={{
                                style: { color: "#5e72e4" },
                              }}
                              variant="outlined"
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: " #5e72e4",
                                    borderWidth: "1px",
                                    borderRadius: "5px",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: " #5e72e4",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#5e72e4",
                                  },
                                  "& .MuiInputLabel-root.Mui-error": {
                                    color: "#5e72e4",
                                  },
                                },
                              }}
                            />
                            <TextField
                              id="end-date"
                              label={
                                language === "hindi" ? "End Date" : "अंतिम तिथि"
                              }
                              type="date"
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                                style: { color: "#5e72e4" },
                              }}
                              InputProps={{
                                style: { color: "#5e72e4" },
                              }}
                              variant="outlined"
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#5e72e4",
                                    borderWidth: "1px",
                                    borderRadius: "5px",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: " #5e72e4",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: " #5e72e4",
                                  },
                                  "& .MuiInputLabel-root.Mui-error": {
                                    color: "#5e72e4",
                                  },
                                },
                              }}
                            />
                            <div>
                              <button
                                className="view-transactions-clear-btn"
                                onClick={() => {
                                  setStartDate("");
                                  setEndDate("");
                                  setSearchQuery("");
                                }}
                              >
                                {language === "hindi"
                                  ? "Clear Filter"
                                  : "फ़िल्टर साफ़ करें"}
                              </button>
                            </div>
                          </div>

                          <div className="">
                            <Box
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: " #5e72e4",
                                    borderWidth: "1px",
                                    borderRadius: "5px",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: " #5e72e4",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: " #5e72e4",
                                  },

                                  "& .MuiInputLabel-root.Mui-error": {
                                    color: "red",
                                  },
                                },
                              }}
                            >
                              <TextField
                                id="search-input"
                                label={
                                  language === "hindi" ? "Search..." : "खोज..."
                                }
                                variant="outlined"
                                value={searchQuery}
                                onChange={handleSearchInput}
                                className="search-input_society"
                              />
                            </Box>
                          </div>
                        </div>
                        <div className="card-billing-body">
                          <div className="billing-container">
                            <div className="parent-container">
                              {loading ? (
                                <td colSpan={15} className="no-data-billing-td">
                                  <div className="billing-loading">
                                    <ThreeCircles
                                      visible={true}
                                      height={60}
                                      width={60}
                                      color="#5e72e4"
                                      ariaLabel="three-circles-loading"
                                    />
                                  </div>
                                </td>
                              ) : (
                                <table className="billing-table">
                                  <thead>
                                    <tr>
                                      <th>Billing Cycle</th>
                                      <th>Billing Period Start Date</th>
                                      <th>Billing Period End Date</th>
                                      <th>Invoice No.</th>
                                      <th>Total Houses</th>
                                      <th>Amount</th>
                                      <th>Discount Amount</th>
                                      <th>Net Payable</th>
                                      <th>Due Date</th>
                                      <th>Affiliate Commission</th>
                                      <th>Payment Status</th>
                                      <th>Action</th>
                                      <th>Dispute Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {billsData && filterData?.length > 0 ? (
                                      filterData?.map((item, index) => {
                                        return (
                                          <>
                                            <tr
                                              className={
                                                item?.disputeId?.status ===
                                                "Pending"
                                                  ? "highlight-row-warning"
                                                  : item?.disputeId?.status ===
                                                    "Rejected"
                                                  ? "highlight-row-danger"
                                                  : item?.disputeId?.status ===
                                                    "Resolved"
                                                  ? "highlight-row-resolved "
                                                  : ""
                                              }
                                            >
                                              <td>
                                                {" "}
                                                <strong
                                                  className={
                                                    item.billingCycle ===
                                                    "monthly"
                                                      ? "interval-monthly"
                                                      : item.billingCycle ===
                                                        "annual"
                                                      ? "interval-annual"
                                                      : item.billingCycle ===
                                                        "quarterly"
                                                      ? "interval-quarterly"
                                                      : ""
                                                  }
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : (
                                                    item.billingCycle.toUpperCase() ||
                                                    "monthly"
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                <strong
                                                  style={{
                                                    // backgroundColor:
                                                    //   bgColorBillingPeriodStartDate,
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    color: "#000",
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : (
                                                    formatDateToCustom(
                                                      item.billing_period_startDate
                                                    )
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                <strong
                                                  style={{
                                                    // backgroundColor:
                                                    //   bgColorBillingPeriodStartDate,
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    color: "#000", // Black text for contrast
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : (
                                                    formatDateToCustom(
                                                      item.billing_period_endDate
                                                    )
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    // backgroundColor: bgColorInvoiceNo,
                                                    borderRadius: "5px",
                                                    padding: "5px 10px",

                                                    color: "#000", // Black text for contrast
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : item.invoiceNo ? (
                                                    item.invoiceNo
                                                  ) : (
                                                    "No Data"
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    // backgroundColor: bgColorTotalHouse,
                                                    borderRadius: "5px",
                                                    padding: "5px 10px",
                                                    color: "#000", // Black text for contrast
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : item.totalHouse ? (
                                                    item.totalHouse
                                                  ) : (
                                                    "No Data"
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <Tooltip
                                                  placement="top"
                                                  title={
                                                    <div>
                                                      Total Houses:{" "}
                                                      {item.totalHouse}
                                                      <br />
                                                      Per House Cost(
                                                      {item.currencyType}):{" "}
                                                      {item.currencyType ==
                                                      "INR"
                                                        ? "₹"
                                                        : "$"}
                                                      {item.pricePerHouse ||
                                                        "10"}
                                                      <br />
                                                      Bill Amount (
                                                      {item.currencyType}):{" "}
                                                      {item.currencyType ==
                                                      "INR"
                                                        ? "₹"
                                                        : "$"}
                                                      {item.totalHouse *
                                                        (item.pricePerHouse ||
                                                          10)}
                                                    </div>
                                                  }
                                                  arrow
                                                >
                                                  <strong
                                                    style={{
                                                      // backgroundColor: bgColorAmount,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000", // Black text for contrast
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : item.amount ? (
                                                      item.amount
                                                    ) : (
                                                      "No Data"
                                                    )}
                                                  </strong>
                                                </Tooltip>
                                              </td>

                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    // backgroundColor: bgColorDiscountAmount,
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    color: "#000",
                                                  }}
                                                >
                                                  {item.currencyType == "INR"
                                                    ? "₹"
                                                    : "$"}

                                                  {item.discountType === "fixed"
                                                    ? item.discountAmount
                                                    : item.pricePerHouse *
                                                      item.totalHouse *
                                                      (item.discountAmount /
                                                        100)}
                                                </strong>
                                              </td>
                                              <td>
                                                <Tooltip
                                                  placement="top"
                                                  title={
                                                    <div>
                                                      Total Houses ={" "}
                                                      {item.totalHouse}
                                                      <br />
                                                      Per House Cost(
                                                      {item.currencyType}) ={" "}
                                                      {item.currencyType ==
                                                      "INR"
                                                        ? "₹"
                                                        : "$"}
                                                      {item.pricePerHouse ||
                                                        "10"}
                                                      <br />
                                                      Sub Total = {item.amount}
                                                      <br />
                                                      Discount Amount ={" "}
                                                      {item.currencyType ==
                                                      "INR"
                                                        ? "₹"
                                                        : "$"}
                                                      {item.discountType ===
                                                      "fixed"
                                                        ? item.discountAmount
                                                        : item.pricePerHouse *
                                                          item.totalHouse *
                                                          (item.discountAmount /
                                                            100)}
                                                      <br />
                                                      Net Payable (
                                                      {item.currencyType}) ={" "}
                                                      {item.currencyType ==
                                                      "INR"
                                                        ? "₹"
                                                        : "$"}
                                                      {item.total}
                                                    </div>
                                                  }
                                                  arrow
                                                >
                                                  <strong
                                                    style={{
                                                      // backgroundColor: bgColorNetPayval,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : item.total ? (
                                                      item.total
                                                    ) : (
                                                      "No Data"
                                                    )}
                                                  </strong>
                                                </Tooltip>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    // backgroundColor: bgColorDiscountAmount,
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    color: "#000", // Black text for contrast
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : item.dueDate ? (
                                                    formatDate(item.dueDate)
                                                  ) : (
                                                    "No Data"
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    // backgroundColor: bgColorDiscountAmount,
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    color: "#000", // Black text for contrast
                                                  }}
                                                >
                                                  {/* <div className="affiliate-commission-edit-view">
                                            <div>
                                              {loading ? (
                                                <div className="">
                                                  <ThreeCircles
                                                    visible={true}
                                                    height={30}
                                                    width={30}
                                                    color="#5e72e4"
                                                    ariaLabel="three-circles-loading"
                                                  />
                                                </div>
                                              ) : commissionEditView[index] ? (
                                                <div>
                                                  <input
                                                    type="text"
                                                    value={
                                                      commissionValues[index] ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        index,
                                                        e.target.value
                                                      )
                                                    }
                                                    className="commission-input"
                                                  />
                                                </div>
                                              ) : (
                                                item.affiliateCommission?.toFixed(
                                                  2
                                                )||"00.00"
                                              )}
                                            </div>
                                            <div>
                                              {commissionEditCancelView[
                                                index
                                              ] ? (
                                                <div className="commission-edit-cancel-view">
                                                  <button>Saved</button>
                                                  <button
                                                    onClick={() =>
                                                      handleCancel(index, item)
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              ) : (
                                                <div
                                                  onClick={() =>
                                                    handleCommissionEdit(
                                                      index,
                                                      item
                                                    )
                                                  }
                                                >
                                                  <CiEdit className="affiliate-commssion-edit-button" />
                                                </div>
                                              )}
                                            </div>
                                          </div> */}
                                                  {item.affiliateCommission?.toFixed(
                                                    2
                                                  ) || "00.00"}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    borderRadius: "5px",
                                                    padding: "5px 10px",

                                                    color:
                                                      item.paymentStatus ===
                                                      "paid"
                                                        ? "green"
                                                        : "red",
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : item.amount ? (
                                                    item.paymentStatus.toUpperCase() ||
                                                    "unPaid"
                                                  ) : (
                                                    "No Data"
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <div className="action-download-bill">
                                                  <div>
                                                    <Tooltip
                                                      onClick={(e) => {}}
                                                      placement="top"
                                                      title={
                                                        language === "hindi"
                                                          ? "Raise Disputes"
                                                          : "विवाद उठाएं"
                                                      }
                                                      arrow
                                                    >
                                                      <span
                                                        className="action-to-updated-payment-status"
                                                        onClick={() =>
                                                          handleShow(item)
                                                        }
                                                      >
                                                        <MdReportProblem />
                                                      </span>
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                                <div className="action-download-bill"></div>
                                              </td>
                                              <td>
                                                {item?.disputeId?.status ? (
                                                  <>
                                                    {item?.disputeId?.status}
                                                    <span
                                                      className="d-flow text-sm"
                                                      style={{
                                                        display: "block",
                                                        marginTop: "5px",
                                                        color: "blue",
                                                        fontWeight: "normal",
                                                      }}
                                                    >
                                                      <span
                                                        onClick={() =>
                                                          handleShowDisputeChat(
                                                            item
                                                          )
                                                        }
                                                      >
                                                        (View Dispute)
                                                        {item?.disputeId
                                                          ?.comments &&
                                                          item?.disputeId?.comments.filter(
                                                            (comment) =>
                                                              comment.read ===
                                                              false
                                                          ).length > 0 && (
                                                            <span
                                                              style={{
                                                                color: "red",
                                                                marginLeft:
                                                                  "5px",
                                                              }}
                                                            >
                                                              {`Unread (${
                                                                item.disputeId.comments.filter(
                                                                  (comment) =>
                                                                    comment.read ===
                                                                    false
                                                                ).length
                                                              })`}
                                                            </span>
                                                          )}
                                                      </span>
                                                    </span>
                                                  </>
                                                ) : (
                                                  <div className="no-dispute">
                                                    {" "}
                                                    ---
                                                  </div>
                                                )}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })
                                    ) : (
                                      <td
                                        colSpan="9"
                                        className="no-data-row"
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                       {language === "english"
                                              ? "कोई डेटा नहीं"
                                              : "No Data"}
                                      </td>
                                    )}
                                  </tbody>{" "}
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DisputeModal
          show={show} // Pass modal visibility as prop
          btnLoading={btnLoading}
          handleClose={handleClose} // Pass the function to close the modal
          handleSaveChanges={handleSaveChanges} // Pass the function to handle saving the dispute
          language={language == "hindi" ? "english" : "hindi"} // Pass the language for translation
        />

        <ViewDisputeModal
          show={disputeChatShow} // Pass modal visibility as prop
          handleClose={handleCloseDisputeChat} // Pass the function to close the modal
          disputeId={dispute} // Pass the function to handle saving the dispute
          language={language == "hindi" ? "english" : "hindi"} // Pass the language for translation
        />
      </AffiliateLayout>
    </div>
  );
};

export default ViewAffiliateTransactions;
