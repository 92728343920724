import { MdDashboard } from "react-icons/md";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { useContext } from "react";
import { FaFileContract, FaUserPlus } from "react-icons/fa";
import { FaExchangeAlt } from "react-icons/fa";
import { MdReportProblem } from "react-icons/md";
const TopNavPathTitle = () => {
  const { language } = useContext(LanguageContext);
  const titles = {
    hindi: {
      dashboard: "Dashboard",
      affiliateClient: "Client",
      afiliateContract: "Contract",
      affiliateTransactions: "Transactions",
      affiliateDisputes: "Raise Disputes",
    },
    english: {
      dashboard: "डैशबोर्ड",
      affiliateClient: "क्लाइंट",
      afiliateContract: "",
      affiliateTransactions: "लेनदेन",
      affiliateDisputes: "विवाद उठाएं",
    },
  };

  //path
  const navPathTitles = [
    {
      path: "/affiliate/affiliateDashboard",
      title: titles[language].dashboard,
      icon: MdDashboard,
    },
    {
      path: "/affiliate/viewAffiliateClient",
      title: titles[language].affiliateClient,
      icon: FaUserPlus,
    },
    {
      path: "/affiliate/addAffiliateClient",
      title: titles[language].affiliateClient,
      icon: FaUserPlus,
    },
    {
      path: "/affiliate/viewAffiliateContracts",
      title: titles[language].afiliateContract,
      icon: FaFileContract,
    },
    {
      path: "/affiliate/viewAffiliateSocietyContracts/:id",
      title: titles[language].afiliateContract,
      icon: FaFileContract,
    },
    {
      path: "/affiliate/addAffiliateContracts/:id",
      title: titles[language].afiliateContract,
      icon: FaFileContract,
    },
    {
      path: "/affiliate/editAffiliateClient/:id",
      title: titles[language].affiliateClient,
      icon: FaFileContract,
    },
    {
      path: "/affiliate/viewAffiliateDisputes",
      title: titles[language].affiliateDisputes,
      icon: MdReportProblem,
    },
    {
      path: "/affiliate/viewAffiliateSocietyList",
      title: titles[language].affiliateTransactions,
      icon: FaExchangeAlt,
    },
    {
      path: "/affiliate/viewAffiliateTransactions/:id",
      title: titles[language].affiliateTransactions,
      icon: FaExchangeAlt,
    },
  ];
  return navPathTitles;
};
export default TopNavPathTitle;
