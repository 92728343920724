import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { PORT } from "../Api/api";
import { TextField, IconButton, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import { FaChevronDown } from "react-icons/fa";
import { LanguageContext } from "../lib/LanguageContext";
import { ThreeCircles } from "react-loader-spinner";
import { Tooltip } from "@mui/material";
import { formatDate } from "../lib/FormattedDate";
import Swal from "sweetalert2";
import { FaCheckCircle, FaTimesCircle, FaHourglassHalf } from "react-icons/fa";
import ButtonLoader from "../Loading/ButtonLoader";
import ViewDisputeModal from "../DisputeChat/ViewDisputeModal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { TiExport } from "react-icons/ti";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import Papa from "papaparse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MdReportProblem } from "react-icons/md";
import DisputeModal from "../affiliate/affiliatePanel/affiliatePages/affiliateBilling/billingTransactionsTab/viewAffiliateTransactions/DisputeModal ";

function ViewAllAffiliateBills({ selectedAgencyId, isAdmin }) {
  const { language } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const [billsData, setBillsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [commissionEditView, setCommissionEditView] = useState(false);
  const [commissionValues, setCommissionValues] = useState({}); // Tracks values for each row
  const [getId, setId] = useState();
  const [show, setShow] = useState(false);
  const [showDisputeModal, setShowDisputeModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [disputeId, setDisputeId] = useState("");
  const [disputeStatusloading, setDisputeStatusloading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [rejectionMessage, setRejectionMessage] = useState(true);

  const [societyData, setSocietyData] = useState([
    { name: "Society 1", id: "1" },
    { name: "Society 2", id: "2" },
  ]);
  const [selectedSociety, setSelectedSociety] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showAllBillsLoading, setShowAllBillsLoading] = useState(false);
  const getRoleId = JSON.parse(localStorage.getItem("roleId"));
  const [billDetails, setBillDetails] = useState([]);
  const [dispute, setDispute] = useState("");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const fetchCommissionData = async () => {
    console.log("selectedAgencyId", selectedAgencyId);
    setLoading(true);
    try {
      const response = await axios.get(
        `${PORT}/getAllCommissionByAffiliateId/${selectedAgencyId}`
      );

      const commissionData = response.data.commission;

      if (commissionData && Array.isArray(commissionData)) {
        // console.log('Commission Data:', commissionData);
        const mergedTransactions = commissionData.flatMap((society) => {
          const societyName = society.society_id?.name || "Unknown"; // Get society name or fallback to 'Unknown'
          const mainBillId = society._id;
          const uniqueBillId = society.billId;

          // Merge society name into each transaction
          return society.transactions.map((transaction) => ({
            ...transaction,
            societyName, // Add society name to the transaction
            mainBillId,
            uniqueBillId,
          }));
        });

        // Sort all transactions across all societies by `billing_period_startDate` in descending order
        const sortedTransactions = mergedTransactions.sort((a, b) => {
          const dateA = new Date(a.billing_period_startDate);
          const dateB = new Date(b.billing_period_startDate);
          return dateB - dateA; // Descending order
        });
        setBillsData(sortedTransactions || []);

        // console.log("Merged and Sorted Transactions:", sortedTransactions);
      }

      // setBillsData(response.data.commission[0]?.transactions || []);
    } catch (error) {
      console.error("Error fetching commission data:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };
  useEffect(() => {
    fetchCommissionData();
  }, []);

  useEffect(() => {
    let filteredData = billsData;

    if (selectedSociety) {
      filteredData = filteredData.filter((item) =>
        item.society_id.name
          .toLowerCase()
          .includes(selectedSociety.name.toLowerCase())
      );
    }

    if (searchQuery) {
      filteredData = filteredData.filter(
        (item) =>
          item.invoiceNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.societyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item?.disputeId?.status
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item?.paymentStatus.toLowerCase() === searchQuery.toLowerCase()
      );
    }

    if (selectedMonth) {
      filteredData = filteredData.filter(
        (item) =>
          item.billingCycle &&
          item.billingCycle.toLowerCase() === selectedMonth.toLowerCase()
      );
    }

    if (startDate || endDate) {
      const start = startDate
        ? new Date(startDate).toISOString().split("T")[0]
        : null;
      const end = endDate
        ? new Date(endDate).toISOString().split("T")[0]
        : null;

      filteredData = filteredData.filter((item) => {
        const formattedStartDate = new Date(item.billing_period_startDate)
          .toISOString()
          .split("T")[0];
        const formattedEndDate = new Date(item.billing_period_endDate)
          .toISOString()
          .split("T")[0];

        // Check conditions based on available dates
        if (start && end) {
          return formattedStartDate >= start && formattedEndDate <= end;
        } else if (start) {
          return formattedStartDate >= start;
        } else if (end) {
          return formattedEndDate <= end;
        }
        return true;
      });
    }

    setFilterData(filteredData);
  }, [
    billsData,
    selectedSociety,
    searchQuery,
    selectedMonth,
    startDate,
    endDate,
  ]);

  function formatDateToCustom(dateString) {
    const date = new Date(dateString);

    // Map of month numbers to short names
    const monthShortNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    // Extract date parts
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = monthShortNames[date.getMonth()]; // Converts month to short name
    const year = date.getFullYear(); // Gets the full year

    // Combine parts in the desired format
    return `${day}-${month}-${year}`;
  }

  const handleSocietyChange = (event, newValue) => {
    setSelectedSociety(newValue);
  };

  const handleClearInput = () => {
    setSelectedSociety(null);
  };

  const handleSearchInput = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClearAllFilter = () => {
    setSelectedSociety(null);
    setSearchQuery("");
    setSelectedMonth("");
    setStartDate("");
    setEndDate("");
    setFilterData(billsData);
  };

  //handleInputChange
  const handleInputChange = (index, value) => {
    if (/^\d*\.?\d*$/.test(value)) {
      // Allow only numbers and decimals
      setCommissionValues((prev) => ({
        ...prev,
        [index]: value,
      }));
    }
  };

  const handlePaymentStatus = async (item, paymentStatus) => {
    const data = {
      mainBillId: item.mainBillId,
      billId: item?._id,
      affiliateId: selectedAgencyId,
      affiliateCommission: item?.affiliateCommission,
      paymentStatus: paymentStatus,
    };
    console.log("data", data);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to pay this?",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    });

    if (!result.isConfirmed) return;
    try {
      const response = await axios.post(
        `${PORT}/modifyAffiliateCommissionStatus`,
        data
      );
      Swal.fire({
        title: "Updated!",
        text: `${response.data.message}`,
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
      fetchCommissionData();
    } catch (error) {
      console.log("Error fetching society data:", error);
    }
  };

  const handleShow = (item) => {
    console.log("item", item);
    // fetchCommissionData();
    if (item?.disputeId?._id) {
      setDisputeId(item?.disputeId?._id);
      setShow(true);
    }
  };

  const handleRaiseDispute = (item) => {
    if (item?.disputeId?.status == "Pending") {
      Swal.fire(
        "Warning !",
        "Dispute Already Raised & Under Process",
        "warning"
      );
    } else {
      setShowDisputeModal(true);
      setBillDetails(item);
    }
  };

  const handleDisputeStatus = async (item, disputeStatus) => {
    setSelectedItem(item._id);

    // console.log("items", item);

    const result = await Swal.fire({
      title: `Are you sure you want to ${disputeStatus} this society?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${disputeStatus} it!`,
    });

    let resolutionNotes = "";
    if (result.isConfirmed) {
      setDisputeStatusloading(true);
      let result2;

      if (disputeStatus === "Rejected") {
        const options = [
          "Invalid Dispute Reason (The provided reason is not valid)",
          "Insufficient Evidence (The provided documentation is not sufficient)",
          "Dispute Already Resolved",
          "Incorrect Billing Details (The dispute is based on incorrect information)",
          "Policy Violation (The reason does not comply with GuardX policies)",
          "Duplicate Dispute (A dispute for the same bill has already been raised)",
          "Delayed Submission (The dispute was filed after the deadline)",
          "Fraudulent Claim (The provided reason and documentation seem fraudulent)",
          "Payment Already Processed",
          "No Valid Contract (The dispute does not align with contract terms)",
          "Invalid Invoice (The invoice is not valid or was not issued)",
          "Technical Error Resolved (The error has already been fixed)",
          "Other (Provide additional details)",
        ];

        result2 = await Swal.fire({
          title: "Cause of Rejection",
          html: `
                          <div class="form-group">
                            <label for="rejectionReason" class="form-label">Select a reason:</label>
                            <select id="rejectionReason" class="form-control">
                              ${options
                                .map(
                                  (option, index) =>
                                    `<option value="${index}">${option}</option>`
                                )
                                .join("")}
                            </select>
                          </div>
                          <div class="form-group mt-3" id="customReasonGroup" style="display: none;">
                            <label for="customReason" class="form-label">Custom Reason:</label>
                            <input id="customReason" class="form-control" type="text" placeholder="Enter your reason" />
                          </div>
                        `,
          showCancelButton: true,
          confirmButtonText: "Save",
          focusConfirm: false,
          preConfirm: () => {
            const reasonSelect = document.getElementById("rejectionReason");
            const customReasonInput = document.getElementById("customReason");
            const selectedValue = reasonSelect.value;

            if (selectedValue === String(options.length - 1)) {
              // "Other" selected
              const customReason = customReasonInput.value.trim();
              if (!customReason) {
                Swal.showValidationMessage(
                  "You need to provide a cause of rejection!"
                );
                return null;
              }
              return customReason; // Return custom input value
            } else {
              return options[selectedValue]; // Return selected dropdown value
            }
          },
          didOpen: () => {
            const reasonSelect = document.getElementById("rejectionReason");
            const customReasonGroup =
              document.getElementById("customReasonGroup");

            // Show/hide custom input based on dropdown selection
            reasonSelect.addEventListener("change", () => {
              if (reasonSelect.value === String(options.length - 1)) {
                // "Other" selected
                customReasonGroup.style.display = "block";
              } else {
                customReasonGroup.style.display = "none";
                document.getElementById("customReason").value = ""; // Clear custom input
              }
            });
          },
        });

        if (result2.isConfirmed) {
          setRejectionMessage(result2.value);
          resolutionNotes = result2.value; // Set the selected or custom rejection message
        } else {
          setDisputeStatusloading(false);
        }
      }
      if (result2 && !result2.isConfirmed) {
        setDisputeStatusloading(false);
        return;
      }
      // console.log("sdsdsd", result2);
      if (disputeStatus === "Resolved") {
        const approvalInput = await Swal.fire({
          title: "Enter Resolution Remark",
          input: "textarea",
          inputLabel: "Provide a detailed remark for resolving this dispute:",
          inputPlaceholder: "Enter your remarks here...",
          showCancelButton: true,
          confirmButtonText: "Save",
          inputValidator: (value) => {
            if (!value || value.trim() === "") {
              return "You need to provide a resolution remark!";
            }
          },
        });

        if (approvalInput.isConfirmed) {
          resolutionNotes = approvalInput.value;
        } else {
          setDisputeStatusloading(false);
          return;
        }
      }

      const payload = {
        disputeReviewBy: getRoleId,
        societyId: item?.society_id,
        affiliateId: selectedAgencyId,
        billId: item?.invoiceNo,
        disputeID: item?.disputeId?._id,
        status: disputeStatus,
        resolutionNotes: resolutionNotes || "Na",
        month: item?.billing_period_startDate,
      };
      console.log("payload", payload);
      try {
        const response = await axios.patch(`${PORT}updateDispute`, payload);
        console.log(response);
        Swal.fire(
          `Successfully updated`,
          `Dispute ${disputeStatus} !`,
          `success`
        );
        fetchCommissionData();
      } catch (error) {
        Swal.fire(`Error`, `${error.response.error}`, `error`);
        console.log(error);
      } finally {
        setDisputeStatusloading(false);
      }
    }
  };

  const handleClose = () => {
    setDisputeId("");
    setShow(false);
    fetchCommissionData();
  };
  const handleCloseDisputeModal = () => {
    setShowDisputeModal(false);
    setDispute("");
  };

  const exportToExcel = (filterData, filename) => {
    if (!filterData || filterData.length === 0) {
      Swal.fire(`Error`, `No data to export!`, `error`);
      return;
    }
    const ws = XLSX.utils.json_to_sheet(
      filterData.map((item) => ({
        BillingCycle: item.billingCycle ? item.billingCycle : "-",
        BillStartDate: item.billing_period_startDate
          ? new Date(item.billing_period_startDate).toLocaleDateString("en-GB")
          : "-",
        BillEndDate: item.billing_period_endDate
          ? new Date(item.billing_period_endDate).toLocaleDateString("en-GB")
          : "-",
        InvoiceNo: item.invoiceNo ? item.invoiceNo : "-",
        TotalHouses: item.totalHouse ? item.totalHouse : "-",
        SocietyRegistrationNo: item.societyRegistration
          ? item.societyRegistration
          : "-",
        PaymentStatus: item.paymentStatus ? item.paymentStatus : "-",
        DueDate: item.dueDate ? item.dueDate : "-",
        TotalAmount: item.total ? item.total : "-",
        DiscountAmount: item.discountAmount ? item.discountAmount : "0",
        SubmittedDate: item.submitedDate ? item.submitedDate : "-",
        SubmittedTime: item.submitedTime ? item.submitedTime : "-",
        PricePerHouse: item.pricePerHouse ? item.pricePerHouse : "-",
        AffiliateCommission: item.affiliateCommission
          ? item.affiliateCommission
          : "-",
        CurrencyType: item.currencyType ? item.currencyType : "-",
        Dispute: item.disputeId ? item.disputeId.disputeReason : "-",
        DisputeStatus: item.disputeId ? item.disputeId.status : "-",
      }))
    );

    // Set column widths
    const wscols = new Array(17).fill({ wch: 30 }); // All columns set to 30 width
    ws["!cols"] = wscols;

    // Highlight rows with specific conditions
    const lightOrange = { fill: { fgColor: { rgb: "FFECB3" } } }; // Light orange color
    Object.keys(ws).forEach((cell) => {
      if (cell[0] !== "!") {
        const rowNumber = parseInt(cell.match(/\d+/)[0], 10) - 2; // Adjust for header row
        if (
          filterData[rowNumber] &&
          (filterData[rowNumber].paymentStatus === "unpaid" ||
            (filterData[rowNumber].disputeId &&
              filterData[rowNumber].disputeId.status === "pending"))
        ) {
          ws[cell].s = lightOrange;
        }
      }
    });

    // Create workbook and append sheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Write workbook and download
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const currentTimeAndDate = new Date()
      .toLocaleString()
      .replace(/[/, :]/g, "_");
    FileSaver.saveAs(blob, `${filename}_${currentTimeAndDate}.xlsx`);
  };

  const exportToCSV = (filterData, filename) => {
    if (!filterData || filterData.length === 0) {
      Swal.fire(`Error`, `No data to export!`, `error`);
      return;
    }

    // Map data into the desired CSV structure
    const csvData = filterData.map((item) => ({
      BillingCycle: item.billingCycle || "Not Added",
      BillStartDate: item.billing_period_startDate
        ? new Date(item.billing_period_startDate).toLocaleDateString("en-GB")
        : "Not Added",
      BillEndDate: item.billing_period_endDate
        ? new Date(item.billing_period_endDate).toLocaleDateString("en-GB")
        : "Not Added",
      InvoiceNo: item.invoiceNo || "Not Added",
      TotalHouses: item.totalHouse || "Not Added",
      SocietyRegistrationNo: item.societyRegistration || "Not Added",
      PaymentStatus: item.paymentStatus || "Not Added",
      DueDate: item.dueDate || "Not Added",
      TotalAmount: item.total.replace(/[^\d.]/g, "") || "Not Added",
      DiscountAmount: item.discountAmount || "0",
      SubmittedDate: item.submitedDate || "Not Added",
      SubmittedTime: item.submitedTime || "Not Added",
      PricePerHouse: item.pricePerHouse || "Not Added",
      AffiliateCommission: item.affiliateCommission || "Not Added",
      CurrencyType: item.currencyType || "Not Added",
      Dispute: item.disputeId?.disputeReason || "Not Added",
      DisputeStatus: item.disputeId?.status || "Not Added",
    }));

    // Convert JSON data to CSV
    const csv = Papa.unparse(csvData);

    // Create a Blob and save as a CSV file
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const currentTimeAndDate = new Date()
      .toLocaleString()
      .replace(/[/, :]/g, "_");
    FileSaver.saveAs(blob, `${filename}_${currentTimeAndDate}.csv`);
  };

  const handleSaveChanges = async (formData) => {
    console.log("DSDSD", formData, billDetails);

    // try {
    //   setBtnLoading(true);
    //   const data = {
    //     mainBillId: billsData?.billId,
    //     affiliateId: billsData?.affiliateId?._id,
    //     societyId: billsData?.society_id?._id,
    //     billId: billDetails?.invoiceNo,
    //     disputeReason: formData.selectedReason === "other"
    //     ? formData.manualComment
    //     : formData.selectedReason,
    //     disputeDescription: formData.disputeDescription,
    //   };
    //   if (formData.screenshot) {
    //     data.screenshot=formData.screenshot;
    //   }
    //   // console.log('sss',data);
    //   const response = await axios.post(`${PORT}/raisedDispute`, data);
    //   // console.log(response);
    try {
      setBtnLoading(true);

      // Create FormData object
      const formDataPayload = new FormData();
      formDataPayload.append("mainBillId", billDetails?.uniqueBillId);
      formDataPayload.append("affiliateId", selectedAgencyId);
      formDataPayload.append("societyId", billDetails?.society_id);
      formDataPayload.append("billId", billDetails?.invoiceNo);
      formDataPayload.append(
        "disputeReason",
        formData.selectedReason === "other"
          ? formData.manualComment
          : formData.selectedReason
      );
      formDataPayload.append("disputeDescription", formData.disputeDescription);

      // Check if the screenshot file exists
      if (formData.screenshot) {
        console.log("inside", formData.screenshot);

        formDataPayload.append("screenshot", formData.screenshot);
      }

      console.log(formDataPayload);

      // Send the request
      const response = await axios.post(
        `${PORT}/raisedDispute`,
        formDataPayload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      handleClose();
      Swal.fire({
        position: "center", // Position at the top right
        icon: "success", // Icon type, can be success, error, etc.
        title: "Success!", // Title of the alert
        text: "Dispute Raised Successfully", // Your custom message
        showConfirmButton: false, // Hides the confirm button
        timer: 1500, // Auto-closes after 1.5 seconds
      });
      setBtnLoading(false);
      fetchCommissionData();
    } catch (error) {
      Swal.fire("Error", `An Error Occured while Raising Disute!`, "error");
      console.log(error);
      setBtnLoading(false);
    } finally {
      setDispute("");
    }
  };

  return (
    <div className="billing-container">
      <div
        className="top-heading-billing-dropdown-allFilter"
        style={{ position: "contents", zIndex: 22000 }}
      >
        {/* all filter */}
        {/* <div className="top-heading-all-filter-button">
          <button onClick={() => {}}>
            {language === "english" ? "सभी" : "All"}
          </button>
        </div> */}

        {/* export */}
        <div className="p-export-btn-society export-dropdown">
          <Tooltip placement="top" title={"Export"}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <TiExport className="export_icon" />
              </AccordionSummary>
              <AccordionDetails>
                <div className="export-btns" style={{ zIndex: 22000 }}>
                  <button
                    // className="p-export-btn-society"
                    onClick={() =>
                      exportToExcel(
                        filterData,
                        `Affiliate_All_Society_Billings `
                      )
                    }
                  >
                    Excel
                  </button>
                  <button
                    // className="p-export-btn-society "
                    onClick={() =>
                      exportToCSV(filterData, `Affiliate_All_Society_Billings`)
                    }
                  >
                    CSV
                  </button>
                </div>
              </AccordionDetails>
            </Accordion>
          </Tooltip>
        </div>

        {/* month filter */}
        <div></div>

        {/* date range filter */}
        <div>
          <div>
            <TextField
              className="m-1"
              id="start-date"
              label={language === "hindi" ? "Start Date" : "आरंभ करने की तिथि"}
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
                style: { color: "#5e72e4" }, // Label color
              }}
              InputProps={{
                style: { color: "#5e72e4" }, // Text color inside input
              }}
              variant="outlined"
            />
            <TextField
              className="m-1"
              id="end-date"
              label={language === "hindi" ? "End Date" : "अंतिम तिथि"}
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
                style: { color: "#5e72e4" }, // Label color
              }}
              InputProps={{
                style: { color: "#5e72e4" }, // Text color inside input
              }}
              variant="outlined"
            />
          </div>
        </div>
        {/* clear filter */}
        <div className="top-heading-clear-all-filter-button">
          <button onClick={handleClearAllFilter}>
            {language === "english" ? "फिल्टर हटाये" : "Clear Filter"}
          </button>
        </div>

        {/* search filter */}
        <div>
          <Box
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
          >
            <TextField
              id="search-input"
              label={language === "hindi" ? "Search..." : "खोज..."}
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchInput}
              className="search-input"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    // borderColor: " #5e72e4",
                    borderWidth: "1px",
                    borderRadius: "5px",
                  },
                  "&:hover fieldset": {
                    // borderColor: " #5e72e4",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: " #5e72e4",
                  },

                  "& .MuiInputLabel-root.Mui-error": {
                    color: "red",
                  },
                },
              }}
            />
          </Box>
        </div>
      </div>

      {/* Table */}
      <div className="table-container">
        <div className="parent-container">
          {loading ? (
            <div className="billing-loading">
              <ThreeCircles
                visible={true}
                height={60}
                width={60}
                color="#5e72e4"
                ariaLabel="three-circles-loading"
              />
            </div>
          ) : (
            <table className="billing-table">
              <thead>
                <tr>
                  <th>
                    {language === "english" ? "बिलिंग चक्र" : "Billing Cycle"}
                  </th>
                  <th>
                    {language === "english"
                      ? "बिलिंग अवधि प्रारंभ तिथि "
                      : "Society Name"}
                  </th>
                  <th>
                    {language === "english" ? "प्रारंभ तिथि" : "Start Date"}
                  </th>
                  <th>
                    {language === "english" ? "समाप्ति तिथि" : "End Date"}
                  </th>
                  <th>
                    {language === "english" ? "चालान संख्या" : "Invoice No"}
                  </th>
                  <th>{language === "english" ? "कुल घर" : "Total Houses"}</th>
                  <th>{language === "english" ? "राशि" : "Amount"}</th>
                  <th>{language === "english" ? "छूट" : "Discount"}</th>
                  <th>{language === "english" ? "कुल देय" : "Net Payable"}</th>
                  <th>{language === "english" ? "नियत तिथि" : "Due Date"}</th>
                  <th>
                    {language === "english"
                      ? "संपर्क कमीशन"
                      : "Affiliate Commission"}
                  </th>
                  <th>
                    {language === "english" ? "स्थिति" : "Payment Status"}
                  </th>
                  {isAdmin ? (
                    <th>{language === "english" ? "कार्य" : "Actions"}</th>
                  ) : (
                    <th>
                      {language === "english" ? "कार्य" : "Raise Dispute"}
                    </th>
                  )}
                  <th>
                    {language === "english"
                      ? "विवाद स्थिति "
                      : "Dispute Status"}
                  </th>
                  {isAdmin && (
                    <th>
                      {" "}
                      {language === "english"
                        ? "विवाद कार्यवाही "
                        : "Dispute Actions"}{" "}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {filterData && filterData.length > 0 ? (
                  filterData.map((item, index) => (
                    <tr
                      className={
                        item?.disputeId?.status === "Pending"
                          ? "highlight-row-warning"
                          : item?.disputeId?.status === "Rejected"
                          ? "highlight-row-danger"
                          : item?.disputeId?.status === "Resolved"
                          ? "highlight-row-resolved "
                          : ""
                      }
                    >
                      <td>
                        {" "}
                        <strong
                          className={
                            item.billingCycle === "monthly"
                              ? "interval-monthly"
                              : item.billingCycle === "annual"
                              ? "interval-annual"
                              : item.billingCycle === "quarterly"
                              ? "interval-quarterly"
                              : ""
                          }
                        >
                          {loading ? (
                            <div className="">
                              <ThreeCircles
                                visible={true}
                                height={30}
                                width={30}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          ) : (
                            item?.billingCycle?.toUpperCase() || "monthly"
                          )}
                        </strong>
                      </td>
                      <td className="text-capitalize">
                        <strong
                          style={{
                            // backgroundColor:
                            //   bgColorBillingPeriodStartDate,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#000",
                          }}
                        >
                          {item?.societyName}
                        </strong>
                      </td>
                      <td>
                        <strong
                          style={{
                            // backgroundColor:
                            //   bgColorBillingPeriodStartDate,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#000",
                          }}
                        >
                          {loading ? (
                            <div className="">
                              <ThreeCircles
                                visible={true}
                                height={30}
                                width={30}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          ) : (
                            formatDateToCustom(item.billing_period_startDate)
                          )}
                        </strong>
                      </td>
                      <td>
                        <strong
                          style={{
                            // backgroundColor:
                            //   bgColorBillingPeriodStartDate,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#000", // Black text for contrast
                          }}
                        >
                          {loading ? (
                            <div className="">
                              <ThreeCircles
                                visible={true}
                                height={30}
                                width={30}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          ) : (
                            formatDateToCustom(item.billing_period_endDate)
                          )}
                        </strong>
                      </td>
                      <td>
                        {" "}
                        <strong
                          style={{
                            // backgroundColor: bgColorInvoiceNo,
                            borderRadius: "5px",
                            padding: "5px 10px",

                            color: "#000", // Black text for contrast
                          }}
                        >
                          {loading ? (
                            <div className="">
                              <ThreeCircles
                                visible={true}
                                height={30}
                                width={30}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          ) : item.invoiceNo ? (
                            item.invoiceNo
                          ) : (
                            "No Data"
                          )}
                        </strong>
                      </td>
                      <td>
                        {" "}
                        <strong
                          style={{
                            // backgroundColor: bgColorTotalHouse,
                            borderRadius: "5px",
                            padding: "5px 10px",
                            color: "#000", // Black text for contrast
                          }}
                        >
                          {loading ? (
                            <div className="">
                              <ThreeCircles
                                visible={true}
                                height={30}
                                width={30}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          ) : item.totalHouse ? (
                            item.totalHouse
                          ) : (
                            "No Data"
                          )}
                        </strong>
                      </td>
                      <td>
                        {" "}
                        <Tooltip
                          placement="top"
                          title={
                            <div>
                              Total Houses: {item.totalHouse}
                              <br />
                              Per House Cost(
                              {item.currencyType}):{" "}
                              {item.currencyType == "INR" ? "₹" : "$"}
                              {item.pricePerHouse || "10"}
                              <br />
                              Bill Amount ({item.currencyType}):{" "}
                              {item.currencyType == "INR" ? "₹" : "$"}
                              {item.totalHouse * (item.pricePerHouse || 10)}
                            </div>
                          }
                          arrow
                        >
                          <strong
                            style={{
                              // backgroundColor: bgColorAmount,
                              padding: "5px 10px",
                              borderRadius: "5px",
                              color: "#000", // Black text for contrast
                            }}
                          >
                            {loading ? (
                              <div className="">
                                <ThreeCircles
                                  visible={true}
                                  height={30}
                                  width={30}
                                  color="#5e72e4"
                                  ariaLabel="three-circles-loading"
                                />
                              </div>
                            ) : item.amount ? (
                              item.amount
                            ) : (
                              "No Data"
                            )}
                          </strong>
                        </Tooltip>
                      </td>

                      <td>
                        {" "}
                        <strong
                          style={{
                            // backgroundColor: bgColorDiscountAmount,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#000",
                          }}
                        >
                          {item.currencyType == "INR" ? "₹" : "$"}

                          {item.discountType === "fixed"
                            ? item.discountAmount
                            : item.pricePerHouse *
                              item.totalHouse *
                              (item.discountAmount / 100)}
                        </strong>
                      </td>
                      <td>
                        <Tooltip
                          placement="top"
                          title={
                            <div>
                              Total Houses = {item.totalHouse}
                              <br />
                              Per House Cost(
                              {item.currencyType}) ={" "}
                              {item.currencyType == "INR" ? "₹" : "$"}
                              {item.pricePerHouse || "10"}
                              <br />
                              Sub Total = {item.amount}
                              <br />
                              Discount Amount ={" "}
                              {item.currencyType == "INR" ? "₹" : "$"}
                              {item.discountType === "fixed"
                                ? item.discountAmount
                                : item.pricePerHouse *
                                  item.totalHouse *
                                  (item.discountAmount / 100)}
                              <br />
                              Net Payable ({item.currencyType}) ={" "}
                              {item.currencyType == "INR" ? "₹" : "$"}
                              {item.total}
                            </div>
                          }
                          arrow
                        >
                          <strong
                            style={{
                              // backgroundColor: bgColorNetPayval,
                              padding: "5px 10px",
                              borderRadius: "5px",
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            {loading ? (
                              <div className="">
                                <ThreeCircles
                                  visible={true}
                                  height={30}
                                  width={30}
                                  color="#5e72e4"
                                  ariaLabel="three-circles-loading"
                                />
                              </div>
                            ) : item.total ? (
                              item.total
                            ) : (
                              "No Data"
                            )}
                          </strong>
                        </Tooltip>
                      </td>
                      <td>
                        {" "}
                        <strong
                          style={{
                            // backgroundColor: bgColorDiscountAmount,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#000", // Black text for contrast
                          }}
                        >
                          {loading ? (
                            <div className="">
                              <ThreeCircles
                                visible={true}
                                height={30}
                                width={30}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          ) : item.dueDate ? (
                            formatDate(item.dueDate)
                          ) : (
                            "No Data"
                          )}
                        </strong>
                      </td>
                      <td>
                        {" "}
                        <strong
                          style={{
                            // backgroundColor: bgColorDiscountAmount,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#000", // Black text for contrast
                          }}
                        >
                          <div className="affiliate-commission-edit-view">
                            <div>
                              {loading ? (
                                <div className="">
                                  <ThreeCircles
                                    visible={true}
                                    height={30}
                                    width={30}
                                    color="#5e72e4"
                                    ariaLabel="three-circles-loading"
                                  />
                                </div>
                              ) : commissionEditView[index] ? (
                                <div>
                                  <input
                                    type="text"
                                    value={commissionValues[index] || ""}
                                    onChange={(e) =>
                                      handleInputChange(index, e.target.value)
                                    }
                                    className="commission-input"
                                  />
                                </div>
                              ) : (
                                item.affiliateCommission?.toFixed(2)
                              )}
                            </div>
                          </div>
                        </strong>
                      </td>
                      <td>
                        {" "}
                        <strong
                          style={{
                            borderRadius: "5px",
                            padding: "5px 10px",

                            color:
                              item.paymentStatus === "paid" ? "green" : "red",
                          }}
                        >
                          {loading ? (
                            <div className="billing-loading">
                              <ThreeCircles
                                visible={true}
                                height={30}
                                width={30}
                                color="#5e72e4"
                                ariaLabel="three-circles-loading"
                              />
                            </div>
                          ) : item.amount ? (
                            item.paymentStatus.toUpperCase() || "unPaid"
                          ) : (
                            "No Data"
                          )}
                        </strong>
                      </td>
                      {isAdmin ? (
                        <td>
                          {" "}
                          <div className="action-download-bill">
                            <div>
                              <Tooltip
                                disabled={
                                  item.paymentStatus === "paid"
                                    ? "disabledPaymentButton"
                                    : ""
                                }
                                onClick={(e) => {
                                  if (item.paymentStatus !== "paid") {
                                    handlePaymentStatus(item, "paid");
                                  }
                                }}
                                placement="top"
                                title={
                                  item.paymentStatus === "paid"
                                    ? language === "hindi"
                                      ? "Paid"
                                      : "भुगतान किया"
                                    : language === "hindi"
                                    ? "Update Payment Status"
                                    : "भुगतान स्थिति अपडेट करें"
                                }
                                arrow
                              >
                                <span
                                  className={`${
                                    item.paymentStatus === "paid"
                                      ? "disabledPaymentButton"
                                      : "action-to-updated-payment-status"
                                  }`}
                                >
                                  <FaCheckCircle />
                                </span>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip
                                disabled={
                                  item.paymentStatus === "paid"
                                    ? "disabledPaymentButton"
                                    : ""
                                }
                                onClick={(e) => {
                                  if (item.paymentStatus == "paid") {
                                    handlePaymentStatus(item, "unpaid");
                                  }
                                }}
                                placement="top"
                                title={
                                  item.paymentStatus === "unpaid"
                                    ? language === "hindi"
                                      ? "Unpaid"
                                      : "बिना भुगतान किया गया बिल"
                                    : language === "hindi"
                                    ? "Update Payment Status"
                                    : "भुगतान स्थिति अपडेट करें"
                                }
                                arrow
                              >
                                <span
                                  className={`${
                                    item.paymentStatus === "unpaid"
                                      ? "disabledPaymentButton"
                                      : "action-to-updated-payment-status"
                                  }`}
                                >
                                  <FaTimesCircle />
                                </span>
                              </Tooltip>
                            </div>
                            {/* <div>
                <ModalViewBillingDetails
                  data={
                    item && item?.bills
                      ? item?.bills
                      : ""
                  }
                />
              </div> */}
                          </div>
                          {/* <div className="action-download-bill"></div> */}
                        </td>
                      ) : (
                        <td>
                          {" "}
                          <div className="action-download-bill">
                            <div>
                              <Tooltip
                                onClick={(e) => {}}
                                placement="top"
                                title={
                                  language === "hindi"
                                    ? "Raise Disputes"
                                    : "विवाद उठाएं"
                                }
                                arrow
                              >
                                <span
                                  className="action-to-updated-payment-status"
                                  onClick={() => handleRaiseDispute(item)}
                                >
                                  <MdReportProblem />
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="action-download-bill"></div>
                        </td>
                      )}
                      <td>
                        {item?.disputeId?.status ? (
                          <>
                            {item?.disputeId?.status}
                            <span
                              className="d-flow text-sm"
                              style={{
                                display: "block",
                                marginTop: "5px",
                                color: "blue",
                                fontWeight: "normal",
                              }}
                            >
                              <span onClick={() => handleShow(item)}>
                                (View Dispute)
                                {item?.disputeId?.comments &&
                                  item?.disputeId?.comments.filter(
                                    (comment) => comment.read === false
                                  ).length > 0 && (
                                    <span
                                      style={{
                                        color: "red",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {`Unread (${
                                        item.disputeId.comments.filter(
                                          (comment) => comment.read === false
                                        ).length
                                      })`}
                                    </span>
                                  )}
                              </span>
                            </span>
                          </>
                        ) : (
                          <div className="no-dispute">---</div>
                        )}
                      </td>

                      {isAdmin && (
                        <td>
                          {item?.disputeId?.status ? (
                            disputeStatusloading && selectedItem == item._id ? (
                              <ButtonLoader
                                height={30}
                                width={30}
                                color={"blue"}
                              />
                            ) : (
                              <div className="action-download-bill">
                                {/* Resolved Button */}
                                <div>
                                  <Tooltip
                                    onClick={() => {
                                      if (
                                        item?.disputeId?.status !== "Resolved"
                                      ) {
                                        handleDisputeStatus(item, "Resolved");
                                      }
                                    }}
                                    placement="top"
                                    title={
                                      item?.disputeId?.status === "Resolved"
                                        ? language === "english"
                                          ? "सुलझा हुआ विवाद"
                                          : "Dispute Resolved"
                                        : language === "english"
                                        ? "सुलझा"
                                        : "Resolve"
                                    }
                                    arrow
                                  >
                                    <span
                                      className={`${
                                        item?.disputeId?.status === "Resolved"
                                          ? "disabledPaymentButton"
                                          : "action-to-updated-payment-status"
                                      }`}
                                      style={{
                                        cursor:
                                          item?.disputeId?.status === "Resolved"
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <FaCheckCircle />
                                      {/* {language === "english" ? "सुलझा" : "Resolved"} */}
                                    </span>
                                  </Tooltip>
                                </div>

                                {/* Rejected Button */}
                                <div>
                                  <Tooltip
                                    onClick={() => {
                                      if (
                                        item?.disputeId?.status !== "Rejected"
                                      ) {
                                        handleDisputeStatus(item, "Rejected");
                                      }
                                    }}
                                    placement="top"
                                    title={
                                      item?.disputeId?.status === "Rejected"
                                        ? language === "english"
                                          ? "अस्वीकृत विवाद"
                                          : "Dispute Rejected"
                                        : language === "english"
                                        ? "अस्वीकृत"
                                        : "Reject"
                                    }
                                    arrow
                                  >
                                    <span
                                      className={`${
                                        item?.disputeId?.status === "Rejected"
                                          ? "disabledPaymentButton"
                                          : "action-to-updated-payment-status"
                                      }`}
                                      style={{
                                        cursor:
                                          item?.disputeId?.status === "Rejected"
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <FaTimesCircle />
                                      {/* {language === "english" ? "अस्वीकृत" : "Rejected"} */}
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            )
                          ) : (
                            <div className="no-dispute">---</div>
                          )}
                          {/* </div> */}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="9"
                      className="no-data-row"
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      No data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <ViewDisputeModal
        show={show} // Pass modal visibility as prop
        handleClose={handleClose} // Pass the function to close the modal
        disputeId={disputeId} // Pass the function to handle saving the dispute
        language={language == "hindi" ? "english" : "hindi"} // Pass the language for translation
      />
      <DisputeModal
        show={showDisputeModal} // Pass modal visibility as prop
        btnLoading={btnLoading}
        handleClose={handleCloseDisputeModal} // Pass the function to close the modal
        handleSaveChanges={handleSaveChanges} // Pass the function to handle saving the dispute
        language={language == "hindi" ? "english" : "hindi"} // Pass the language for translation
      />
    </div>
  );
}

export default ViewAllAffiliateBills;
