// import React, { useContext, useState } from "react";
// import GuardLayout from "../lib/GuardLayout";
// import { LanguageContext } from "../lib/LanguageContext";
// import Navbar from "../Navbar/Navbar";
// import { useNavigate } from "react-router-dom";
// import useCountdown from "../lib/useCountdown";
// import axios from "axios";
// import Swal from "sweetalert2";
// import { PORT } from "../Api/api";
// import { Hourglass } from "react-loader-spinner";

// function SendOtpMail() {
//   const { language } = useContext(LanguageContext);
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);

//   const [userData, setUserData] = useState({
//     email: "",
//     OTP: "",
//     currentPassword: "",
//     newPassword: "",
//     confirmPassword: "",
//   });
//   const [emailFlag, setEmailFlag] = useState(false);
//   const [serverOTP, setServerOTP] = useState(false);
//   const [user, setuser] = useState(null);
//   const { start, secondsLeft, minsLeft, ms } = useCountdown(null);
//   const [timeup, setTimeup] = useState(false);
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     const copyLogiInfo = { ...userData };
//     copyLogiInfo[name] = value;
//     setUserData(copyLogiInfo);
//   };
//   //otp session expired
//   if (secondsLeft == 1) {
//     Swal.fire({
//       position: "center",
//       icon: "error",
//       title: language === "english" ? "समय समाप्त !" : "Time Up !",
//       showConfirmButton: false,
//       timer: 1500,
//     });
//     setTimeout(() => {
//       setTimeup(true);
//       // navigate(-1)
//       // setServerOTP(null)
//     }, 1500);
//   }

//   //generate Otp
//   const sendOTP = async () => {
//     setTimeup(false);
//     setLoading(true);
//     // console.log(emailFlag);
//     try {
//       const url = `${PORT}sendOtpEmail`;
//       const response = await axios.post(url, {
//         query: userData.email,
//         affiliateUser: true,
//       });
//       setLoading(false);
//       // console.log(response);
//       setuser(response.data.payload);
//       if (response.status === 200) {
//         setEmailFlag(true);
//         // handleSuccess(response.data.message);
//         Swal.fire({
//           position: "center",
//           icon: "success",
//           title:
//             language === "english"
//               ? "ओटीपी ईमेल सफलतापूर्वक भेज दिया गया है. "
//               : "OTP email has been sent successfully. ",
//           timer: 1500,
//         });
//         start(120);
//       }
//     } catch (error) {
//       setLoading(false);
//       let errorMsg=
//       language === "english"
//         ? "कृपया थोड़े समय के बाद बाद में प्रयास करें ईमेल भेजते समय आंतरिक सर्वर त्रुटि"
//         : `Please Try Later Internal Server Error While Sending Email`;
//       // console.log("set otp error", error.response);
      
//       if(error.response.status===500){
//         Swal.fire("Error!",errorMsg,'error');
//       }else{
//         Swal.fire({
//           position: "center",
//           icon: "error",
//           title: error.response.data ,
//           showConfirmButton: false,
//           timer: 1500,
//         });
//       }
      
   
//       // handleError(error.response.data.message);
//       setEmailFlag(false);
//     }
//   };

//   //chk otp
//   const checkOTP = async (otp) => {
//     console.log(userData.OTP, user);
//     setLoading(true);
//     try {
//       const url = `${PORT}MatchEmailOtp`;
//       const payload = {
//         otp: userData.OTP,
//       };
//       if (user.affiliateUser) {
//         payload.affiliateUser = user.affiliateUser;
//       }
//       if (user.societyUser) {
//         payload.societyUser = user.societyUser;
//       }
//       // console.log("otp pa", payload);

//       const response = await axios.post(url, payload);
//       setLoading(false);
//       if (response?.status === 200) {
//         Swal.fire({
//           position: "center",
//           icon: "success",
//           title:
//             language === "english" ? "ओटीपी मिलान सत्यापित" : "OTP verified",
//           showConfirmButton: false,
//           timer: 1500,
//         });
//         navigate("/changePassword", { state: payload });
//       }
//     } catch (error) {
//       setLoading(false);
//       if (error?.response?.status === 400) {
//         Swal.fire({
//           position: "center",
//           icon: "error",
//           title: language === "english" ? "अमान्य ओटीपी" : "Invalid OTP",
//           showConfirmButton: false,
//           timer: 1500,
//         });
//       }
//       console.log(error);
//     }
//   };

//   return (
//     <div className="background_image">
//       <GuardLayout>
//         <Navbar />

//         <div className="main-form-affiliate">
//           <div
//             className="form-container-affiliate-login"
//             style={{ height: "auto", minHeight: "200px" }}
//           >
//             <h2 className="affiliate-login_title">
//               {language === "english" ? "पासवर्ड रीसेट" : "Reset Password"}
//             </h2>
//             {/* Email Input Section */}

//             {!emailFlag && (
//               <div className="form-group">
//                 <input
//                   type="email"
//                   className="form-control mt-2"
//                   placeholder={
//                     language === "english"
//                       ? "ईमेल पता दर्ज करें"
//                       : "Enter email"
//                   }
//                   name="email"
//                   onChange={handleChange}
//                   required
//                   disabled={emailFlag}
//                 />
//                 <button
//                   className="forgot-submit-button"
//                   onClick={sendOTP}
//                   disabled={emailFlag}
//                 >
//                   {loading && (
//                     <>
//                       <Hourglass
//                         visible={true}
//                         height="20"
//                         width="20"
//                         ariaLabel="hourglass-loading"
//                         wrapperStyle={{}}
//                         wrapperClass=""
//                         colors={["#ffffff"]}
//                         className="mt-1"
//                       />
//                     </>
//                   )}
//                   {language === "english" ? "  ओटीपी भेजें" : " Send OTP"}
//                 </button>
//               </div>
//             )}

//             {/* Countdown Timer */}

//             {/* OTP Input Section */}

//             {emailFlag && (
//               <div className="form-group">
//                 {/* <h5 className='text-center text-light'>Enter verification code </h5> */}
//                 <p className="text-sm text-center">
//                   {language === "english"
//                     ? "हमने कोड भेज दिया है "
//                     : "We've sent code to "}
//                   <strong>{userData.email}</strong>
//                 </p>
//                 <input
//                   type="number"
//                   className="form-control mt-2"
//                   placeholder={
//                     language === "english" ? "ओटीपी दर्ज करें" : "Enter OTP"
//                   }
//                   name="OTP"
//                   onChange={handleChange}
//                   disabled={!emailFlag}
//                 />
//                 <button
//                   className="forgot-submit-button"
//                   onClick={checkOTP}
//                   disabled={!emailFlag}
//                 >
//                   {" "}
//                   {loading && (
//                     <>
//                       <Hourglass
//                         visible={true}
//                         height="20"
//                         width="20"
//                         ariaLabel="hourglass-loading"
//                         wrapperStyle={{}}
//                         wrapperClass=""
//                         colors={["#ffffff"]}
//                         className="mt-1"
//                       />
//                     </>
//                   )}
//                   {language === "english" ? " ओटीपी सबमिट करें" : " Submit OTP"}
//                 </button>
                // <div className="d-flex justify-content-end " style={{marginBottom:'-10px'}}>
                //   {timeup && (
                //     <span onClick={sendOTP} style={{ cursor: "pointer" }}>
                //       {language === "english"
                //         ? `कोड नहीं मिला ? `
                //         : `Didn't get a Code ? `}
                //       <strong>
                //         {language === "english"
                //           ? `पुनः भेजें.`
                //           : `Click to resend.`}
                //       </strong>
                //     </span>
                //   )}
                //   {secondsLeft !== null && secondsLeft > 0 && !timeup && (
                //     <p className="text-light mt-2 text-center">
                //       <strong>
                //         {language === "english"
                //           ? `शेष समय : `
                //           : `Time Remaining : `}
                //       </strong>{" "}
                //       0{minsLeft}:{ms < 10 ? `0${ms}` : ms}
                //     </p>
                //   )}
                // </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </GuardLayout>
//     </div>
//   );
// }

// export default SendOtpMail;
import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import GuardLayout from "../lib/GuardLayout";
import { LanguageContext } from "../lib/LanguageContext";
import Navbar from "../Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import useCountdown from "../lib/useCountdown";
import axios from "axios";
import Swal from "sweetalert2";
import { PORT } from "../Api/api";
import { Hourglass } from "react-loader-spinner";
import { Button } from "react-bootstrap";
import ButtonLoader from "../Loading/ButtonLoader";

function SendOtpMail() {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailFlag, setEmailFlag] = useState(false);
  const [user, setUser] = useState(null);
  const { start, secondsLeft, minsLeft, ms } = useCountdown(null);
  const [timeup, setTimeup] = useState(false);

  // Validation Schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(language === "english" ? "अमान्य ईमेल" : "Invalid email")
      .required(language === "english" ? "ईमेल आवश्यक है" : "Email is required"),
    OTP: Yup.string()
          .length(4, language === "english" ? "ओटीपी 4 अंक का होना चाहिए" : "OTP must be 4 digits")
          .required(language === "english" ? "ओटीपी आवश्यक है" : "OTP is required"),
  });

  // Formik Setup
  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem('forgotEmail'),
      OTP: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (emailFlag) {
        checkOTP();
      } else {
        sendOTP();
      }
    },
  });

  // OTP Session Expired
  if (secondsLeft === 1) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: language === "english" ? "समय समाप्त !" : "Time Up !",
      showConfirmButton: false,
      timer: 1500,
    });
    setTimeout(() => {
      setTimeup(true);
    }, 1500);
  }

  // Generate OTP
  const sendOTP = async () => {
    setTimeup(false);
    setLoading(true);
    try {
      const url = `${PORT}sendOtpEmail`;
      const response = await axios.post(url, {
        query: formik.values.email,
        affiliateUser: true,
      });
      setLoading(false);
      setUser(response.data.payload);
      if (response.status === 200) {
        setEmailFlag(true);
        Swal.fire({
          position: "center",
          icon: "success",
          title:
            language === "english"
              ? "ओटीपी ईमेल सफलतापूर्वक भेज दिया गया है."
              : "OTP email sent.",
          timer: 1500,
        });
        start(120);
      }
    } catch (error) {
      setLoading(false);
      const errorMsg =
        language === "english"
          ? "कृपया बाद में पुनः प्रयास करें। आंतरिक सर्वर त्रुटि"
          : "Please try again later. Internal server error.";
      if (error.response?.status === 500) {
        Swal.fire("Error!", errorMsg, "error");
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: error.response?.data || errorMsg,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      setEmailFlag(false);
    }finally{
      setLoading(false);
    }
  };

  // Check OTP
  const checkOTP = async () => {
    setLoading(true);
    try {
      if(timeup){
        return Swal.fire('OTP Expired !','Please get new OTP ','error');
      }
      const url = `${PORT}MatchEmailOtp`;
      const payload = { otp: formik.values.OTP };
      if (user.affiliateUser) payload.affiliateUser = user.affiliateUser;
      if (user.societyUser) payload.societyUser = user.societyUser;
     
      const response = await axios.post(url, payload);
      setLoading(false);
      if (response.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title:
            language === "english" ? "ओटीपी सत्यापित" : "OTP Verified",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/changePassword", { state: payload });
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: language === "english" ? "अमान्य ओटीपी" : "Invalid OTP",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      console.error(error);
    }finally{
      setLoading(false);
    }
  };
// console.log(formik.errors);

  return (
    <div className="background_image">
      <GuardLayout>
        <Navbar />
        <div className="main-form-affiliate">
          <div
            className="form-container-affiliate-login"
            style={{ height: "auto", minHeight: "200px" }}
          >
            <h2 className="affiliate-login_title">
              {language === "english" ? "पासवर्ड रीसेट" : "Reset Password"}
            </h2>
            <form onSubmit={formik.handleSubmit}>
              {/* Email Input Section */}
              {!emailFlag && (
                <div className="form-group">
                  <input
                    type="email"
                    className={`form-control mt-2 ${
                      formik.touched.email && formik.errors.email ? "is-invalid" : ""
                    }`}
                    placeholder={
                      language === "english" ? "ईमेल पता दर्ज करें" : "Enter email"
                    }
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="invalid-feedback text-light"><span className="text-light">{formik.errors.email}</span></div>
                  )}
                  <button
                    className="forgot-submit-button"
                    onClick={sendOTP}
                    disabled={loading}
                  >
                    {/* {loading && <Hourglass visible={true} height="20" width="20" />} */}
                    {loading?<ButtonLoader color="green"/>:language === "english" ? "ओटीपी भेजें" : "Send OTP"}
                  </button>
                </div>
              )}

              {/* OTP Input Section */}
              {emailFlag && (
                <div className="form-group">
                  <p className="text-sm text-center">
                    {language === "english"
                      ? "हमने कोड भेज दिया है"
                      : "We've sent code to"}{" "}
                    <strong>{formik.values.email}</strong>
                  </p>
                  {/* <input
                    type="number"
                    className={`form-control mt-2 ${
                      formik.touched.OTP && formik.errors.OTP ? "is-invalid" : ""
                    }`}
                    placeholder={
                      language === "english" ? "ओटीपी दर्ज करें" : "Enter OTP"
                    }
                    name="OTP"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.OTP}
                    disabled={timeup}
                    maxLength={4}
                  /> */}

                <input
                    type="text"
                    id="OTP"
                    name="OTP"
                    className={`form-control mt-2 ${
                      formik.touched.OTP && formik.errors.OTP ? "is-invalid" : ""
                    }`}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Restrict to numeric input
                    }}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9]/g, "");
                      formik.handleChange(e); // Use Formik's handleChange
                      formik.setFieldValue("OTP", numericValue); // Update the value correctly
                    }}
                    onBlur={formik.handleBlur} // Trigger Formik's touched state
                    placeholder={
                      language === "english" ? "ओटीपी दर्ज करें" : "Enter OTP"
                    }
                    maxLength="4"
                    aria-required="true"
                    aria-label={
                      language === "english" ? "ओटीपी दर्ज करें" : "Enter OTP"
                    }
                  />
                  {formik.touched.OTP && formik.errors.OTP && (
                  <div className="invalid-feedback"><span className="text-light">{formik.errors.OTP}</span></div>
                )}
                  <button
                    className="forgot-submit-button"
                    type="submit"
                    disabled={timeup || loading}
                  >
                    {/* {loading && <Hourglass visible={true} height="20" width="20" />} */}
                    {loading?<ButtonLoader color="green"/>:language === "english" ? "ओटीपी सबमिट करें" : "Submit OTP"}
                  </button>
                </div>
              )}
            </form>
            <div className="d-flex justify-content-end " style={{marginBottom:'-10px'}}>
                  {timeup && (
                    <span onClick={sendOTP} style={{ cursor: "pointer" }}>
                      {language === "english"
                        ? `कोड नहीं मिला ? `
                        : `Didn't get a Code ? `}
                      <strong>
                        {language === "english"
                          ? `पुनः भेजें.`
                          : `Click to resend.`}
                      </strong>
                    </span>
                  )}
                  {secondsLeft !== null && secondsLeft > 0 && !timeup && (
                    <p className="text-light mt-2 text-center">
                      <strong>
                        {language === "english"
                          ? `शेष समय : `
                          : `Time Remaining : `}
                      </strong>{" "}
                      0{minsLeft}:{ms < 10 ? `0${ms}` : ms}
                    </p>
                  )}
                </div>
            {/* Timer */}
            {/* {emailFlag && secondsLeft !== null && secondsLeft > 0 && (
              <p className="text-light mt-2 text-center">
                <strong>
                  {language === "english"
                    ? "शेष समय : "
                    : "Time Remaining : "}
                </strong>{" "}
                0{minsLeft}:{ms < 10 ? `0${ms}` : ms}
              </p>
            )} */}
          </div>
        </div>
      </GuardLayout>
    </div>
  );
}

export default SendOtpMail;
