import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { PORT } from "../../../../../../Api/api";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../../../../../lib/Layout";
import { IoEyeSharp } from "react-icons/io5";
import Swal from "sweetalert2";
import AddBackbtn from "../../../../../../lib/AddBackbtn";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import { useFormikContext } from "formik";
import { RxCrossCircled } from "react-icons/rx";
import AffiliateLayout from "../../../../affiliateLib/affiliateLayout/AffiliateLayout";
import ButtonLoader from "../../../../../../Loading/ButtonLoader";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: "500px",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};
const EditClient = () => {
  const { language } = useContext(LanguageContext);
  const params = useParams();
  const id = params.id;
  const [getSocietyDeatils, setGetSoceityDeatils] = useState();
  const getRoleId = JSON.parse(localStorage.getItem("roleId"));
  const getParentId = JSON.parse(localStorage.getItem("ParentId"));
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedHouses, setSelectedHouses] = useState();
  const [planeType, setPlaneType] = useState();
  const [interval, setInterval] = useState();
  const [subscription, setSubscription] = useState([]);
  const [plane, setPlane] = useState("");
  const [b, setB] = useState([]);
  const [initialValues, setinitialValues] = useState({
    name: "",
    address: "",
    societyRegistration: "",
    societyHouseList: "",
    societyContactNumber: "",
    societyLogo: "",
    createdBy: getRoleId,
    parentId: getParentId,
    defaultPermissionLevel: 4,
    role: "Society Admin",
    state: "",
    city: "",
    planType: "",
    intervalType: "",
    discountType: "",
    discountAmount: "",
    pricePerHouse: "",
    currencyType: "",
  });
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    societyContactNumber: Yup.string()
      .matches(/^\d{10}$/, "Contact must be exactly 10 digits")
      .required("Contact is required"),
  });
  //fetch SocietyDataWithId
  const getSocietyDataWithId = async () => {
    try {
      const response = await axios.get(`${PORT}/getSocietyDetailsWithId/${id}`);
      setinitialValues(response.data.data);
      setGetSoceityDeatils(response.data.data);
    } catch (error) {}
  };
  //useRevalidateOnLanguageChange
  const useRevalidateOnLanguageChange = () => {
    const { validateForm } = useFormikContext();

    React.useEffect(() => {
      validateForm();
    }, [language, validateForm]);
  };
  //useRevalidateOnLanguageChange
  const RevalidateOnLanguageChange = () => {
    useRevalidateOnLanguageChange();
    return null;
  };
  //handle submit to edit society details
  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to update the Society",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSubmitting(true);
        try {
          const formData = new FormData();

          // Destructure the values to make it cleaner
          const { societyLogo, pricePerHouse, currencyType, ...otherValues } =
            values;

          // Add societyLogo if it is a File
          if (societyLogo instanceof File) {
            formData.append("societyLogo", societyLogo);
          }
          // Handle special conditions for pricePerHouse and currencyType
          if (pricePerHouse !== null && pricePerHouse !== undefined) {
            formData.append("pricePerHouse", b?.price || values.pricePerHouse); // Ensure string type
          }
          if (
            currencyType !== null &&
            currencyType !== undefined &&
            planeType !== "Custom"
          ) {
            formData.append(
              "currencyType",
              b?.commissionCurrency || values.currencyType
            ); // Ensure string type
          }

          // Iterate through other values, including arrays or other fields
          Object.entries(otherValues).forEach(([key, value]) => {
            if (value !== null && value !== undefined) {
              if (Array.isArray(value)) {
                // If value is an array (for files), append each file
                value.forEach((file) => formData.append(key, file));
              } else {
                formData.append(key, value);
              }
            }
          });

          // Send the API request
          await axios.put(
            `${PORT}/updateSocietyDeatilsWithId/${id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          Swal.fire({
            title: "Updated!",
            text: "Your Society has been Updated",
            icon: "success",
            timer: 1500,
            timerProgressBar: true,
          }).then(() => {
            navigate(-1);
          });
          setSubmitting(false);
        } catch (error) {
          console.error("Error updating entry:", error);
          setSubmitting(false);
          Swal.fire({
            title: "Error",
            text: "An error occurred while updating the entry.",
            icon: "error",
          });
        } finally {
          setSubmitting(false);
        }
      }
      setSubmitting(false);
    });
  };
  //handleHouseChange
  const handleHouseChange = (e, setFieldValue) => {
    const selectedRange = e.target.value;
    setFieldValue("societyHouseList", selectedRange);
    setSelectedHouses(selectedRange);
    // Call the API or logic to get the subscription based on house range
    fetchSubscription(selectedRange, setFieldValue);
  };
  //handle price and selct houses according to plane
  const getsubscription = async () => {
    try {
      const response = await axios.get(`${PORT}/getSubscription`);
      setSubscription(response.data);
    } catch (error) {}
  };
  //fetch subscription
  const fetchSubscription = (houseRange, setFieldValue) => {
    if (!subscription) return;
    const selectedPlan = subscription.find(
      (plan) => plan.societyHouseList === houseRange
    );
    if (selectedPlan) {
      setPlaneType(selectedPlan.name);
      setInterval(selectedPlan.interval);
      setFieldValue("planType", selectedPlan.name);
      setFieldValue("intervalType", selectedPlan.interval || "monthly");
      setFieldValue("billingCycle", selectedPlan.interval);
      setFieldValue("pricePerHouse", selectedPlan.price);
      setFieldValue("currencyType", selectedPlan.commissionCurrency);
    } else {
      setFieldValue("planType", "");
      // setFieldValue("intervalType", "");
      setPlaneType("");
      setInterval("");
    }
  };
  // Handle Discount Type Change
  const handleDiscountTypeChange = (e, setFieldValue) => {
    const selectedDiscountType = e.target.value;
    setFieldValue("discountType", selectedDiscountType);
    // If changing to "Flat", you might want to reset or change the discount amount calculation
    if (selectedDiscountType === "flat") {
      setFieldValue("discountAmount", 10);
    } else {
      setFieldValue("discountAmount", 0);
    }
  };
  //handle plan Change
  const handlePlanTypeChange = (e, setFieldValue, values) => {
    const selectedPlan = e.target.value;
    // Set the selected plan type
    setFieldValue("planType", selectedPlan);

    if (selectedPlan === "Custom") {
      // Clear previous fetched values to allow manual input
      setFieldValue("pricePerHouse", ""); // Clear price
      setFieldValue("currencyType", ""); // Clear currency
      setFieldValue("intervalType", "monthly"); // Optional, clear interval if needed
    } else {
      // Fetch subscription details based on house range
      fetchSubscription(values.societyHouseList, setFieldValue);
    }
  };
  //ftech price according to plan
  const fetchPriceAccToSelectedPlan = (plane) => {
    if (!subscription) return;
    const selectedPlan = subscription.find((plan) => plan.name === plane);
    setB(selectedPlan);
  };

  //function lock
  // useEffect(() => {
  //   fetchSelectPlanePrice(plane);
  // }, [plane]);
  useEffect(() => {
    fetchPriceAccToSelectedPlan(plane);
  }, [plane]);
  useEffect(() => {
    getsubscription();
    getSocietyDataWithId();
  }, []);
  return (
    <>
      <AffiliateLayout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi" ? " Edit Client" : "संपादित करें क्लाइंट"}
          </h5>

          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <br />

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, values, isSubmitting }) => (
            <Form enctype="multipart/form-data">
              <div className="society_main">
                <div className="society_main_div">
                  <div className="society_detail_heading">
                    {language === "hindi"
                      ? " Society details"
                      : "सोसायटी विवरण"}
                  </div>
                  <div className="edit-society-input">
                    <label className="edit_society_lable" htmlFor="name">
                      {language === "hindi"
                        ? "  Society Name "
                        : "सोसायटी का नाम "}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      id="name"
                      className="edit-input"
                      name="name"
                      maxLength="35"
                      required
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error_msg_society"
                    />
                  </div>
                  <div className="edit-society-input">
                    <label className="edit_society_lable" htmlFor="societyLogo">
                      {language === "hindi"
                        ? "Society Logo "
                        : "सोसायटी लोगो  "}
                    </label>
                    <br />
                    <div className="input-wrapper-society-logo">
                      <input
                        type="file"
                        className="edit-input"
                        name="societyLogo"
                        accept="image/*"
                        onChange={(event) => {
                          setFieldValue(
                            "societyLogo",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <div className="eye-button-society">
                        <IoEyeSharp
                          onClick={handleOpen}
                          data-toggle="tooltip"
                          className="eyes_view"
                          data-placement="top"
                          title={language === "hindi" ? "view" : "देखना"}
                        />
                      </div>
                    </div>{" "}
                  </div>
                  <div className="edit-society-input">
                    <label className="edit_society_lable" htmlFor="address">
                      {language === "hindi" ? " Address" : "पता"}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      className="edit-input"
                      id="address"
                      name="address"
                      maxLength="45"
                      required
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="error_msg_society"
                    />
                  </div>
                  <div className="edit-society-input">
                    <label className="edit_society_lable" htmlFor="state">
                      {language === "hindi" ? " State" : "पता"}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      className="edit-input"
                      id="state"
                      name="state"
                      maxLength="45"
                      required
                    />
                  </div>
                  <div className="edit-society-input">
                    <label className="edit_society_lable" htmlFor="state">
                      {language === "hindi" ? " City" : "पता"}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      className="edit-input"
                      id="city"
                      name="city"
                      maxLength="45"
                      required
                    />
                  </div>
                  <div className="edit-society-input">
                    <label
                      className="edit_society_lable"
                      htmlFor="societyContactNumber"
                    >
                      {language === "hindi" ? "  Contact No." : "संपर्क संख्या"}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      className="edit-input"
                      id="societyContactNumber"
                      name="societyContactNumber"
                      required
                      maxLength={10}
                      minLeghth={10}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Restrict to numeric input
                      }}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        ); // Remove non-numeric characters
                        setFieldValue("societyContactNumber", numericValue); // Update Formik value
                      }}
                    />
                    <ErrorMessage
                      name="societyContactNumber"
                      component="div"
                      className="error_msg_society"
                    />
                  </div>
                  <div className="edit-society-input">
                    <label
                      className="edit_society_lable"
                      htmlFor="societyHouseList"
                    >
                      {language === "hindi"
                        ? "No.of Houses in Society"
                        : "सोसायटी  में घरों की संख्या "}{" "}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      as="select"
                      className="edit-input"
                      id="societyHouseList"
                      name="societyHouseList"
                      required
                      onChange={(e) => handleHouseChange(e, setFieldValue)}
                    >
                      <option value="">
                        {" "}
                        {language === "hindi"
                          ? "Select House Range"
                          : "हाउस रेंज चुनें"}
                      </option>
                      <option value="0-250">0 - 250</option>
                      <option value="250-500">250 - 500</option>
                      <option value="500-1000">500 - 1000</option>
                    </Field>
                    <ErrorMessage
                      name="societyHouseList"
                      component="div"
                      className="error_msg_society"
                    />
                  </div>
                  <div className="edit-society-input">
                    <label
                      className="edit_society_lable"
                      htmlFor="societyRegistration"
                    >
                      {language === "hindi"
                        ? "Society Registration No."
                        : "सोसायटी पंजीकरण संख्या "}
                    </label>

                    <br />
                    <Field
                      type="text"
                      className="edit-input"
                      id="societyRegistration"
                      name="societyRegistration"
                      maxLength="45"
                    />
                    <ErrorMessage
                      name="societyRegistration"
                      component="div"
                      className="error_msg_society"
                    />
                  </div>
                  {/* society email */}
                  <div  className="edit-society-input">
                    <label
                      className="edit_society_lable"
                      htmlFor="societyRegistration"
                    >
                      {language === "hindi" ? "Society Email" : "सोसायटी ईमेल"}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="email"
                      className="edit-input"
                      id="societyEmail"
                      name="societyEmail"
                      maxLength="70"
                      autoComplete="off"
                      required
                    />
                  </div>{" "}
                  <div
                    style={{ visibility: "hidden" }}
                     className="edit-society-input"
                  >
                    <label
                      className="edit_society_lable"
                      htmlFor="societyRegistration"
                    >
                      {language === "hindi" ? "Society Email" : "सोसायटी ईमेल"}{" "}
                      <span className="Star_color">*</span>
                    </label>
                    <br />
                    <Field
                      type="text"
                      className="edit-input"
                      id="societyEmail"
                      name="societyEmail"
                      maxLength="45"
                      autoComplete="off"
                    />
                  </div>
                </div>
                {/* {selectedHouses ? ( */}

                {/* ) : null} */}
                {/* {selectedHouses ? ( */}
                {/* // ) : null} */}
                <div className="society_bottom_btn_div">
                  <button
                    className="society_btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <ButtonLoader />
                    ) : language === "hindi" ? (
                      "    Submit"
                    ) : (
                      "  जमा करना"
                    )}
                  </button>
                  <RevalidateOnLanguageChange />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {/* Modal  For Society Logo     */}
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="top-section" onClick={handleClose}>
                <RxCrossCircled className="close-icon" />
              </div>
              <div className="mt-1 view-society-logo-div">
                {getSocietyDeatils?.societyLogo ? (
                  <div className="society-logo-img">
                    <img
                      src={`/${getSocietyDeatils.societyLogo.replace(
                        "public/",
                        ""
                      )}`}
                      alt=""
                    />
                  </div>
                ) : (
                  <>
                    <h6>
                      {getSocietyDeatils?.name ? (
                        (() => {
                          let words = getSocietyDeatils.name.split(" ");
                          const initials = words[0]
                            ?.substring(0, 1)
                            .toUpperCase();
                          return (
                            <div className="purpose_default_icon">
                              <h5>{initials}</h5>
                            </div>
                          );
                        })()
                      ) : (
                        <div className="align-middle text-center purpose_icon_title">
                          <h5 className="initialss">N/A</h5>
                        </div>
                      )}
                    </h6>
                  </>
                )}
              </div>
            </Box>
          </Modal>
        </div>
      </AffiliateLayout>
      <ToastContainer />
      {/* //Edit Society Images */}
    </>
  );
};

export default EditClient;
