import React, { useEffect, useState } from 'react';

export default function useCountdown(initialSeconds = null) {
  const [secondsLeft, setSecondsLeft] = useState(initialSeconds);

  useEffect(() => {
    if (secondsLeft === null || secondsLeft <= 0) return;

    const interval = setInterval(() => {
      setSecondsLeft((prev) => {
        if (prev <= 1) {
          clearInterval(interval); // Stop countdown when it reaches 0
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount or secondsLeft change
  }, [secondsLeft]);

  const start = (seconds) => {
    setSecondsLeft(Number(seconds));
  };
  const minsLeft = Math.floor(secondsLeft / 60) || 0;
  const ms = secondsLeft % 60 || 0;

  return { secondsLeft, start, minsLeft, ms };
}
