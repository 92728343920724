import { messaging } from '../firebaseConfig/firebaseConfig';
import { getToken } from 'firebase/messaging';

export const getFcmToken = async () => {
    try {
        const currentToken = await getToken(messaging, {
            vapidKey: "BHhm5KkiTAM_trVGmxh1esxP67440mkBK4ISoAA5-pRFuchyaCAe0dEtci87uV88rtw6vcrHRx4trmwN7kXDQa8"
        });
        if (currentToken) {
            console.log("Token received:", currentToken);
            return currentToken;
        } else {
            console.warn("No token available. Make sure notifications are enabled in your browser settings.");
            return null;
        }
    } catch (error) {
        console.error("An error occurred while retrieving the token:", error);
    }
};
