// import React, { useState } from "react";
// import GuardLayout from "../../../lib/GuardLayout";
// import Navbar from "../../../Navbar/Navbar";
// import "./style.css";
// import axios from "axios";
// import { PORT } from "../../../Api/api";
// import { toast, ToastContainer } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// const AffiliateRegister = () => {
//   const [step, setStep] = useState(1);
//   const initialFormData = {
//     agencyName: "",
//     contactPerson: "",
//     documentation: null,
//     email: "",
//     phone: "",
//     password: "",
//     address:"",
//     // confirmPassword: ""
//   };
//   const [formData, setFormData] = useState(initialFormData);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };
//   const navigate = useNavigate();
//   const nextStep = () => setStep(step + 1);
//   const prevStep = () => setStep(step - 1);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const data = new FormData();
//     data.append("agencyName", formData.agencyName);
//     data.append("contactPerson", formData.contactPerson);
//     data.append("email", formData.email);
//     data.append("phone", formData.phone);
//     data.append("password", formData.password);
//     data.append("documentation", formData.documentation);
//     data.append("address", formData.address);

//     try {
//       const response = await axios.post(`${PORT}/affiliateRegister`, data);
//       // console.log(response);
      
//       toast.success(response.data.message);
//       const notify=await axios.post(`${PORT}notifySuperAdminNewAgency`, {affilaite_id:response.data.data._id})   //18/12/24

//       setTimeout(() => {
//         navigate("/affiliateLogin");
//         resetForm();
//       }, 1500);
//     } catch (error) {
//       toast.error(error.response.data.message);
//     }
//   };
//   const resetForm = () => {
//     setFormData(initialFormData);
//   };
//   const handleFileChange = (e) => {
//     const file = e.target.files[0]; // Get the file object
//     setFormData({ ...formData, documentation: file });
//   };
//   return (
//     <GuardLayout>
//       <Navbar />
//       <div className="affiliate-register-container">
//         {/* Progress Indicator */}
//         <div className="progress-indicator">
//           {[1, 2].map((item) => (
//             <div
//               key={item}
//               className={`progress-step ${step >= item ? "activeLine" : ""}`}
//             >
//               <div className="circle">{item}</div>
//               {item < 2 && <div className="line"></div>}
//             </div>
//           ))}
//         </div>

//         <form
//           className="form-container-affiliate-register"
//           onSubmit={handleSubmit}
//         >
//           {step === 1 && (
//             <div className="form-step">
//               <h3>Agency Information</h3>
//               <label>
//                 Agency Name:
//                 <input
//                   type="text"
//                   name="agencyName"
//                   value={formData.agencyName}
//                   onChange={handleChange}
//                   required
//                   placeholder="Enter agency name"
//                   maxLength={50}
//                 />
//               </label>
//               <label>
//                 Contact Person:
//                 <input
//                   type="text"
//                   name="contactPerson"
//                   value={formData.contactPerson}
//                   onChange={handleChange}
//                   required
//                   maxLength={50}
//                   placeholder="Enter contact person's name"
//                 />
//               </label>
//               <label>
//                 Documentation:
//                 <input
//                   type="file"
//                   name="documentation"
//                   onChange={handleFileChange} 
//                   required
//                 />
//                 <small className="pt-1">
//                   Please upload the required documentation
//                 </small>
//               </label>
//               <button type="button" onClick={nextStep}>
//                 Next
//               </button>
//             </div>
//           )}

//           {step === 2 && (
//             <div className="form-step">
//               <h3>Contact Information</h3>
//               <label>
//                 Email:
//                 <input
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   required
//                   placeholder="Enter your email address"
//                 />
//               </label>
//               <label>
//                 Phone:
//                 <input
//                   type="text"
//                   name="phone"
//                   value={formData.phone}
//                   onChange={handleChange}
//                   required
//                   pattern="[0-9]{10}"
//                   maxLength={10}
//                   placeholder="Enter a 10-digit phone number"
//                 />
//               </label>
//               <label>
//                 Password:
//                 <input
//                   type="password"
//                   name="password"
//                   value={formData.password}
//                   onChange={handleChange}
//                   required
//                   minLength="6"
//                   placeholder="Enter at least 6 characters"
//                 />
//               </label>
//               <label>
//               Address:
//                 <textarea
//                   name="address"
//                   rows={5}
//                   value={formData.address}
//                   onChange={handleChange}
//                   required
//                   placeholder="Enter Agency Address"
//                 />
//               </label>
//               <button type="button" onClick={prevStep}>
//                 Previous
//               </button>
//               <button type="submit">Submit</button>
//             </div>
//           )}
//         </form>
//       </div>
//       <ToastContainer />
//     </GuardLayout>
//   );
// };

// export default AffiliateRegister;

import React, { useState,useContext } from "react";
import GuardLayout from "../../../lib/GuardLayout";
import Navbar from "../../../Navbar/Navbar";
import "./style.css";
import axios from "axios";
import { PORT } from "../../../Api/api";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { ThreeCircles } from "react-loader-spinner";
import { IoIosEyeOff, IoIosEye } from "react-icons/io";
import { Hourglass } from "react-loader-spinner";
import { LanguageContext } from "../../../lib/LanguageContext";
import ButtonLoader from "../../../Loading/ButtonLoader";
  
const AffiliateRegister = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [loadingPermission, setLoadingPermission] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { language } = useContext(LanguageContext);
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Step 1 validation schema
  const validationSchemaStep1 = Yup.object({
    agencyName: Yup.string()
      .max(50, language === "hindi"? "Agency Name must be 50 characters or less":"एजेंसी का नाम 50 अक्षर या उससे कम होना चाहिए")
      .min(4, language === "hindi"?"Agency Name must contain at least 4 characters":"एजेंसी के नाम में कम से कम 4 अक्षर होने चाहिए")
      .required(language === "hindi"?"Agency Name is required":"एजेंसी का नाम आवश्यक है"),
    contactPerson: Yup.string()
      .max(50, language === "hindi"?"Contact Person's Name must be 50 characters or less":"संपर्क व्यक्ति का नाम 50 अक्षर या उससे कम होना चाहिए")
      .min(4, language === "hindi"?"Contact Person's Name must contain at least 4 characters":"संपर्क व्यक्ति के नाम में कम से कम 4 अक्षर होने चाहिए")

      .required(language === "hindi"?"Contact Person is required":"संपर्क व्यक्ति आवश्यक है"),
    documentation: Yup.mixed().required(language === "hindi"?"Documentation is required":"दस्तावेज़ीकरण आवश्यक है"),
  });

  const emailValidation = Yup.string()
    .required(language === "hindi" ? "Email is required":"ईमेल की जरूरत है"  )
    .max(
        60,
        language === "hindi"
            ? "Email must be at most 60 characters long":"ईमेल अधिकतम 60 अक्षर लंबा होना चाहिए"
            
    )
    .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        language === "hindi"
            ? "Please enter a valid email address" :"कृपया एक मान्य ईमेल पता दर्ज करें"
            
    );

  // Step 2 validation schema
  const validationSchemaStep2 = Yup.object({
    email: emailValidation ,
    phone: Yup.number()
    .typeError(language === "hindi"?"Phone number must be a valid number":"फ़ोन नंबर एक वैध नंबर होना चाहिए")
    .integer(language === "hindi"?"Phone number must be a valid number":"फ़ोन नंबर एक वैध नंबर होना चाहिए")
    .test(
      "len",
      language === "hindi"?"Phone number must be exactly 10 digits":"फ़ोन नंबर बिल्कुल 10 अंकों का होना चाहिए",
      (val) => val && val.toString().length === 10
    )
    .required(language === "hindi"?"Phone number is required":"फ़ोन नंबर आवश्यक है"),
  
    password: Yup.string()
      .min(6, language === "hindi"?"Password must be at least 6 characters":"पासवर्ड कम से कम 6 अंकों का होना चाहिए")
      .required(language === "hindi"?"Password is required":"पासवर्ड आवश्यक है"),
    address: Yup.string().required(language === "hindi"?"Address is required":"पता आवश्यक है"),
  });

  // Form submission handler
  const handleSubmit = async (values, { setSubmitting }) => {
    setLoadingPermission(true)
    const data = new FormData();
    data.append("agencyName", values.agencyName);
    data.append("contactPerson", values.contactPerson);
    data.append("email", values.email);
    data.append("phone", values.phone);
    data.append("password", values.password);
    data.append("documentation", values.documentation);
    data.append("address", values.address);

    try {
      const response = await axios.post(`${PORT}/affiliateRegister`, data);
      toast.success(response.data.message);
      setLoadingPermission(false)

      // console.log(response);
      // console.log(response.data.data._id);

      setTimeout(() => {
        navigate("/affiliateLogin");
      }, 1500);

      await axios.post(`${PORT}/notifySuperAdminNewAgency`, {
        affiliate_id: response.data.data._id,
      });
    } catch (error) {
      setStep((prevStep) => prevStep - 1);
      setLoadingPermission(false)
      toast.error(error.response?.data?.message);
      // Swal.fire({
      //   icon: "error",
      //   title: "Submission Error",
      //   text: error.response?.data?.message || "Something went wrong!",
      // });
    } finally {
      setSubmitting(false);
    }
  };

  // Function to validate current step and move to the next
  const handleNextStep = async (validateForm, setTouched) => {
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      // Show Swal error for validation issues
      // Swal.fire({
      //   icon: "error",
      //   title: "Validation Errors",
      //   html: Object.values(errors)
      //     .map((error) => `<p>${error}</p>`)
      //     .join(""),
      // });
      setTouched(errors); // Mark fields as touched to show inline errors
      return;
    }
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <div className="background_image">
    <GuardLayout>
      <Navbar />
      <div className="affiliate-register-container">
        {/* Progress Indicator */}
        <div className="progress-indicator">
          {[1, 2].map((item) => (
            <div
              key={item}
              className={`progress-step ${step >= item ? "activeLine" : ""}`}
            >
              <div className="circle">{item}</div>
              {item < 2 && <div className="line "></div>}
            </div>
          ))}
        </div>

        <Formik
          initialValues={{
            agencyName: "",
            contactPerson: "",
            documentation: null,
            email: "",
            phone: "",
            password: "",
            address: "",
          }}
          validationSchema={step === 1 ? validationSchemaStep1 : validationSchemaStep2}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, validateForm, setTouched, isSubmitting }) => (
            <Form className="form-container-affiliate-register">
              {step === 1 && (
                <div className="form-step">
                  <h3 className="text-center ">{language === "hindi"?"AGENCY INFORMATION":"एजेंसी की जानकारी"}</h3>
                  <label>
                        {language === "hindi"? "Agency Name":"एजेंसी का नाम"}
                    <Field
                      type="text"
                      name="agencyName"
                      placeholder={language === "hindi"?"Enter agency name":"एजेंसी का नाम दर्ज करें"}
                      maxLength={50}
                    />
                    <ErrorMessage
                      name="agencyName"
                      component="div"
                      className="error-message  text-light mb-0"
                    />
                  </label>
                  <label>
                  {language === "hindi"? "Contact Person":"संपर्क व्यक्ति"} 
                    
                    <Field
                      type="text"
                      name="contactPerson"
                      placeholder={language === "hindi"?"Enter contact person's name":"संपर्क व्यक्ति का नाम दर्ज करें"}
                      maxLength={50}
                    />
                    <ErrorMessage
                      name="contactPerson"
                      component="div"
                      className="error-message text-light"
                    />
                  </label>
                  <label>{language === "hindi"? "Documentation":"प्रलेखन"} 
                    <input
                      type="file"
                      name="documentation"
                      onChange={(e) => setFieldValue("documentation", e.target.files[0])}
                    />
                    <ErrorMessage
                      name="documentation"
                      component="div"
                      className="error-message text-light"
                    />
                  </label>
                  <button
                    type="button"
                    onClick={() => handleNextStep(validateForm, setTouched)}
                  >
                  {language === "hindi"? "Next":"अगला"}   
                  </button>
                </div>
              )}

              {step === 2 && (
                <div className="form-step">
                  <h3 className="text-center text-uppercase ">Contact Information</h3>
                  <label>{language === "hindi"? " Email":"ईमेल"} 
                   
                    <Field
                      type="email"
                      name="email"
                      placeholder={language === "hindi"?"Enter your email address":"अपना ईमेल पता दर्ज करें"}
                      maxLength="60"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message  text-light"
                    />
                  </label>
                  <label>{language === "hindi"? "Phone":"फ़ोन"} 
                    <Field
                        type="text"
                        id="phone"
                        name="phone"
                        onChange={(e) => {
                          // Allow only numeric values
                          const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                          setFieldValue("phone", numericValue); // Update Formik value
                        }}
                        placeholder={language === "hindi"?"Enter a 10-digit phone number":"10 अंकों वाला फ़ोन नंबर दर्ज करें"}
                        maxLength="10"
                      />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="error-message  text-light"
                    />
                  </label>
                  <label>{language === "hindi"? "Password":"पासवर्ड"} 
                    
                    <div className="password-container">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder={language === "hindi"?"Enter at least 6 characters":"कम से कम 6 वर्ण दर्ज करें"}
                      className="password-input"
                    />
                    {showPassword ? (
                      <IoIosEye className="eye-icon" onClick={togglePasswordVisibility} />
                    ) : (
                      <IoIosEyeOff className="eye-icon" onClick={togglePasswordVisibility} />
                    )}
                  </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error-message  text-light"
                    />
                  </label>
                  <label>{language === "hindi"? "Address":"पता"} 
                   
                    <Field
                      as="textarea"
                      name="address"
                      rows={5}
                      placeholder={language === "hindi"?"Enter Agency Address":"एजेंसी का पता दर्ज करें"}
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="error-message text-light"
                    />
                  </label>
                  <button type="button" onClick={() => setStep(1)} >
                  { language === "hindi" ? "Previous":"पिछला"}
                  </button>
                  <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ?<ButtonLoader color="green"/>
                      // <Hourglass
                      //   visible={true}
                      //   height="20"
                      //   width="20"
                      //   ariaLabel="hourglass-loading"
                      //   wrapperStyle={{}} 
                      //   wrapperClass=""
                      //   colors={["#ffffff"]}
                      //   className="mt-1"
                      // />
                   : language === "hindi" ? " Submit":" सबमिट"}
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer />
    </GuardLayout>
    </div>
  );
};

export default AffiliateRegister;
