import React, { useState, useEffect, useRef } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { PORT } from "../Api/api";
import ButtonLoader from "../Loading/ButtonLoader";
import decodeToken from "../affiliate/affiliatePanel/affiliateLib/decodeToken/DecodeToken";
import { AiOutlineEye } from "react-icons/ai";
import ImagePreviewModal from "./ImagePreviewModal";
import Tooltip from "@mui/material/Tooltip";

const ViewDisputeModal = ({
  show,
  btnLoading,
  handleClose,
  disputeId,
  language,
}) => {
  // console.log("disputeId",disputeId);

  const [disputeData, setDisputeData] = useState(null);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendingComment, setSendingComment] = useState(false);
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [msg, setmsg] = useState(false);
  const chatEndRef = useRef(null);

  const token = localStorage.getItem("token");
  const decodedToken = decodeToken(token);

  const isSuperAdmin = JSON.parse(localStorage.getItem("roleId"));

  const currentUserModel = isSuperAdmin ? "signUpUsers" : "AffiliateAuth";
  const currentUserId = isSuperAdmin ? isSuperAdmin : decodedToken.id;
  const openImagePreview = () => {
    if (!disputeData.screenshot) {
      setmsg(!msg);
    } else {
      setIsImagePreviewOpen(true);
    }
  };
  const closeImagePreview = () => setIsImagePreviewOpen(false);

  useEffect(() => {
    if (disputeId) {
      // Set the interval to fetch dispute data every 5 seconds
      fetchDisputeData();
      const intervalId = setInterval(() => {
        fetchDisputeData();
      }, 10000);

      // Cleanup function to clear the interval if disputeId changes or component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [disputeId]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [disputeData]);

  const fetchDisputeData = async () => {
    try {
      // setLoading(true);
      const response = await axios.get(
        `${PORT}getDisputeByDisputeId/${disputeId}`,
        {
          params: { userId: currentUserId }, // Pass userId as query parameter
        }
      );
      setDisputeData(response.data);
      console.log("response.data", response.data);
    } catch (error) {
      console.error("Error fetching dispute data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleAddComment = async () => {
    if (!comment.trim()) return;

    try {
      setSendingComment(true);
      const payload = {
        text: comment,
        user: currentUserId,
        userModel: currentUserModel,
      };
      await axios.post(
        `${PORT}addDisputeConversationMessage/${disputeId}`,
        payload
      );
      await fetchDisputeData();
      setComment("");
    } catch (error) {
      console.error("Error adding comment", error);
    } finally {
      setSendingComment(false);
    }
  };

  const handleModalClose = () => {
    setDisputeData(null);
    handleClose();
  };

  const getUserName = (comment) => {
    if (comment.userModel === "signUpUsers") {
      return "Admin";
    } else if (comment.userModel === "AffiliateAuth") {
      return "Affiliate";
    }
    return "Unknown User";
  };

  const isCurrentUserComment = (comment) => {
    return (
      comment.user === currentUserId && comment.userModel === currentUserModel
    );
  };

  const formatDateLabel = (date) => {
    const messageDate = new Date(date);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (messageDate.toDateString() === today.toDateString()) {
      return language === "hindi" ? "आज" : "Today";
    }
    if (messageDate.toDateString() === yesterday.toDateString()) {
      return language === "hindi" ? "कल" : "Yesterday";
    }
    return messageDate.toLocaleDateString(
      language === "hindi" ? "hi-IN" : "en-US",
      {
        year: "numeric",
        month: "short",
        day: "numeric",
      }
    );
  };

  let lastMessageDate = null;

  return (
    <Modal show={show} onHide={handleModalClose} size="lg" centered>
      <Modal.Header closeButton className="bg-primary text-white">
        <Modal.Title>
          <span className="card-billing-heading1 ">
            {language === "hindi" ? "विवाद देखें" : "View Dispute"}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column p-0" style={{ height: "90vh" }}>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          disputeData && (
            <div className="d-flex flex-column  h-100">
              <div
                className="p-3 border-bottom"
                style={{ backgroundColor: "#f8f9fa" }}
              >
                <h5 className="text-primary">
                  {language === "hindi" ? "विवाद विवरण" : "Dispute Details"}
                </h5>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      <strong>
                        {language === "hindi" ? "कारण:" : "Reason:"}
                      </strong>{" "}
                      {disputeData.disputeReason}
                    </p>
                    <p className="pt-2">
                      <strong>
                        {language === "hindi" ? "विवरण:" : "Description:"}
                      </strong>{" "}
                      {disputeData.disputeDescription}
                    </p>
                    <p className="pt-2">
                      <strong>
                        {language === "hindi" ? "विवरण:" : "Dispute Id:"}
                      </strong>{" "}
                      {disputeData._id}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex justify-content-between">
                      <p>
                        <strong>
                          {language === "hindi" ? "स्थिति:" : "Status:"}
                        </strong>{" "}
                        &nbsp;{" "}
                        <span
                          className={`status-badge ${disputeData.status.toLowerCase()} `}
                        >
                          {disputeData.status}
                        </span>
                      </p>
                      {/* iamge preview */}
                      <Tooltip
                        placement="top"
                        title={
                          disputeData.screenshot
                            ? language === "hindi"
                              ? "विवाद छवि खोलें"
                              : "Open Dispute Image"
                            : language === "hindi"
                            ? "कोई छवि उपलब्ध नहीं है"
                            : "No Image Available"
                        }
                      >
                        <span
                          className="mx-2 d-inline-flex  justify-content-end"
                          onClick={
                            disputeData.screenshot ? openImagePreview : null
                          } // Ensure function only runs if screenshot exists
                          role="button"
                          tabIndex={0}
                          style={{
                            cursor: disputeData.screenshot
                              ? "pointer"
                              : "not-allowed",
                            opacity: disputeData.screenshot ? 1 : 0.5, // Visually indicate if disabled
                          }}
                          aria-disabled={!disputeData.screenshot}
                          aria-label={
                            disputeData.screenshot
                              ? language === "hindi"
                                ? "छवि पूर्वावलोकन खोलें"
                                : "Open Image Preview"
                              : language === "hindi"
                              ? "कोई छवि उपलब्ध नहीं है"
                              : "No Image Available"
                          }
                        >
                          <AiOutlineEye
                            style={{ height: "30px", width: "30px" }}
                            className="flex items-center"
                          />
                        </span>
                      </Tooltip>
                      {/* <span className="mx-2 d-inline-flex align-items-center justify-content-end" onClick={openImagePreview}>
                      <AiOutlineEye
                    variant="outline"
                    style={{height:"20px",width:"20px"}}
                    className="flex items-center "
                    disabled={!disputeData.screenshot} 
                  /> */}
                      {/* <AiOutlineEye /> */}
                      {/* {
                    !msg ?
                    language === "hindi" ? "छवि देखें" : "View Dispute Image"
                    :
                    <span className="text-warning">{language === "hindi" ? " छवि उपलब्ध नहीं है" : "No Image Available"}</span>

                  }
                        </Button> */}
                      {/* </span> */}
                    </div>
                    {disputeData.status !== "Pending" && (
                      <p>
                        <strong>
                          {language === "hindi"
                            ? "समाधान नोट्स:"
                            : "Resolution Notes:"}
                        </strong>{" "}
                        {disputeData.resolutionNotes || "-"}
                      </p>
                    )}
                    <p></p>
                  </div>
                </div>

                <ImagePreviewModal
                  isOpen={isImagePreviewOpen}
                  onClose={closeImagePreview}
                  imageUrl={disputeData?.screenshot}
                />
              </div>

              <div
                className="flex-grow-1 overflow-auto p-3"
                style={{ backgroundColor: "#e9ecef" }}
              >
                {disputeData.comments.map((comment) => {
                  const messageDateLabel = formatDateLabel(comment.createdAt);
                  const showDateLabel = lastMessageDate !== messageDateLabel;
                  lastMessageDate = messageDateLabel;

                  return (
                    <React.Fragment key={comment._id}>
                      {showDateLabel && (
                        <div
                          className="text-center my-2 text-muted sticky-date-label"
                          style={{
                            width: "20%",
                            left: "40%",
                            position: "sticky",
                            top: "0",
                            zIndex: "10",
                            backgroundColor: "#f8f9fa", // Matches modal background
                            padding: "5px 10px",
                            borderRadius: "10px",
                            boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <small className="fw-bold text-dark">
                            {messageDateLabel}
                          </small>
                        </div>
                      )}
                      <div
                        key={comment._id}
                        className={`d-flex mb-3 ${
                          isCurrentUserComment(comment)
                            ? "justify-content-end"
                            : "justify-content-start"
                        }`}
                      >
                        <div
                          className={`p-3 rounded shadow-sm  ${
                            isCurrentUserComment(comment)
                              ? "bg-primary text-white text-end "
                              : "bg-white text-dark text-start"
                          }`}
                          style={{
                            maxWidth: "70%",
                            minWidth: "40%",
                            alignSelf: isCurrentUserComment(comment)
                              ? "flex-end"
                              : "flex-start",
                            wordBreak: "break-word",
                          }}
                        >
                          <strong>{getUserName(comment)}</strong>
                          <p className="mb-1">{comment.text}</p>
                          <small
                            className={
                              isCurrentUserComment(comment)
                                ? "text-light"
                                : "text-muted"
                            }
                          >
                            {new Date(comment.createdAt).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </small>
                          {comment.user == currentUserId && (
                            <small>
                              <span className="ms-2">
                                {comment?.read ? "✔✔" : "✔"}
                              </span>
                            </small>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
                <div ref={chatEndRef} />
              </div>

              <div className="p-3 border-top">
                <Form.Group controlId="comment" className="mb-2">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={comment}
                    onChange={handleCommentChange}
                    placeholder={
                      language === "hindi"
                        ? "अपनी टिप्पणी दर्ज करें..."
                        : "Enter your comment..."
                    }
                    style={{ resize: "none" }}
                  />
                </Form.Group>
                <div className="d-flex justify-content-end text-light">
                  <Button
                    variant="primary"
                    onClick={handleAddComment}
                    disabled={sendingComment || !comment.trim()}
                    style={{ color: "white" }}
                  >
                    {sendingComment ? (
                      <Spinner animation="border" size="sm" />
                    ) : language === "hindi" ? (
                      "टिप्पणी जोड़ें"
                    ) : (
                      "Add Comment"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          )
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          {btnLoading ? (
            <ButtonLoader />
          ) : language === "hindi" ? (
            "बंद करें"
          ) : (
            "Close"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewDisputeModal;
