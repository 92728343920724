

const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const date = now.getDate().toString().padStart(2, "0");
  return `${date}-${month}-${year}`;
};

export default getCurrentDate

// 20-12-2024 jknk
