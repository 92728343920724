import React, { useContext, useEffect, useState } from "react";
import AffiliateLayout from "../../affiliateLib/affiliateLayout/AffiliateLayout";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { Bar, Line, Doughnut, Pie } from "react-chartjs-2";
import { FaLocationArrow } from "react-icons/fa";
import { ThreeCircles } from "react-loader-spinner";
import { FaHouseUser, FaBuilding } from "react-icons/fa";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { MdPermIdentity } from "react-icons/md";
import { BsFillHouseSlashFill } from "react-icons/bs";
import { GiReceiveMoney } from "react-icons/gi";
import { MdOutlineCurrencyExchange, MdPaid } from "react-icons/md";
import { BsFillHouseCheckFill } from "react-icons/bs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import axios from "axios";
import { PORT } from "../../../../Api/api";
import decodeToken from "../../affiliateLib/decodeToken/DecodeToken";
import Loading from "../../../../Loading/Loading";
import SmallLoader from "../../../../Loading/SmallLoader";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);
const AffiliateDashboard = () => {
  const { language } = useContext(LanguageContext);
  const [societyData, setSocietyData] = useState({});
  const [approvedSociety, setUserLength] = useState(5);
  const [entriesLengthBySuperAdmin, setEntriesLengthBySuperAdmin] = useState(0);
  const [purposeLengthBySuperAdmin, setPurposeLengthBySuperAdmin] = useState(0);
  const [societyYear, setSocietyYear] = useState(2024);
  const [societyChartLength, setSocietyChartLength] = useState();
  const [sococietyLoader, setSococietyLoader] = useState(true);
  const [commissionLoader, SetCommissionLoader] = useState(true);
  const [yearsData, setYearData] = useState({});
  const thisYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(thisYear);

  const [affiliateEarnings, setAffiliateEarnings] = useState({
    totalCommission: 0,
    pendingCommission: 0,
    paidCommission: 0,
  });
  const token = localStorage.getItem("token");
  const decodePayload = decodeToken(token);
  const [isLoading, setIsLoading] = useState(true);
  // const [societyData,setSocietyData]=useEffect({})

  // Handle year change
  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };
  const fetchSocietyCount = async () => {
    try {
      const response = await axios.post(`${PORT}getAffiliateSocietyCount`, {
        affiliate_id: decodePayload.id,
      });

      setSocietyData(response?.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1100);
    } catch (error) {
      console.log("while fetching society count", error);
    } finally {
      setSococietyLoader(false);
      SetCommissionLoader(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1100);
    }
  };
  // console.log(yearsData,"yearsData")
  const fetchCommisionDetails = async () => {
    try {
      const response = await axios.post(`${PORT}getAffiliateYearlyCommmision`, {
        affiliate_id: decodePayload.id,
      });
      setYearData(response?.data?.yearData || {});

      setAffiliateEarnings(
        {
          totalCommission:
            response?.data?.affiiateEarnings.affiliateTotalCommission || 0,
          pendingCommission:
            response?.data?.affiiateEarnings.affiliatePendingEarnings || 0,
          paidCommission:
            response?.data?.affiiateEarnings.affiliatePaidEarnings || 0,
        } || {}
      );
      // console.log(response,"response+++--->")
    } catch (error) {
      console.log("while fetching commsion details", error);
    }
  };
  useEffect(() => {
    fetchCommisionDetails();
    fetchSocietyCount();
  }, []);

  // console.log('sdsd',yearsData);
  const selectedYearData = yearsData[selectedYear] || [];
  // Extract the months
  const months = selectedYearData.map((item) => item.month) || [];
  // Prepare the total commission for all societies in each month
  const totalCommissions = selectedYearData.map((monthData) =>
    monthData.societies.reduce((sum, society) => sum + society.commission, 0)
  );
  const pendingCommissions = selectedYearData.map((monthData) =>
    monthData.societies.reduce(
      (sum, society) => sum + society.pendingCommission,
      0
    )
  );
  // console.log("totalCommissions", pendingCommissions);
  const totalPaid = totalCommissions.reduce((sum, value) => sum + value, 0);
  const totalPending = pendingCommissions.reduce(
    (sum, value) => sum + value,
    0
  );

  // socity commission Chart.js data
  const chartData = {
    labels: months,
    datasets: [
      {
        label:
          language === "hindi"
            ? `Earned commission ₹( ${totalPaid}) `
            : ` कमीशन कमाया ₹(${totalPaid}) `,
        data: totalCommissions,
        fill: false,
        // borderColor: "rgba(75, 192, 192, 1)",
        borderColor: "#5E72E4",
        tension: 0.4, // For cubic interpolation
      },
      {
        label:
          language === "hindi"
            ? `Pending commission ₹(${totalPending})`
            : ` लंबित कमीशन ₹(${totalPending})`,
        data: pendingCommissions,
        fill: false,
        borderColor: "rgb(235, 88, 14)",
        tension: 0.4, // For cubic interpolation
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text:
          language === "hindi"
            ? `Society Commissions for Year ${selectedYear}`
            : `सोसायटी से कमाया कमीशन ${selectedYear}`,
      },
      tooltip: {
        callbacks: {
          beforeLabel: (context) => {
            const monthIndex = context.dataIndex; // The index of the month being hovered
            const societies = selectedYearData[monthIndex].societies;

            // Check which dataset is being hovered: 0 for "Paid Commission", 1 for "Pending Commission"
            if (context.datasetIndex === 0) {
              // Paid Commission Details
              return societies
                .map((society) => `${society.name}: Paid ${society.commission}`)
                .join("\n");
            } else if (context.datasetIndex === 1) {
              // Pending Commission Details
              return societies
                .map(
                  (society) =>
                    `${society.name}: Pending ${society.pendingCommission}`
                )
                .join("\n");
            }
          },
          label: (context) => {
            // Display dataset-specific total value

            if (context.datasetIndex === 0) {
              return `Paid Commission: ${context.raw}`;
            } else if (context.datasetIndex === 1) {
              return `Pending Commission: ${context.raw}`;
            }
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  //commsion earned dougnut chart

  const doughnutData = {
    labels: [
      language === "hindi"
        ? `Paid Commission  ₹(${totalPaid})`
        : `भुगतान किया गया कमीशन  ₹(${totalPaid})`,
      language === "hindi"
        ? `Pending Commission ₹(${totalPending})`
        : `लंबित  कमीशन ₹(${totalPending})`,
    ],
    datasets: [
      {
        label: "Commission Breakdown",
        data: [totalPaid, totalPending],
        backgroundColor: [
          // "rgba(75, 192, 192, 0.7)", // Paid Commission
          "#5E72E4", // Paid Commission
          "rgba(235, 88, 14, 0.7)", // Pending Commission
        ],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(235, 88, 14, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    rotation: -90, // Start from the top
    circumference: 180, // Display only half of the doughnut
    plugins: {
      title: {
        display: true,
        text:
          language === "hindi"
            ? `Total Commissions in Year ${selectedYear}`
            : `वर्ष में कुल कमीशन ${selectedYear}`,
      },
      legend: {
        position: "top", // Legend position
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const value = context.raw || 0;
            const total = context.dataset.data.reduce(
              (acc, curr) => acc + curr,
              0
            );
            const percentage = ((value / total) * 100).toFixed(2);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
    cutout: "50%", // Creates the doughnut hole
  };

  const currentYear = new Date().getFullYear(); // Get the current year
  const previousYear = currentYear - 1; // Previous year
  const previousToPreviousYear = currentYear - 2; // Next year

  // All socities status pie Chart
  const selectedYearSocietyCount = societyData?.yearData?.[selectedYear] || {};
  // console.log('setSelectedYear',selectedYearSocietyCount);

  const { approved = 0, rejected = 0, pending = 0 } = selectedYearSocietyCount;

  // Prepare Pie Chart data
  const pieData = {
    labels: [
      language === "hindi"
        ? `Approved Societies (${approved})`
        : `स्वीकृत सोसायटी (${approved})`,
      language === "hindi"
        ? `Pending Societies (${pending})`
        : `लंबित सोसायटी (${pending})`,
      language === "hindi"
        ? `Rejected Societies (${rejected})`
        : `अस्वीकृत सोसायटी (${rejected})`,
    ],
    datasets: [
      {
        label: "Society Status Breakdown",
        data: [approved, pending, rejected],
        backgroundColor: [
          "#5E72E4", // Approved (Blue)
          "rgba(235, 88, 14, 0.7)", // Rejected (Orange)
          "rgba(255, 193, 7, 0.7)", // Pending (Yellow)
        ],
        borderColor: [
          "#5E72E4", // Approved
          "rgba(235, 88, 14, 1)", // Rejected
          "rgba(255, 193, 7, 1)", // Pending
        ],
        borderWidth: 1,
      },
    ],
  };

  // Pie Chart options
  const pieOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text:
          language === "hindi"
            ? `Society Status in Year ${selectedYear}`
            : ` सोसायटी का स्टेटस ${selectedYear}`,
      },
      legend: {
        position: "top", // Legend position
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const value = context.raw || 0;
            const total = context.dataset.data.reduce(
              (acc, curr) => acc + curr,
              0
            );
            const percentage = ((value / total) * 100).toFixed(2);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
  };

  return (
    <div>
      <AffiliateLayout>
        <div className="container-fluid py-4">
          <div className="row">
            {/* Total Society */}
            <div className="col-xl-4 col-sm-6 mb-xl-0 mb-4">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0  numbers_heading text-uppercase font-weight-bold">
                          {}
                          {language === "hindi"
                            ? "Total Society"
                            : " कुल सोसायटी"}
                        </p>
                        <h5 className="font-weight-bolder">
                          {sococietyLoader ? (
                            <SmallLoader size={20} />
                          ) : (
                            societyData?.totalSocietyCount
                          )}
                        </h5>
                        <p className="mb-0">
                          <span className="text-success text-sm font-weight-bolder">
                            {language === "hindi" ? "Societies" : " सोसाइटी"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div
                        className="icon icon-shape 
                        bg-gradient-info shadow-info text-center rounded-circle"
                      >
                        <FaBuilding className="dashboard_icons"></FaBuilding>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Approved Society */}
            <div className="col-xl-4 col-sm-6 mb-xl-0 mb-4">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0  numbers_heading text-uppercase font-weight-bold">
                          {}
                          {language === "hindi"
                            ? "Approved  Society"
                            : " स्वीकृत सोसायटी"}
                        </p>
                        <h5 className="font-weight-bolder">
                          {sococietyLoader ? (
                            <SmallLoader size={20} />
                          ) : (
                            societyData?.totalApproved
                          )}
                        </h5>
                        <p className="mb-0">
                          <span className="text-success text-sm font-weight-bolder">
                            {language === "hindi" ? "Approved " : " स्वीकृत"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div
                        className="icon icon-shape 
                        bg-gradient-primary shadow-primary text-center rounded-circle"
                      >
                        <BsFillHouseCheckFill className="dashboard_icons"></BsFillHouseCheckFill>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Pending /Reject */}
            <div className="col-xl-4 col-sm-6 mb-xl-0 mb-4">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0 text-uppercase font-weight-bold">
                          {language === "hindi"
                            ? "UnApproved Society"
                            : "  अस्वीकृत सोसायटी"}
                        </p>
                        {sococietyLoader ? (
                          <SmallLoader size={20} />
                        ) : (
                          <h5 className="font-weight-bolder">
                            {`${societyData?.totalPending}/

                   ${societyData?.totalRejected}`}
                          </h5>
                        )}
                        <p className="mb-0">
                          <span className="text-success text-sm font-weight-bolder">
                            {language === "hindi"
                              ? " Pending / Rejected"
                              : "  लंबित / अस्वीकृत"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div className="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                        <BsFillHouseSlashFill className="dashboard_icons" />
                        {/* <MdPermIdentity /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            {/* Total Commision */}
            <div className="col-xl-4 col-sm-6 mb-xl-0 mb-4">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0  numbers_heading text-uppercase font-weight-bold">
                          {language === "hindi"
                            ? "Total Commission"
                            : "  कुल कमीशन"}
                        </p>
                        <h5 className="font-weight-bolder">
                          {commissionLoader ? (
                            <SmallLoader size={20} />
                          ) : (
                            affiliateEarnings?.totalCommission
                          )}
                        </h5>
                        <p className="mb-0">
                          <span className="text-success text-sm font-weight-bolder">
                            {language === "hindi"
                              ? " Earned Commission"
                              : "  कमीशन कमाया"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div className="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                        {/* <FaLocationArrow className="dashboard_icons" /> */}
                        <GiReceiveMoney className="dashboard_icons" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Paid Commision */}
            <div className="col-xl-4 col-sm-6 mb-xl-0 mb-4">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0 text-uppercase font-weight-bold">
                          {language === "hindi"
                            ? " Paid Commission"
                            : "भुगतान की गई कमीशन"}
                        </p>
                        <h5 className="font-weight-bolder">
                          {commissionLoader ? (
                            <SmallLoader size={20} />
                          ) : (
                            affiliateEarnings?.paidCommission
                          )}
                        </h5>
                        <p className="mb-0">
                          <span className="text-success text-sm font-weight-bolder">
                            {language === "hindi"
                              ? " Paid Commission"
                              : "भुगतान की गई कमीशन"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div className="icon icon-shape bg-gradient-info shadow-info text-center rounded-circle">
                        {/* <AiOutlineQuestionCircle className="dashboard_icons" /> */}
                        <MdPaid className="dashboard_icons" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Pending Commision */}
            <div className="col-xl-4 col-sm-6 mb-xl-0 mb-4">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0 text-uppercase font-weight-bold">
                          {language === "hindi"
                            ? " Pending Commission"
                            : "  लंबित कमीशन"}
                        </p>
                        <h5 className="font-weight-bolder">
                          {commissionLoader ? (
                            <SmallLoader size={20} />
                          ) : (
                            affiliateEarnings?.pendingCommission
                          )}
                        </h5>
                        <p className="mb-0">
                          <span className="text-success text-sm font-weight-bolder">
                            {language === "hindi"
                              ? " Pending Commission"
                              : "  लंबित कमीशन  "}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div className="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                        {/* <AiOutlineQuestionCircle className="dashboard_icons" /> */}
                        <MdOutlineCurrencyExchange className="dashboard_icons" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* // ChartJS */}
          <div className="row mt-4 ">
            <br />
            <br />
            <div className="col-lg-12 mb-lg-0 mb-4 mt-4">
              <div className="card z-index-2 h-100">
                <div className="mx-3 mt-3">
                  <label className="me-2">
                    {language === "hindi"
                      ? ` Select Year : `
                      : ` वर्ष चुनें : `}
                  </label>
                  <select value={selectedYear} onChange={handleYearChange}>
                    <option value={previousToPreviousYear}>
                      {previousToPreviousYear}
                    </option>
                    <option value={previousYear}>{previousYear}</option>
                    <option value={currentYear}>{currentYear}</option>
                  </select>
                </div>
                <div className="card-header pb-0 pt-3 bg-transparent">
                  <div className="mb-5">
                    {isLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "100px",
                        }}
                      >
                        <SmallLoader size={50} />
                      </div>
                    ) : yearsData[selectedYear] ? (
                      <Line data={chartData} options={options} />
                    ) : (
                      <div
                        style={{
                          height: "250px",
                          textAlign: "center",
                          alignContent: "center",
                        }}
                      >
                        No Data Found
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4 ">
            {/* Yearly Commssions */}
            <div className="col-lg-6 mb-lg-0 mb-4 ">
              <div className="card z-index-2 h-100">
                <div className="card-header pb-0 pt-3 bg-transparent">
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "100px",
                      }}
                    >
                      <SmallLoader size={50} />
                    </div>
                  ) : yearsData[selectedYear] ? (
                    <Doughnut data={doughnutData} options={doughnutOptions} />
                  ) : (
                    <div
                      style={{
                        height: "200px",
                        textAlign: "center",
                        alignContent: "center",
                      }}
                    >
                      No Data Found
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Socities Status */}
            <div className="col-lg-6 mb-lg-0 mb-4 ">
              <div className="card z-index-2 h-100">
                <div className="card-header pb-0 pt-3 bg-transparent pb-4">
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "100px",
                      }}
                    >
                      <SmallLoader size={50} />
                    </div>
                  ) : societyData?.yearData?.[selectedYear] ? (
                    <Pie data={pieData} options={pieOptions} />
                  ) : (
                    <div
                      style={{
                        height: "200px",
                        textAlign: "center",
                        alignContent: "center",
                      }}
                    >
                      No Data Found
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AffiliateLayout>
    </div>
  );
};

export default AffiliateDashboard;
