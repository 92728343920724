import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { PORT } from "../../../../Api/api";
import Layout from "../../../../lib/Layout";
import AddBackbtn from "../../../../lib/AddBackbtn";
import { LanguageContext } from "../../../../lib/LanguageContext";
import ButtonLoader from "../../../../Loading/ButtonLoader";
const AddSubscription = () => {
  const [isSubmitting,setSubmitting]=useState(false)
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const getRoleId = JSON.parse(localStorage.getItem("roleId"));
  //add subscription
  const formik = useFormik({
    initialValues: {
      name: "",
      createdBy: getRoleId,
      price: "",
      societyHouseList: "",
      description: "",
      interval: "",
      commissionCurrency: "",
    },
    onSubmit: async (values) => {
      try {
        setSubmitting(true)
        await axios.post(`${PORT}createSubscription`, values);
        //   toast.success(res.data.msg);
        toast.success('Subscription Plan Created !')
        setTimeout(() => {
          setSubmitting(false)
          navigate(-1);
        }, 1000);
      } catch (error) {
        toast.error(error.response.data.msg);
        console.log(error, "error");
        setSubmitting(false)
      }
    },
  });
  return (
    <>
      <Layout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi" ? "Add Subscription " : "सदस्यता जोड़ें "}
          </h5>
          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <div className="subscription-main-div">
          <form onSubmit={formik.handleSubmit} className="subscription-form">
            <div>
              <label htmlFor="name" className="editLabel">
                {language === "hindi" ? "Plan Name" : " योजना का नाम"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <select
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="edit-input"
                required
              >
                <option value="" selected>
                  {language === "hindi" ? "Select Plan" : "योजना चुनें"}{" "}
                  {/* Placeholder option */}
                </option>
                <option value="Standard">
                  {language === "hindi" ? " Standard Plan" : "स्टैंडर्ड योजना "}
                </option>
                <option value="Elite">
                  {language === "hindi" ? "Elite Plan" : "एलीट योजना"}
                </option>
                <option value="Supreme">
                  {language === "hindi" ? "Supreme Plan" : " सुप्रीम योजना"}
                </option>
              </select>
            </div>
            <div>
              <label htmlFor="price" className="editLabel">
                {language === "hindi"
                  ? " Price Per household"
                  : " प्रत्येक घर का मूल्य"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <input
                type="number"
                id="price"
                name="price"
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={language === "hindi" ? "Enter price" : " मूल्य"}
                className="edit-input"
                min="0" // Ensure no negative values
                required
              />
            </div>
            <div>
              <label className="editLabel" htmlFor="societyHouseList">
                {language === "hindi"
                  ? "No. of Houses in Society"
                  : "सोसायटी में घरों की संख्या"}
              </label>
              <br />
              <select
                id="societyHouseList"
                name="societyHouseList"
                value={formik.values.societyHouseList}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="edit-input"
                required
                // onChange={(e) => handleHouseChange(e, setFieldValue)}
              >
                <option value="">Select House Range</option>
                <option value="0-250">0 - 250</option>
                <option value="250-500">250 - 500</option>
                <option value="500-1000">500 - 1000</option>
              </select>
            </div>
            <div>
              <label htmlFor="interval" className="editLabel">
                {language === "hindi" ? "Interval" : "अंतराल"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <select
                id="interval"
                name="interval"
                value={formik.values.interval}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="edit-input"
                required
              >
                <option value="" selected>
                  {language === "hindi" ? "Select Interval" : "चयनित अंतराल"}{" "}
                  {/* Placeholder option */}
                </option>
                <option value="monthly">
                  {language === "hindi" ? "Monthly" : " मासिक"}
                </option>
                <option value="annual" disabled={true}>
                  {language === "hindi" ? "Annual" : " वार्षिक"}
                </option>
                <option value="quarterly" disabled={true}>
                  {language === "hindi" ? "Quarterly" : " त्रैमासिक"}
                </option>
              </select>
            </div>

            <div>
              <label htmlFor="description" className="editLabel">
                {language === "hindi" ? "Description" : "विवरण"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <textarea
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={
                  language === "hindi" ? "Enter plan description" : " विवरण"
                }
                className="edit-input"
                maxLength="200" // Allow more characters for description
                required
              />
            </div>

            <div>
              <label htmlFor="commissionCurrency" className="editLabel">
                {language === "english" ? "भुगतान मुद्रा" : " Payment Currency"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <select
                id="commissionCurrency"
                name="commissionCurrency"
                value={formik.values.commissionCurrency}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="edit-input"
                required
              >
                <option value="" selected>
                  {language === "hindi"
                    ? "Select  Payment Currency "
                    : "भुगतान मुद्रा चुनें"}{" "}
                  {/* Placeholder option */}
                </option>
                <option value="INR">
                  {language === "hindi" ? " ₹ (INR)" : "₹ (रुपया)"}
                </option>
                <option value="USD" disabled={true}>
                  {language === "hindi" ? " $ (USD)" : "$ (डॉलर)"}
                </option>
                {/* You can add more currencies if needed */}
              </select>
            </div>

            <div className="role_submit_btn">
              <button
                className="edit-button edit_btn_create_role"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting?<ButtonLoader/>:language === "hindi"
                  ? "Create Subscription Plan"
                  : "Create Subscription Plan"}
              </button>
            </div>
          </form>
        </div>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default AddSubscription;
