import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Avatar,
} from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import NotificationsIcon from '@mui/icons-material/Notifications';

function NotificationDetailView({ notification, onClose }) {
  const getNotificationIcon = (type) => {
    switch (type) {
      case 'job':
        return <WorkIcon fontSize="large" />;
      case 'connection':
        return <PersonAddIcon fontSize="large" />;
      case 'engagement':
        return <TrendingUpIcon fontSize="large" />;
      default:
        return <NotificationsIcon fontSize="large" />;
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar style={{ marginRight: '16px', backgroundColor: '#0077B5' }}>
            {getNotificationIcon(notification.type)}
          </Avatar>
          <Typography variant="h6">{notification.title}</Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          {notification.message}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Received: {new Date(notification.createdAt).toLocaleString()}
        </Typography>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={onClose} color="primary">
          
        </Button> */}
        <Button color="primary" variant="contained" onClick={onClose}>
        Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NotificationDetailView;

